import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/user/auth.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as userActions from './user.actions';
import { tap, mergeMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class UserEffects {
    constructor(
        private _userService: AuthService,
        private _actions$: Actions) { }

    @Effect({ dispatch: false })
    signOut$ = this._actions$
        .pipe(
            ofType(userActions.UserActionTypes.signOut),
            tap(() => {
                this._userService.logout();
            })
        );


    @Effect()
    getLoggedInUser$: Observable<Action> = this._actions$
        .pipe(
            ofType(userActions.UserActionTypes.loadUserProfile),
            mergeMap(() => this._userService.getUserProfile().pipe(
                map(userProfile => (new userActions.LoadUserProfileSuccess(userProfile))),
                catchError(err => of(new userActions.LoadUserProfileFail('Error loading user profile')))
            ))
        );

    @Effect()
    getManagers$: Observable<Action> = this._actions$
        .pipe(
            ofType(userActions.UserActionTypes.loadManagers),
            mergeMap(() => this._userService.getManagers().pipe(
                map(managers => (new userActions.LoadManagersSuccess(managers))),
                catchError(err => of(new userActions.LoadManagersFail('Error loading managers')))
            ))
        );
}
