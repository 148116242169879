import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum OpenedByMeCampaignActionTypes {
    loadCampaigns = '[Opened by me Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Opened by me Campaigns] Load campaigns success',
    loadCampaignsFail = '[Opened by me Campaigns] Load campaigns fail',
    setPaginationInfo = '[Opened by me Campaigns] Set pagination info',
    setSortingInfo = '[Opened by me Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Opened by me Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = OpenedByMeCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = OpenedByMeCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = OpenedByMeCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = OpenedByMeCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = OpenedByMeCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = OpenedByMeCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type OpenedByMeCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
