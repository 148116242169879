import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    Campaign, CampaignStatus, RuleField,
    CampaignEffectType, CampaignTypeItem, CampaignBrandItem, CodeType, CampaignType
} from '../../campaign';
import * as fromCampaign from '../../state';
import * as fromUser from '../../../user/state/user.reducer';
import * as fromApp from '../../../state/app.reducer';
import * as fromNotification from '../../../notifications/state/notifications.reducer';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import * as campaignActions from '../../state/campaign.actions';
import * as campaignPropertyActions from '../../state/campaign-property.actions';
import * as userActions from '../../../user/state/user.actions';
import * as appActions from '../../../state/app.actions';
import * as ruleBuilderActions from '../../state/rule-builder.actions';
import * as notificationActions from '../../../notifications/state/notifications.actions';
import { map } from 'rxjs/operators';
import { SmartManagerUser } from 'src/app/user/smart-manager-user';
import { SmartUser } from 'src/app/user/smart-user';
import { MatDialog } from '@angular/material';
import { SubmitToApprovalDialogComponent } from '../../components/submit-to-approval-dialog/submit-to-approval-dialog.component';
import { CampaignService } from '../../campaign.service';
import * as moment from 'moment';
import { RoutingStateService } from 'src/app/routing-state.service';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { DeliveryMethod } from '../../postage';
import { ConfirmEditDialogComponent } from '../../components/confirm-edit-dialog/confirm-edit-dialog.component';
import { Title } from '@angular/platform-browser';
import { FaviconService } from 'src/app/favicon.service';


@Component({
    templateUrl: './campaign-edit-page.component.html'
})
export class CampaignEditPageComponent implements OnInit, OnDestroy {
    campaignTypes$: Observable<CampaignTypeItem[]>;
    campaignBrands$: Observable<CampaignBrandItem[]>;
    loadingConflicts$: Observable<boolean>;
    savingCampaign$: Observable<boolean>;
    loadingManagers$: Observable<boolean>;
    managers$: Observable<SmartManagerUser[]>;
    currentUser$: Observable<SmartUser>;
    deliveryMethods$: Observable<DeliveryMethod[]>;
    campaignEffectTypes$: Observable<CampaignEffectType[]>;
    codeDefinitionTypes: any[] = [{ name: 'Generic', value: CodeType.generic }, { name: 'Unique', value: CodeType.unique }];
    loaded$: Observable<boolean>;

    campaign: Campaign;
    ruleFields: RuleField[];
    pageTitle: string;

    private _isDirty = false;
    private _subscriptions: Subscription[] = [];
    private _escHotkeySubscription: Subscription;
    private _sendSubmitForApprovalNotification = false;

    get isDirty(): boolean {
        return this._isDirty;
    }

    get isNewCampaign(): boolean {
        if (this.campaign && this.campaign.id === 0) {
            return true;
        }
        return false;
    }

    get isLive(): boolean {
        if (this.campaign && this.campaign.status === CampaignStatus.approved) {
            const today = moment();
            const from = moment(this.campaign.validFrom * 1000);
            const to = moment(this.campaign.validTo * 1000);
            return today.isBetween(from, to);
        }

        return false;
    }

    get checkForConflictsBeforeSaving(): boolean {
        return this.campaign
            && this.isLive
            && this.campaign.type === CampaignType.sitewidePromotion;
    }

    get isApproved(): boolean {
        return this.campaign && this.campaign.status === CampaignStatus.approved;
    }

    get isPending(): boolean {
        return this.campaign && this.campaign.status === CampaignStatus.pending;
    }

    constructor(
        private _campaignStore: Store<fromCampaign.State>,
        private _userStore: Store<fromUser.UserState>,
        private _activatedRoute: ActivatedRoute,
        private _actionSubject: ActionsSubject,
        private _dialog: MatDialog,
        private _appStore: Store<fromApp.AppState>,
        private _notificationStore: Store<fromNotification.NotificationState>,
        private _campaignService: CampaignService,
        private _router: Router,
        private _hotkeysService: Hotkeys,
        private _routingStateService: RoutingStateService,
        private _titleService: Title,
        private _faviconService: FaviconService) { }

    ngOnInit(): void {
        this.loaded$ = this._campaignStore.pipe(select(fromCampaign.getCurrentCampaignLoaded));
        this.deliveryMethods$ = this._campaignStore.pipe(select(fromCampaign.getDeliveryMethods));
        this._campaignStore.dispatch(new campaignPropertyActions.LoadCampaignTypes());
        this._campaignStore.dispatch(new campaignPropertyActions.LoadCampaignBrands());
        this._campaignStore.dispatch(new ruleBuilderActions.LoadFields());
        this._campaignStore.dispatch(new ruleBuilderActions.LoadCampaignEffectTypes());
        this._subscriptions.push(this._activatedRoute.params
            .pipe(map(params => new campaignActions.GetCampaign(params.id)))
            .subscribe(action => this._campaignStore.dispatch(action)));
        this._subscriptions.push(this._campaignStore.pipe(select(fromCampaign.getRuleBuilderFields))
            .subscribe(ruleFieldArray => {
                this.ruleFields = ruleFieldArray;
            }));
        this._subscriptions.push(this._campaignStore.pipe(select(fromCampaign.getCurrentCampaign)).subscribe(campaign => {
            this.campaign = campaign;
            this.setTitle();
        }));
        this.currentUser$ = this._userStore.pipe(select(fromUser.getCurrentUser));
        this.campaignTypes$ = this._campaignStore.pipe(select(fromCampaign.getCampaignTypes));
        this.campaignBrands$ = this._campaignStore.pipe(select(fromCampaign.getCampaignBrands));
        this.loadingConflicts$ = this._campaignStore.pipe(select(fromCampaign.getIsLoadingConflicts));
        this.savingCampaign$ = this._campaignStore.pipe(select(fromCampaign.getIsSavingCampaign));
        this.loadingManagers$ = this._userStore.pipe(select(fromUser.getIsLoadingManagers));
        this.managers$ = this._userStore.pipe(select(fromUser.getManagers));
        this.campaignEffectTypes$ = this._campaignStore.pipe(select(fromCampaign.getCamapignEffectTypes));

        this._subscriptions.push(this._actionSubject
            .filter(action => action.type === campaignActions.CampaignActionTypes.saveCampaignSuccess)
            .subscribe((action: campaignActions.SaveCampaignSuccess) => {
                if (this._sendSubmitForApprovalNotification) {
                    this._notificationStore.dispatch(new notificationActions.SendCampaignRequestForApprovalMessage({
                        campaignCreatedBy: action.payload.createdBy,
                        nominatedManager: this.campaign.nominatedManager,
                        requestTimestamp: moment().unix(),
                        campaignId: action.payload.id
                      }));
                    this._sendSubmitForApprovalNotification = false;
                }
                this._isDirty = false;
                this.goBack();
            }));
        this._subscriptions.push(this._actionSubject
            .filter(action => action.type === campaignActions.CampaignActionTypes.saveCampaignFail)
            .subscribe(() => {
                this._appStore.dispatch(new appActions.SetMessage(':-( Something went wrong saving the campaign, please try again.'));
            }));
        this._subscriptions.push(this._actionSubject
            .filter(action => action.type === campaignActions.CampaignActionTypes.saveCampaignOutOfDateError)
            .subscribe(() => {
                this._appStore.dispatch(new appActions
                    .SetMessage('Unable to save as the Campaign has been updated by another user, please refresh to continue'));
            }));
        this._subscriptions.push(this._actionSubject
            .filter(action => action.type === campaignActions.CampaignActionTypes.getCampaignFail)
            .subscribe(() => {
                this._router.navigateByUrl('/404');
            }));
        this._userStore.dispatch(new userActions.LoadManagers());
        this.registerEscKey();
    }

    private setTitle(): void {
        if (this.campaign) {
            if (this.isNewCampaign) {
                this.pageTitle = 'Add Campaign';
            } else {
                this.pageTitle = `Edit Campaign: ${this.campaign.name}`;
            }
            this.setBrowserTitle();
        }
    }

    private setBrowserTitle() {
        if (this.isNewCampaign) {
            this._titleService.setTitle('New campaign');
        } else {
            this._titleService.setTitle(`(E) ${this.campaign.id}: ${this.campaign.name}`);
        }
    }

    ngOnDestroy(): void {
        this._campaignStore.dispatch(new campaignActions.ClearCurrentCampaign);
        this._subscriptions.forEach(subscription => {
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        this.disposeEscKey();
    }

    setIsDirty(isDirty: boolean): void {
        this._isDirty = isDirty;
        this._faviconService.update(this.isDirty);
    }

    cancel(): void {
        this.goBack();
    }

    save(campaign: Campaign): void {
        if (this.checkForConflictsBeforeSaving) {
            this.disposeEscKey();
            const dialogRef = this._dialog.open(ConfirmEditDialogComponent, {
                width: '500px',
                data: this.campaign
            });

            dialogRef.afterClosed().subscribe(result => {
                this.registerEscKey();
                if (result) {
                    this._campaignStore.dispatch(new campaignActions.SetIsSavingCampaign());
                    this._campaignStore.dispatch(new campaignActions.SaveCampaign(this._campaignService.mapCampaignToModel(campaign)));
                }
            });
        } else {
            this._campaignStore.dispatch(new campaignActions.SetIsSavingCampaign());
            this._campaignStore.dispatch(new campaignActions.SaveCampaign(this._campaignService.mapCampaignToModel(campaign)));
        }
    }

    saveAndSubmit(campaign: Campaign): void {
        this.disposeEscKey();
        const dialogRef = this._dialog.open(SubmitToApprovalDialogComponent, {
            width: '500px',
            data: this.managers$
        });

        dialogRef.afterClosed().subscribe(result => {
            this.registerEscKey();
            if (result) {
                this._sendSubmitForApprovalNotification = true;
                campaign.nominatedManager = this.campaign.nominatedManager = result;
                campaign.status = CampaignStatus.pending;
                this.save(campaign);
            }
        });
    }

    goBack(): void {
        const previousRoute = this._routingStateService.getPreviousUrl();
        this._router.navigateByUrl(previousRoute);
    }

    private registerEscKey(): void {
        this._escHotkeySubscription = this._hotkeysService.addShortcut({ keys: 'esc' })
            .subscribe(() => {
                this.goBack();
            });
    }

    private disposeEscKey(): void {
        if (this._escHotkeySubscription) {
            this._escHotkeySubscription.unsubscribe();
        }
    }
}
