import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as openedByMeCampaignActions from './opened-by-me-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

@Injectable()
export class OpenedByMeCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadRecentlyViewedCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(openedByMeCampaignActions.OpenedByMeCampaignActionTypes.loadCampaigns),
        map((action: openedByMeCampaignActions.LoadCampaigns) => action.payload),
        mergeMap((requestInfo) =>
            this._campaignService.getRecentlyViewed(requestInfo.pagination.index, requestInfo.pagination.size,
                    requestInfo.sorting.fieldName, requestInfo.sorting.direction).pipe(
                map(campaigns => (new openedByMeCampaignActions.LoadCampaignsSuccess(campaigns))),
                catchError(() => of(new openedByMeCampaignActions.LoadCampaignsFail('Error loading campaigns opened by me')))
            )
        )
    );
}
