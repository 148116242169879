import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { Product } from '../../product';
import { Observable, Subscription } from 'rxjs';
import * as fromCampaign from '../../state';
import { Store, select } from '@ngrx/store';
import * as productActions from '../../state/product.actions';
import 'rxjs/add/operator/debounceTime';
import { ProductService } from '../../product.service';

@Component({
  selector: 'app-product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.scss'],
})
export class ProductInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() product: Product;
  @Input() brandId: number;
  @Output() productChange = new EventEmitter<Product>();

  filteredProducts$: Observable<Product[]>;
  selectedProduct: Product;
  isLoading$: Observable<boolean>;
  productInputForm: FormGroup;

  private _subscriptions: Subscription[] = [];

  get productSearchFormControl(): AbstractControl {
    return this.productInputForm.get('productSearchInput');
  }

  constructor(private _fb: FormBuilder,
    private _store: Store<fromCampaign.State>,
    private _productService: ProductService) {
    this.productInputForm = this._fb.group({
      productSearchInput: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.filteredProducts$ = this._store.pipe(select(fromCampaign.getFilteredProducts));
    this.isLoading$ = this._store.pipe(select(fromCampaign.getIsLoadingProducts));

    this._subscriptions.push(this.productInputForm.get('productSearchInput')
      .valueChanges
      .debounceTime(300)
      .subscribe(value => {
        this._store.dispatch(new productActions.SetIsLoadingProducts());
        this._store.dispatch(new productActions.SearchProducts({ term: value, brandId: this.brandId }));
      }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product && changes.product.currentValue) {
      this.setProduct(<Product>changes.product.currentValue);
    }

    if (changes.brandId && changes.brandId.currentValue) {
      if (this.productSearchFormControl.value) {
        this.validateProduct();
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  productSelected() {
    this.selectedProduct = this.productInputForm.get('productSearchInput').value;
    this.productChange.emit(this.selectedProduct);
  }

  clearSelectedProduct() {
    this.selectedProduct = null;
    this.productInputForm.controls['productSearchInput'].setValue(null);
    this.productChange.emit(null);
  }

  displayProductFn(product: Product) {
    if (product) { return product.name; }
  }

  private validateProduct(): void {
    const selectedProduct = <Product>this.productSearchFormControl.value;
    this._subscriptions.push(this._productService.searchProducts({term: selectedProduct.code.toString(), brandId: this.brandId})
      .subscribe(products => {
        if (!products || products.length === 0) {
          this.clearSelectedProduct();
        }
      }));
  }

  private setProduct(product: Product) {
    this.productInputForm.controls['productSearchInput'].setValue(product);
    this.productSelected();
  }
}
