import { Action } from '@ngrx/store';
import { DimensionName, DimensionValue } from '../components/dimension-input/dimension';

export enum DimensionActionTypes {
    loadDimensionNames = '[Dimension] Load dimension names',
    loadDimensionNamesSuccess = '[Dimension] Load dimension names succes',
    loadDimensionNamesFail = '[Dimension] Load dimension names fail',
    loadDimensionValues = '[Dimension] Load dimension values',
    loadDimensionValuesSuccess = '[Dimension] Load dimension values success',
    loadDimensionValuesFail = '[Dimension] Load dimension values fail',
    setIsLoadingDimensionNames = '[Dimension] Loading dimention names',
    setIsLoadingDimensionValues = '[Dimension] Loading dimention values'
}

export class LoadDimensionNames implements Action {
    readonly type = DimensionActionTypes.loadDimensionNames;
}

export class LoadDimensionNamesSuccess implements Action {
    readonly type = DimensionActionTypes.loadDimensionNamesSuccess;
    constructor(public payload: DimensionName[]) { }
}

export class LoadDimensionNamesFail implements Action {
    readonly type = DimensionActionTypes.loadDimensionNamesFail;
    constructor(public payload: string) { }
}

export class LoadDimnesionValues implements Action {
    readonly type = DimensionActionTypes.loadDimensionValues;
    constructor(public payload: number) { }
}

export class LoadDimnesionValuesSuccess implements Action {
    readonly type = DimensionActionTypes.loadDimensionValuesSuccess;
    constructor(public payload: DimensionValue[]) { }
}

export class LoadDimnesionValuesFail implements Action {
    readonly type = DimensionActionTypes.loadDimensionValuesFail;
    constructor(public payload: string) { }
}

export class SetIsloadingDimensionNames implements Action {
    readonly type = DimensionActionTypes.setIsLoadingDimensionNames;
}

export class SetIsloadingDimensionValues implements Action {
    readonly type = DimensionActionTypes.setIsLoadingDimensionValues;
}

export type DimensionActions  = LoadDimensionNames
    | LoadDimensionNamesSuccess
    | LoadDimensionNamesFail
    | LoadDimnesionValues
    | LoadDimnesionValuesSuccess
    | LoadDimnesionValuesFail
    | SetIsloadingDimensionNames
    | SetIsloadingDimensionValues;
