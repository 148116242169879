import * as fromCampaign from './campaign.reducer';
import * as fromLiveCampaign from './live-campaign.reducer';
import * as fromDraftCampaign from './draft-campaign.reducer';
import * as fromScheduledCampaign from './scheduled-campaign.reducer';
import * as fromExpiredCampaign from './expired-campaign.reducer';
import * as fromAllCampaign from './all-campaign.reducer';
import * as fromPendingApprovalCampaign from './pending-approval-campaign.reducer';
import * as fromTabPendingApprovalCampaign from './tab-pending-approval-campaign.reducer';
import * as fromCreatedByMeCampaign from './created-by-me-campaign.reducer';
import * as fromOpenedByMeCampaign from './opened-by-me-campaign.reducer';
import * as fromFilteredCampaign from './filtered-campaign.reducer';
import * as fromCampaignProperties from './campaign-property.reducer';
import * as fromProduct from './product.reducer';
import * as fromDimension from './dimension.reducer';
import * as fromPostage from './postage.reducer';
import * as fromRuleBuilder from './rule-builder.reducer';

import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
    campaign: fromCampaign.CampaignState;
    liveCampaigns: fromLiveCampaign.LiveCampaignState;
    draftCampaigns: fromDraftCampaign.DraftCampaignState;
    scheduledCampaigns: fromScheduledCampaign.ScheduledCampaignState;
    expiredCampaigns: fromExpiredCampaign.ExpiredCampaignState;
    allCampaigns: fromAllCampaign.AllCampaignState;
    pendingApprovalCampaigns: fromPendingApprovalCampaign.PendingApprovalCampaignState;
    tabPendingApprovalCampaigns: fromTabPendingApprovalCampaign.TabPendingApprovalCampaignState;
    createdByMeCampaigns: fromCreatedByMeCampaign.CreatedByMeCampaignState;
    openedByMeCampaigns: fromOpenedByMeCampaign.OpenedByMeCampaignState;
    filteredCampaigns: fromFilteredCampaign.FilteredCampaignState;
    campaignProperties: fromCampaignProperties.CampaignPropertyState;
    products: fromProduct.ProductState;
    dimensions: fromDimension.DimensionState;
    postage: fromPostage.PostageStage;
    queryBuilder: fromRuleBuilder.RuleBuilderStage;
}

export const reducers = {
    campaign: fromCampaign.reducer,
    liveCampaigns: fromLiveCampaign.reducer,
    draftCampaigns: fromDraftCampaign.reducer,
    scheduledCampaigns: fromScheduledCampaign.reducer,
    expiredCampaigns: fromExpiredCampaign.reducer,
    allCampaigns: fromAllCampaign.reducer,
    pendingApprovalCampaigns: fromPendingApprovalCampaign.reducer,
    tabPendingApprovalCampaigns: fromTabPendingApprovalCampaign.reducer,
    createdByMeCampaigns: fromCreatedByMeCampaign.reducer,
    openedByMeCampaigns: fromOpenedByMeCampaign.reducer,
    filteredCampaigns: fromFilteredCampaign.reducer,
    campaignProperties: fromCampaignProperties.reducer,
    products: fromProduct.reducer,
    dimensions: fromDimension.reducer,
    postage: fromPostage.reducer,
    queryBuilder: fromRuleBuilder.reducer
};

export const getCampaignFeatureState = createFeatureSelector<State>('campaigns');

export const getCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.campaign
);

export const getLiveCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.liveCampaigns
);

export const getDraftCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.draftCampaigns
);

export const getScheduledCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.scheduledCampaigns
);

export const getExpiredCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.expiredCampaigns
);

export const getAllCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.allCampaigns
);

export const getPendingApprovalCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.pendingApprovalCampaigns
);

export const getTabPendingApprovalCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.tabPendingApprovalCampaigns
);

export const getCreatedByMeCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.createdByMeCampaigns
);

export const getOpenedByMeCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.openedByMeCampaigns
);

export const getFilteredCampaignState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.filteredCampaigns
);

export const getProductState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.products
);

export const getDimensionState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.dimensions
);

export const getPostageState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.postage
);

export const getRuleBuilderState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.queryBuilder
);

export const getCampaignPropertiesState = createSelector(
    getCampaignFeatureState,
    (state: State) => state.campaignProperties
);

export const getCurrentCampaign = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.currentCampaign
);

export const getCurrentCampaignLoaded = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.currentCampaignLoaded
);

export const getCurrentCampaignListTab = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.currentCampaignListTab
);

export const getLiveCampaignSummaries = createSelector(
    getLiveCampaignState,
    (state: fromLiveCampaign.LiveCampaignState) => state.liveCampaigns
);

export const getIsLoadingLiveCampaigns = createSelector(
    getLiveCampaignState,
    (state: fromLiveCampaign.LiveCampaignState) => state.isLoading
);

export const getDraftCampaignSummaries = createSelector(
    getDraftCampaignState,
    (state: fromDraftCampaign.DraftCampaignState) => state.draftCampaigns
);

export const getIsLoadingDraftCampaigns = createSelector(
    getDraftCampaignState,
    (state: fromDraftCampaign.DraftCampaignState) => state.isLoading
);

export const getScheduledCampaignSummaries = createSelector(
    getScheduledCampaignState,
    (state: fromScheduledCampaign.ScheduledCampaignState) => state.scheduledCampaigns
);

export const getIsLoadingScheduledCampaigns = createSelector(
    getScheduledCampaignState,
    (state: fromScheduledCampaign.ScheduledCampaignState) => state.isLoading
);

export const getExpiredCampaignSummaries = createSelector(
    getExpiredCampaignState,
    (state: fromExpiredCampaign.ExpiredCampaignState) => state.expiredCampaigns
);

export const getIsLoadingExpiredCampaigns = createSelector(
    getExpiredCampaignState,
    (state: fromExpiredCampaign.ExpiredCampaignState) => state.isLoading
);

export const getAllCampaignSummaries = createSelector(
    getAllCampaignState,
    (state: fromAllCampaign.AllCampaignState) => state.allCampaigns
);

export const getIsLoadingAllCampaigns = createSelector(
    getAllCampaignState,
    (state: fromAllCampaign.AllCampaignState) => state.isLoading
);

export const getPendingApprovalCampaignSummaries = createSelector(
    getPendingApprovalCampaignState,
    (state: fromPendingApprovalCampaign.PendingApprovalCampaignState) => state.pendingApprovalCampaigns
);

export const getIsLoadingPendingApprovalCampaigns = createSelector(
    getPendingApprovalCampaignState,
    (state: fromPendingApprovalCampaign.PendingApprovalCampaignState) => state.isLoading
);

export const getTabPendingApprovalCampaignSummaries = createSelector(
    getTabPendingApprovalCampaignState,
    (state: fromTabPendingApprovalCampaign.TabPendingApprovalCampaignState) => state.pendingApprovalCampaigns
);

export const getIsLoadingTabPendingApprovalCampaigns = createSelector(
    getTabPendingApprovalCampaignState,
    (state: fromTabPendingApprovalCampaign.TabPendingApprovalCampaignState) => state.isLoading
);

export const getCreatedByMeCampaignSummaries = createSelector(
    getCreatedByMeCampaignState,
    (state: fromCreatedByMeCampaign.CreatedByMeCampaignState) => state.createdByMeCampaigns
);

export const getIsLoadingCreatedByMeCampaigns = createSelector(
    getCreatedByMeCampaignState,
    (state: fromCreatedByMeCampaign.CreatedByMeCampaignState) => state.isLoading
);

export const getOpenedByMeCampaignSummaries = createSelector(
    getOpenedByMeCampaignState,
    (state: fromOpenedByMeCampaign.OpenedByMeCampaignState) => state.openedByMeCampaigns
);

export const getIsLoadingOpenedByMeCampaigns = createSelector(
    getOpenedByMeCampaignState,
    (state: fromOpenedByMeCampaign.OpenedByMeCampaignState) => state.isLoading
);

export const getFilteredCampaignSummaries = createSelector(
    getFilteredCampaignState,
    (state: fromFilteredCampaign.FilteredCampaignState) => state.filteredCampaigns
);

export const getIsLoadingFilteredCampaigns = createSelector(
    getFilteredCampaignState,
    (state: fromFilteredCampaign.FilteredCampaignState) => state.isLoading
);

export const getCampaignError = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.error
);

export const getFilteredProducts = createSelector(
    getProductState,
    (state: fromProduct.ProductState) => state.filteredProducts
);

export const getProductError = createSelector(
    getProductState,
    (state: fromProduct.ProductState) => state.error
);

export const getIsLoadingProducts = createSelector(
    getProductState,
    (state: fromProduct.ProductState) => state.isLoading
);

export const getDimensionNames = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.dimensionNames
);

export const getDimensionNamesLoaded = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.dimensionNamesLoaded
);

export const getFilteredDimensionValues = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.filteredDimensionValues
);

export const getIsLoadingDimensionNames = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.isLoadingDimensionNames
);

export const getIsLoadingDimensionValues = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.isLoadingDimensionValues
);

export const getIsDeletingCampaign = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.isDeleting
);

export const getIsUpdatingPendingStatusOfCampaign = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.isUpdatingPendingStatus
);

export const getDimensionError = createSelector(
    getDimensionState,
    (state: fromDimension.DimensionState) => state.error
);

export const getDeliveryMethods = createSelector(
    getPostageState,
    (state: fromPostage.PostageStage) => state.deliveryMethods
);

export const getDeliveryMethodsLoaded = createSelector(
    getPostageState,
    (state: fromPostage.PostageStage) => state.deliveryMethodsLoaded
);

export const getDeliveryMethosLoadingError = createSelector(
    getPostageState,
    (state: fromPostage.PostageStage) => state.error
);

export const getDeliveryMethodsIsLoading = createSelector(
    getPostageState,
    (state: fromPostage.PostageStage) => state.isLoading
);

export const getIsLoadingConflicts = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.isLoadingConflicts
);

export const getConflicts = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.conflicts
);

export const getIsUpdatingPriorities = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.isUpdatingPriorities
);

export const getIsSavingCampaign = createSelector(
    getCampaignState,
    (state: fromCampaign.CampaignState) => state.isSaving
);

export const getRuleBuilderFields = createSelector(
    getRuleBuilderState,
    (state: fromRuleBuilder.RuleBuilderStage) => state.fields
);

export const getRuleBuilderFieldsLoaded = createSelector(
    getRuleBuilderState,
    (state: fromRuleBuilder.RuleBuilderStage) => state.fieldsLoaded
);

export const getCamapignEffectTypes = createSelector(
    getRuleBuilderState,
    (state: fromRuleBuilder.RuleBuilderStage) => state.campaignEffectTypes
);

export const getCamapignEffectTypesLoaded = createSelector(
    getRuleBuilderState,
    (state: fromRuleBuilder.RuleBuilderStage) => state.campaignEffectTypesLoaded
);

export const getCampaignTypes = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignTypes
);

export const getCampaignBrands = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignBrands
);

export const getCampaignTypesLoaded = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignTypesLoaded
);

export const getCampaignBrandsLoaded = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignBrandsLoaded
);

export const getCampaignStatuses = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignStatuses
);

export const getCampaignStatusesLoaded = createSelector(
    getCampaignPropertiesState,
    (state: fromCampaignProperties.CampaignPropertyState) => state.campaignStatusesLoaded
);
