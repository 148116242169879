import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { TabPendingApprovalCampaignActions, TabPendingApprovalCampaignActionTypes } from './tab-pending-approval-campaign.actions';

export interface TabPendingApprovalCampaignState {
    pendingApprovalCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: TabPendingApprovalCampaignState = {
    pendingApprovalCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 10, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: TabPendingApprovalCampaignActions): TabPendingApprovalCampaignState {
    switch (action.type) {
        case TabPendingApprovalCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                pendingApprovalCampaigns: action.payload,
                isLoading: false
            };
        case TabPendingApprovalCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                pendingApprovalCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case TabPendingApprovalCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case TabPendingApprovalCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                pendingApprovalCampaigns: {
                    ...state.pendingApprovalCampaigns,
                    pagination: action.payload
                }
            };
        case TabPendingApprovalCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                pendingApprovalCampaigns: {
                    ...state.pendingApprovalCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
