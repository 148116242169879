import { Injectable, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { HotkeysHelpDialogComponent } from '../hotkeys-help-dialog/hotkeys-help-dialog.component';

interface Options {
    element: any;
    keys: string;
}

@Injectable({ providedIn: 'root' })
export class Hotkeys {
    defaults: Partial<Options> = {
        element: this._document
    };

    constructor(private _eventManager: EventManager,
        private _dialog: MatDialog,
        @Inject(DOCUMENT) private _document: Document) {
        this.addShortcut({ keys: 'shift.?' }).subscribe(() => {
            this.openHelpModal();
        });
    }

    addShortcut(options: Partial<Options>) {
        const merged = { ...this.defaults, ...options };
        const event = `keydown.${merged.keys}`;

        return new Observable(observer => {
            const handler = (e) => {
                e.preventDefault();
                observer.next(e);
            };

            const dispose = this._eventManager.addEventListener(
                merged.element, event, handler
            );

            return () => {
                dispose();
            };
        });
    }

    private openHelpModal() {
        this._dialog.open(HotkeysHelpDialogComponent, {
            width: '500px'
        });
    }
}
