import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppNotification } from '../../notification';

@Component({
  selector: 'app-notification-messages',
  templateUrl: './notification-messages.component.html',
  styleUrls: ['./notification-messages.component.scss']
})
export class NotificationMessagesComponent {
  @Input() messages: AppNotification[];
  @Output() notificationActionEvent = new EventEmitter<AppNotification>();

  notificationAction(notification: AppNotification): void {
    this.notificationActionEvent.emit(notification);
  }

}
