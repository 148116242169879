import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum TabPendingApprovalCampaignActionTypes {
    loadCampaigns = '[Campaigns Pending Approval Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Campaigns Pending Approval Campaigns] Load campaigns success',
    loadCampaignsFail = '[Campaigns Pending Approval Campaigns] Load campaigns fail',
    setPaginationInfo = '[Campaigns Pending Approval Campaigns] Set pagination info',
    setSortingInfo = '[Campaigns Pending Approval Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Campaigns Pending Approval Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = TabPendingApprovalCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type TabPendingApprovalCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
