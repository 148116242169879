import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignEditComponent } from './components/campaign-edit/campaign-edit.component';
import { DimensionInputComponent } from './components/dimension-input/dimension-input.component';


import { SharedModule } from '../shared/shared.module';
import { QueryBuilderModule } from 'angular2-query-builder';
import { RuleComponent } from './components/rule/rule.component';
import { RuleListComponent } from './components/rule-list/rule-list.component';
import { RuleListSectionComponent } from './rule-list-section.component';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
import { EffectsModule } from '@ngrx/effects';
import { CampaignEffects } from './state/campaign.effects';
import { CampaignEditPageGuard } from './campaign-edit-page.guard';
import { CampaignEditPageComponent } from './containers/campaign-edit-page/campaign-edit-page.componet';
import { ProductInputComponent } from './components/product-input/product-input.component';
import { ProductEffects } from './state/product.effects';
import { RuleEffectComponent } from './components/rule-effect/rule-effect.component';
import { CampaignReviewComponent } from './components/campaign-review/campaign-review.component';
import { RuleViewerComponent } from './components/rule-viewer/rule-viewer.component';
import { RuleConditionViewerComponent } from './components/rule-condition-viewer/rule-condition-viewer.component';
import { DimensionEffects } from './state/dimension.effects';
import { PostageEffects } from './state/postage.effects';
import { CampaignViewPageComponent } from './containers/campaign-view-page/campaign-view-page.component';
import { CampaignViewComponent } from './components/campaign-view/campaign-view.component';
import { SubmitToApprovalDialogComponent } from './components/submit-to-approval-dialog/submit-to-approval-dialog.component';
import { CampaignPropertyEffects } from './state/campaign-property.effects';
import { RoleGuard } from '../role.guard';
import { LiveCampaignEffects } from './state/live-campaign.effects';
import { DraftCampaignEffects } from './state/draft-campaign.effects';
import { ScheduledCampaignEffects } from './state/scheduled-campaign.effects';
import { ExpiredCampaignEffects } from './state/expired-campaign.effects';
import { AllCampaignEffects } from './state/all-campaign.effects';
import { RuleBuilderEffects } from './state/rule-builder.effects';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { ConfirmApproveDialogComponent } from './components/confirm-approve-dialog/confirm-approve-dialog.component';
import { ConfirmRejectDialogComponent } from './components/confirm-reject-dialog/confirm-reject-dialog.component';
import { CampaignConflictsComponent } from './components/campaign-conflicts/campaign-conflicts.component';
import { CampaignSearchComponent } from './components/campaign-search/campaign-search.component';
import { FilteredCampaignEffects } from './state/filtered-campaign.effects';
import { ManageConflictsDialogComponent } from './components/manage-conflicts-dialog/manage-conflicts-dialog.component';
import { ConfirmEditDialogComponent } from './components/confirm-edit-dialog/confirm-edit-dialog.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { TabPendingApprovalCampaignEffects } from './state/tab-pending-approval-campaign.effects';

@NgModule({
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID
    }
  ],
  imports: [
    SharedModule,
    NotificationsModule,
    RouterModule.forChild([
      { path: 'campaigns', component: CampaignListComponent, canActivate: [MsalGuard, RoleGuard],
      data: { roles: ['Discounting.Viewer'] }},
      {
        path: 'campaigns/:id/edit',
        component: CampaignEditPageComponent,
        canActivate: [MsalGuard, RoleGuard],
        canDeactivate: [CampaignEditPageGuard],
        data: { roles: ['Discounting.Creator']}
      },
      {
        path: 'campaigns/:id/view',
        component: CampaignViewPageComponent,
        canActivate: [MsalGuard, RoleGuard],
        data: { roles: ['Discounting.Viewer']}
      }
    ]),
    QueryBuilderModule,
    StoreModule
      .forFeature('campaigns', reducers),
    EffectsModule.forFeature(
      [ CampaignEffects, ProductEffects, DimensionEffects, PostageEffects,
        CampaignPropertyEffects, LiveCampaignEffects, DraftCampaignEffects,
        ScheduledCampaignEffects, ExpiredCampaignEffects, AllCampaignEffects,
        RuleBuilderEffects, FilteredCampaignEffects, TabPendingApprovalCampaignEffects ]
    )
  ],
  declarations: [
    CampaignListComponent,
    CampaignEditComponent,
    CampaignEditPageComponent,
    CampaignConflictsComponent,
    DimensionInputComponent,
    RuleComponent,
    RuleListComponent,
    RuleListSectionComponent,
    RuleEffectComponent,
    ProductInputComponent,
    CampaignReviewComponent,
    RuleViewerComponent,
    RuleConditionViewerComponent,
    CampaignViewPageComponent,
    CampaignViewComponent,
    SubmitToApprovalDialogComponent,
    ConfirmDeleteDialogComponent,
    ConfirmApproveDialogComponent,
    ConfirmRejectDialogComponent,
    ManageConflictsDialogComponent,
    ConfirmEditDialogComponent,
    CampaignSearchComponent
  ],
  exports: [
    SharedModule,
    NotificationsModule
  ],
  entryComponents: [
    RuleComponent,
    SubmitToApprovalDialogComponent,
    ConfirmDeleteDialogComponent,
    ConfirmApproveDialogComponent,
    ConfirmRejectDialogComponent,
    ConfirmEditDialogComponent,
    ManageConflictsDialogComponent
  ]
})
export class CampaignModule { }
