import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product, ProductSearch } from './product';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
  private _productsUrl = `${environment.api.rootUrl}products`;

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  searchProducts(productSearch: ProductSearch): Observable<Product[]> {
    return this._http.get<Product[]>(`${this._productsUrl}/search?term=${productSearch.term}&smartboxbrandid=${productSearch.brandId}`)
    .pipe(
      tap(data => console.log(JSON.stringify(data))),
      catchError(this._helperService.handleError)
    );
  }
}
