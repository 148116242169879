import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/notifications.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './state/notifications.effects';
import { NotificationSnackBarComponent } from './components/notification-snack-bar/notification-snack-bar.component';
import { NotificationsPageComponent } from './containers/notifications-page/notifications-page.component';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NotificationUsersComponent } from './components/notification-users/notification-users.component';
import { NotificationMessagesComponent } from './components/notification-messages/notification-messages.component';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('notifications', reducer),
    EffectsModule.forFeature([NotificationEffects]),
    RouterModule.forChild([
      { path: 'notifications', component: NotificationsPageComponent, canActivate: [MsalGuard] }
    ])
  ],
  declarations: [
    NotificationSnackBarComponent,
    NotificationsPageComponent,
    NotificationUsersComponent,
    NotificationMessagesComponent,
  ],
  entryComponents: [
    NotificationSnackBarComponent
  ]
})
export class NotificationsModule { }
