import { AppNotification, NotificationUser } from '../notification';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationActions, NotificationActionTypes } from './notifications.actions';
import { groupBy } from 'rxjs/operators';

export interface NotificationState {
    notifications: AppNotification[];
    lastNotificationReceived: AppNotification;
    selectedUser: string;
}

const initialState: NotificationState = {
    notifications: [],
    selectedUser: '',
    lastNotificationReceived: {
        campaignId: 0,
        from: '',
        id: 0,
        message: '',
        read: false,
        timestamp: 0
    }
};

const getNotificationsFeatureState = createFeatureSelector<NotificationState>('notifications');

export const getNotifications = createSelector(
    getNotificationsFeatureState,
    state => state.notifications
);

export const getUnreadNotificationsCount = createSelector(
    getNotificationsFeatureState,
    state => state.notifications
        ? state.notifications.filter(notification => !notification.read).length
        : 0
);

export const getSelectedUser = createSelector(
    getNotificationsFeatureState,
    state => state.selectedUser
);

export const getUsersWithNotifications = createSelector(
    getNotificationsFeatureState,
    state => {
        const result: NotificationUser[] = [];
        state.notifications.forEach(notification => {
            if (result.findIndex(r => r.name === notification.from) === -1) {
                result.push({
                    name: notification.from,
                    hasUnread: state.notifications
                        .filter(notifCount => notifCount.from === notification.from && !notifCount.read).length > 0,
                    lastNotificationTimestamp: notification.timestamp
                });
            }
        });

        return result;
    }
);

export function reducer(state = initialState, action: NotificationActions): NotificationState {
    switch (action.type) {
        case NotificationActionTypes.receivedNotification:
            return {
                ...state,
                notifications: [
                    {
                        ...action.payload
                    },
                    ...state.notifications
                ],
                lastNotificationReceived: {
                    ...action.payload
                }
            };
        case NotificationActionTypes.markNotificationAsReadSuccess:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.id === action.payload) {
                        notification.read = true;
                        return notification;
                    }
                    return notification;
                })
            };
        case NotificationActionTypes.markUserNotificationsAsReadSuccess:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.from === action.payload) {
                        notification.read = true;
                        return notification;
                    }
                    return notification;
                })
            };
        case NotificationActionTypes.loadNotificationsSuccess:
            return {
                ...state,
                notifications: [
                    ...action.payload
                ]
            };
        case NotificationActionTypes.loadNotificationsFail:
            return {
                ...state,
                notifications: []
            };
        case NotificationActionTypes.selectUser:
            return {
                ...state,
                selectedUser: action.payload
            };
        default:
            return state;
    }
}
