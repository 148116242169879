import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { LiveCampaignActions, LiveCampaignActionTypes } from './live-campaign.actions';

export interface LiveCampaignState {
    liveCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: LiveCampaignState = {
    liveCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 10, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: LiveCampaignActions): LiveCampaignState {
    switch (action.type) {
        case LiveCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                liveCampaigns: action.payload,
                isLoading: false
            };
        case LiveCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                liveCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case LiveCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case LiveCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                liveCampaigns: {
                    ...state.liveCampaigns,
                    pagination: action.payload
                }
            };
        case LiveCampaignActionTypes.setSortingInfo:
            console.log(action.payload);
            return {
                ...state,
                liveCampaigns: {
                    ...state.liveCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
