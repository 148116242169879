import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';

export function uniqueValueValidator(): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    const priorities = c.value.map(item => item.campaignPriority);
    if (hasDuplicates(priorities)) {
      return { duplicate: true };
    }

    return null;
  };
}

function hasDuplicates(priorities: number[]) {
  const counts = [];
  for (let i = 0; i <= priorities.length; i++) {
    if (counts[priorities[i]] === undefined) {
      counts[priorities[i]] = 1;
    } else {
      return true;
    }
  }
  return false;
}
