import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { DraftCampaignActions, DraftCampaignActionTypes } from './draft-campaign.actions';

export interface DraftCampaignState {
    draftCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: DraftCampaignState = {
    draftCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 10, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: DraftCampaignActions): DraftCampaignState {
    switch (action.type) {
        case DraftCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                draftCampaigns: action.payload,
                isLoading: false
            };
        case DraftCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                draftCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case DraftCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case DraftCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                draftCampaigns: {
                    ...state.draftCampaigns,
                    pagination: action.payload
                }
            };
        case DraftCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                draftCampaigns: {
                    ...state.draftCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
