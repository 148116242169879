import { CampaignTypeItem, CampaignBrandItem, CampaignStatus } from '../campaign';
import { CampaignPropertyActions, CampaignPropertyActionTypes } from './campaign-property.actions';

export interface CampaignPropertyState {
    campaignTypes: CampaignTypeItem[];
    campaignTypesLoaded: boolean;
    campaignBrands: CampaignBrandItem[];
    campaignBrandsLoaded: boolean;
    campaignStatuses: CampaignStatus[];
    campaignStatusesLoaded: boolean;
    error: string;
}

const initialState: CampaignPropertyState = {
    campaignTypes: [],
    campaignTypesLoaded: false,
    campaignBrands: [],
    campaignBrandsLoaded: false,
    campaignStatuses: [],
    campaignStatusesLoaded: false,
    error: ''
};

export function reducer(state = initialState, action: CampaignPropertyActions): CampaignPropertyState {
    switch (action.type) {
        case CampaignPropertyActionTypes.loadCampaignTypesSuccess:
            return {
                ...state,
                campaignTypes: action.payload,
                campaignTypesLoaded: true
            };
        case CampaignPropertyActionTypes.loadCampaignTypesFail:
            return {
                ...state,
                campaignTypes: [],
                campaignTypesLoaded: false,
                error: action.payload
            };
        case CampaignPropertyActionTypes.loadCampaignBrandsSuccess:
            return {
                ...state,
                campaignBrands: action.payload,
                campaignBrandsLoaded: true
            };
        case CampaignPropertyActionTypes.loadCampaignBrandsFail:
            return {
                ...state,
                campaignBrands: [],
                campaignBrandsLoaded: false,
                error: action.payload
            };
        case CampaignPropertyActionTypes.loadCampaignStatusesSuccess:
            return {
                ...state,
                campaignStatuses: action.payload,
                campaignStatusesLoaded: true
            };
        case CampaignPropertyActionTypes.loadCampaignStatusesFail:
            return {
                ...state,
                campaignStatuses: [],
                campaignStatusesLoaded: false,
                error: action.payload
            };
        default:
            return state;
    }
}
