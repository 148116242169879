import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bulletPoint'
})
export class BulletPointPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const bulletPoints = value.split('\n');
      let newBulletPoints = '';
      bulletPoints.forEach(bullet => {
        if (bullet) {
          newBulletPoints += `<li>${bullet}</li>`;
        }
      });
      return `<ul style='margin-top:0;'>${newBulletPoints}</ul>`;
    }

    return value;
  }

}
