import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as productActions from './product.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProductService } from '../product.service';
import { ProductSearch } from '../product';


@Injectable()
export class ProductEffects {
    constructor(private _productService: ProductService,
        private _actions$: Actions) { }

    @Effect()
    searchProducts$: Observable<Action> = this._actions$.pipe(
        ofType(productActions.ProductActionTypes.searchProducts),
        map((action: productActions.SearchProducts) => action.payload),
        switchMap((productSearch: ProductSearch) =>
            this._productService.searchProducts(productSearch).pipe(
                map(products => (new productActions.SearchProductsSuccess(products))),
                catchError(err => of(new productActions.SearchProductsFail('Error loading products')))))
    );
}
