import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalModule } from '@azure/msal-angular';
import { LogLevel } from 'msal';
import { DashboardPageComponent } from './dashboard/containers/dashboard-page/dashboard-page.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Constants } from './constants';
import { ByeComponent } from './bye/bye.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SettingsComponent } from './settings/settings.component';

// Features
import { CampaignModule } from './campaigns/campaign.module';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { UserModule } from './user/user.module';
import { reducer } from './state/app.reducer';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';
import { MyCampaignsComponent } from './dashboard/component/my-campaigns/my-campaigns.component';
import { MyRecentActivityComponent } from './dashboard/component/my-recent-activity/my-recent-activity.component';
import { LiveCampaignsSummaryComponent } from './dashboard/component/live-campaigns-summary/live-campaigns-summary.component';
import { PendingApprovalComponent } from './dashboard/component/pending-approval/pending-approval.component';
import { PendingApprovalCampaignEffects } from './campaigns/state/pending-approval-campaign.effects';
import { CreatedByMeCampaignEffects } from './campaigns/state/created-by-me-campaign.effects';
import { OpenedByMeCampaignEffects } from './campaigns/state/opened-by-me-campaign.effects';
import { MsalInterceptorTmp } from './MsalInterceptorTmp';
import { HotkeysHelpDialogComponent } from './hotkeys-help-dialog/hotkeys-help-dialog.component';
import { NotificationsModule } from './notifications/notifications.module';

@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    ByeComponent,
    SidebarComponent,
    SettingsComponent,
    NotFoundComponent,
    NotAuthorisedComponent,
    MyCampaignsComponent,
    MyRecentActivityComponent,
    LiveCampaignsSummaryComponent,
    PendingApprovalComponent,
    HotkeysHelpDialogComponent,
  ],
  imports: [
    BrowserModule,
    UserModule,
    NotificationsModule,
    CampaignModule,
    MsalModule.forRoot({
      clientID: Constants.clientId,
      authority: Constants.stsAuthority,
      cacheLocation: 'localStorage',
      consentScopes: ['https://graph.microsoft.com/User.Read', environment.api.azureADAppPermission],
      level: LogLevel.Verbose,
      piiLoggingEnabled: environment.production ? false : true,
      redirectUri: environment.clientRoot,
      postLogoutRedirectUri: `${environment.clientRoot}bye`,
      protectedResourceMap: Constants.protectedResourceMap,
      unprotectedResources: [],
    }),
    AppRoutingModule,
    StoreModule.forRoot({ applicationState: reducer }),
    StoreDevtoolsModule.instrument({
      name: 'APM Demo App DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([PendingApprovalCampaignEffects, CreatedByMeCampaignEffects, OpenedByMeCampaignEffects])
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptorTmp,
    multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [HotkeysHelpDialogComponent]
})
export class AppModule { }
