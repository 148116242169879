import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DimensionInput, DimensionName, DimensionValue } from './dimension';
import { Observable, Subscription } from 'rxjs';
import * as fromDimension from '../../state';
import { Store, select } from '@ngrx/store';
import * as dimensionActions from '../../state/dimension.actions';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-dimension-input',
  templateUrl: './dimension-input.component.html',
  styleUrls: ['./dimension-input.component.scss']
})
export class DimensionInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dimension: DimensionInput;
  @Output() dimensionChange = new EventEmitter<DimensionInput>();

  dimensionNames$: Observable<DimensionName[]>;
  filteredDimensionValues$: Observable<DimensionValue[]>;
  isLoadingDimensionNames$: Observable<boolean>;
  isLoadingDimensionValues$: Observable<boolean>;

  selectedDimensionInput: DimensionInput;

  private _selectedDimensioName: DimensionName;
  private _selectedDimensionValue: DimensionValue;
  private _formDimensionNameSubsciption: Subscription;
  private _formDimensionValueSubsciption: Subscription;

  dimensionForm: FormGroup;

  get dimensionNameFormControl(): AbstractControl {
    return this.dimensionForm.get('dimensionName');
  }

  get dimensionValueFormControl(): AbstractControl {
    return this.dimensionForm.get('dimensionValue');
  }

  constructor(private _fb: FormBuilder, private _store: Store<fromDimension.State>) {
    this.dimensionForm = this._fb.group({
      dimensionName: [null, [Validators.required]],
      dimensionValue: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.dimensionNames$ = this._store.pipe(select(fromDimension.getDimensionNames));
    this.filteredDimensionValues$ = this._store.pipe(select(fromDimension.getFilteredDimensionValues));

    this.isLoadingDimensionNames$ = this._store.pipe(select(fromDimension.getIsLoadingDimensionNames));
    this.isLoadingDimensionValues$ = this._store.pipe(select(fromDimension.getIsLoadingDimensionValues));

    this._store.dispatch(new dimensionActions.SetIsloadingDimensionNames);
    this._store.dispatch(new dimensionActions.LoadDimensionNames());

    if (!this.dimension) {
      this.dimension = {
        dimensionNameId: 0,
        dimensionName: '',
        dimensionValueId: 0,
        dimensionValue: ''
      };
    }

    this._formDimensionNameSubsciption = this.dimensionForm.get('dimensionName')
      .valueChanges
      .subscribe(value => {
        if (value) {
          this._selectedDimensioName = value;
          this._store.dispatch(new dimensionActions.SetIsloadingDimensionValues);
          this._store.dispatch(new dimensionActions.LoadDimnesionValues(value.dimensionNameId));
        }
      });

    this._formDimensionValueSubsciption = this.dimensionForm.get('dimensionValue')
      .valueChanges
      .subscribe(value => {
        if (value) {
          this._selectedDimensionValue = value;
          this.selectedDimensionInput = {
            dimensionName: this._selectedDimensioName.dimensionName,
            dimensionNameId: this._selectedDimensioName.dimensionNameId,
            dimensionValue: this._selectedDimensionValue.description,
            dimensionValueId: this._selectedDimensionValue.id
          };
          this.dimensionChange.emit(this.selectedDimensionInput);
        }
      });
  }

  clearSelectedDimensionInput() {
    this.selectedDimensionInput = null;
    this.dimensionForm.reset();
    this.dimensionChange.emit(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dimension) {
      const dimensionInput = changes.dimension.currentValue as DimensionInput;
      if (dimensionInput) {
        this.selectedDimensionInput = dimensionInput;
      }
    }
  }

  ngOnDestroy(): void {
    this._formDimensionNameSubsciption.unsubscribe();
    this._formDimensionValueSubsciption.unsubscribe();
  }
}
