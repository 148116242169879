import {
  Component, OnInit, ViewChild, OnDestroy, Input, OnChanges, Output, EventEmitter,
  SimpleChanges, AfterViewInit, Renderer2
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RuleListComponent } from '../rule-list/rule-list.component';
import { Campaign, RuleField, CampaignEffectType, CampaignTypeItem, CampaignBrandItem, CampaignType } from '../../campaign';
import { Subscription } from 'rxjs';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper, MatSelectChange, MatSelect } from '@angular/material';
import * as moment from 'moment';
import { SmartUser } from 'src/app/user/smart-user';
import { Constants } from 'src/app/constants';
import { maxLinesMaxLengthValidator } from 'src/app/shared/max-lines-max-length.directive';
import { CodeValidator } from 'src/app/shared/generic-code-validators.directive';
import { campaignDatesValidator } from 'src/app/shared/date-validators.directive';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(RuleListComponent) ruleList: RuleListComponent;
  @ViewChild(MatStepper) stepper: MatStepper;
  @Input() selectedCampaign: Campaign;
  @Input() campaignTypes: CampaignTypeItem[];
  @Input() campaignBrands: CampaignBrandItem[];
  @Input() checkingConflicts: boolean;
  @Input() savingCampaign: boolean;
  @Input() currentUser: SmartUser;
  @Input() ruleFields: RuleField[];
  @Input() codeDefinitionTypes: any[];
  @Input() campaignEffectTypes: CampaignEffectType[];
  @Input() deliveryMethods: DeliveryMethod[];
  @Input() isLive: boolean;
  @Input() isApproved: boolean;
  @Input() isPending: boolean;
  @Output() isDirty = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Campaign>();
  @Output() saveAndSubmit = new EventEmitter<Campaign>();

  campaign: Campaign | null;
  campaignInformationForm: FormGroup;
  campaignMarketingInformationForm: FormGroup;
  campaignCodeDefinitionForm: FormGroup;
  areRulesValid = false;
  wereRulesValidated = false;
  ruleFieldArray: RuleField[];
  @ViewChild('campaignTypeDropdown') campType: MatSelect;
  @ViewChild('codesTypeDropdown') codesType: MatSelect;

  private _currentUser: SmartUser;
  private _subscriptions: Subscription[] = [];

  get isCurrentUserManager(): boolean {
    return this._currentUser
      ? this._currentUser.roles.indexOf(Constants.roles.manager) > -1
      : false;
  }

  get isCurrentUserCreator(): boolean {
    return this._currentUser
      ? this._currentUser.roles.indexOf(Constants.roles.creator) > -1
      : false;
  }

  get showFlashSaleOption(): boolean {
    if (this.campaign && this.campaignTypes) {
      const selected = this.campaignTypes.find(type => type.id === this.campaign.type);

      return selected
        ? selected.allowFlashSales
        : false;
    }
    return false;
  }

  get useDateTime(): boolean {
    return !this.showFlashSaleOption;
  }

  get showCodeDefinitionSection(): boolean {
    if (this.campaign && this.campaignTypes) {
      return this.campaign.type === CampaignType.discount;
    }
    return false;
  }

  get showMarketingMessages(): boolean {
    return this.campaign && this.showFlashSaleOption && !this.campaign.isFlashSale;
  }

  get codeLengthFormControl() {
    return this.campaignCodeDefinitionForm.get('codeLength');
  }

  get campaignTypeFormControl() {
    return this.campaignInformationForm.get('type');
  }

  get campaignBrandFormControl() {
    return this.campaignInformationForm.get('brand');
  }

  get campaignNameFormControl() {
    return this.campaignInformationForm.get('name');
  }

  get campaignValidFromFormControl() {
    return this.campaignInformationForm.get('validFrom');
  }

  get campaignValidToFormControl() {
    return this.campaignInformationForm.get('validTo');
  }

  get longMarketingMessageFormControl() {
    return this.campaignMarketingInformationForm.get('longMarketingMessage');
  }

  get shortMarketingMessageFormControl() {
    return this.campaignMarketingInformationForm.get('shortMarketingMessage');
  }

  get bulletPointsFormControl() {
    return this.campaignMarketingInformationForm.get('bulletPoints');
  }

  get genericCodeFormControl() {
    return this.campaignCodeDefinitionForm.get('code');
  }

  get canSaveAndSubmit(): boolean {
    return !this.isLive && !this.isPending;
  }

  get canSave(): boolean {
    if (this.isApproved && !this.isLive) {
      return false;
    }

    if (this.isApproved && this.isLive) {
      return this.isCurrentUserCreator;
    }

    if (!this.isLive) {
      return this.isCurrentUserCreator;
    }

    return this.isCurrentUserManager;
  }

  get canAddRule(): boolean {
    if (this.campaign && this.campaign.type === CampaignType.discount) {
      return true;
    } else {
      return this.ruleList.rules.length === 0;
    }
  }

  get showMarketingBulletPoints(): boolean {
    if (this.showMarketingMessages) {
      const ruleWithDimensionEffect = this.ruleList.rules.find(ruleset => {
        if (ruleset.effect && ruleset.effect.dimension) {
          return true;
        } else {
          return false;
        }
      });
      if (ruleWithDimensionEffect) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  constructor(
    private _fb: FormBuilder,
    private _hotkeysService: Hotkeys,
    private _codeValidator: CodeValidator) {
    this.campaignInformationForm = this._fb.group({
      brand: ['', [Validators.required]],
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      isFlashSale: [false],
      description: [''],
      validFrom: [''],
      validTo: ['']
    }, { validator: campaignDatesValidator.bind(this) }
    );

    this.campaignMarketingInformationForm = this._fb.group({
      longMarketingMessage: ['', [Validators.maxLength(100)]],
      shortMarketingMessage: ['', [Validators.maxLength(50)]],
      bulletPoints: ['', [maxLinesMaxLengthValidator(3, 50)]]
    });

    this.campaignCodeDefinitionForm = this._fb.group({
      typeId: ['generic', [Validators.required]],
      code: ['', [Validators.required]],
      numberOfCodes: [''],
      codeLength: [''],
      maxCampaignRedemptions: [0, [Validators.required]],
      // maxRedemptionsPerSession: ['', [Validators.required]],
      maxRedemptionsPerSession: [0],
      maxRedemptionsPerCode: [0],
      prefix: [''],
      disallowedCharacters: [''],
      // isDynamic: ['']
    });

    this.setConditionalValidators();
  }

  setRulesValidated(): void {
    this.wereRulesValidated = true;
  }

  private resetMarketingMessages(): void {
    this.campaignMarketingInformationForm.patchValue({
      shortMarketingMessage: '',
      longMarketingMessage: '',
      bulletPoints: ''
    });
  }

  private disableFieldsIfLive(): void {
    if (this.isLive) {

      // todo: Temp fix while preview camp is done, it should be disabled to everyone
      if (!this.isCurrentUserManager) {
        this.campaignInformationForm.get('type').disable();
      }

      this.campaignInformationForm.get('brand').disable();
      this.campaignCodeDefinitionForm.get('typeId').disable();
      this.campaignCodeDefinitionForm.get('code').disable();
      this.campaignCodeDefinitionForm.get('numberOfCodes').disable();
      this.campaignCodeDefinitionForm.get('codeLength').disable();
      this.campaignCodeDefinitionForm.get('maxCampaignRedemptions').disable();
      this.campaignCodeDefinitionForm.get('maxRedemptionsPerSession').disable();
      this.campaignCodeDefinitionForm.get('maxRedemptionsPerCode').disable();
      this.campaignCodeDefinitionForm.get('prefix').disable();
      this.campaignCodeDefinitionForm.get('disallowedCharacters').disable();
      // this.campaignCodeDefinitionForm.get('isDynamic').disable();
    }
  }

  private setConditionalValidators(): void {
    const code = this.campaignCodeDefinitionForm.get('code');
    const numberOfCodes = this.campaignCodeDefinitionForm.get('numberOfCodes');
    const codeLength = this.campaignCodeDefinitionForm.get('codeLength');
    const maxRedemptionsPerCode = this.campaignCodeDefinitionForm.get('maxRedemptionsPerCode');

    this._subscriptions.push(this.campaignCodeDefinitionForm.get('typeId').valueChanges
      .subscribe(codeType => {
        switch (codeType) {
          case 1:
            code.setValidators([Validators.required]);
            code.setAsyncValidators(this._codeValidator.isCodeAvailable(this.campaign.id));
            numberOfCodes.setValidators(null);
            codeLength.setValidators(null);
            maxRedemptionsPerCode.setValidators([Validators.required]);
            break;
          case 2:
            code.setValidators(null);
            code.setAsyncValidators(null);
            numberOfCodes.setValidators([Validators.required]);
            codeLength.setValidators([Validators.required, Validators.min(12), Validators.max(20)]);
            maxRedemptionsPerCode.setValidators(null);
            break;
        }

        code.updateValueAndValidity();
        numberOfCodes.updateValueAndValidity();
        codeLength.updateValueAndValidity();
        maxRedemptionsPerCode.updateValueAndValidity();
      }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.campType.focus();
      this.isDirty.emit(false);
    }, 100);
  }

  ngOnInit(): void {
    this._subscriptions.push(this.campaignInformationForm.statusChanges.subscribe(() => {
      this.isDirty.emit(true);
    }));

    this._subscriptions.push(this.campaignMarketingInformationForm.statusChanges.subscribe(() => {
      this.isDirty.emit(true);
    }));

    this._subscriptions.push(this.campaignCodeDefinitionForm.statusChanges.subscribe(() => {
      this.isDirty.emit(true);
    }));

    this._subscriptions.push(this.ruleList.rulesChange.subscribe((changed) => {
      this.isDirty.emit(changed);
    }));

    this._subscriptions.push(this.ruleList.isValid.subscribe((valid) => {
      this.areRulesValid = valid;
    }));

    this._subscriptions.push(this.campaignInformationForm.get('isFlashSale').valueChanges
      .subscribe(isFlashSale => {
        this.campaign.rules = [];
        if (isFlashSale != null && isFlashSale !== undefined && isFlashSale !== this.campaign.isFlashSale) {
          this.resetMarketingMessages();
          this.campaign = {
            ...this.campaign,
            isFlashSale: isFlashSale
          };
        }
      }));

    this._subscriptions.push(this.campaignInformationForm.get('brand').valueChanges
      .subscribe(brand => {
        if (brand && brand !== this.campaign.brand) {
          this.campaign = {
            ...this.campaign,
            brand: brand
          };
        }
      }));

    this._currentUser = this.currentUser;
    this.registerHotkeys();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCampaign && changes.selectedCampaign.currentValue) {
      const camp: any = changes.selectedCampaign.currentValue as Campaign;
      this.displayCampaign(camp);
    }

    if (changes.currentUser && changes.currentUser.currentValue) {
      this._currentUser = changes.currentUser.currentValue;
    }

    if (changes.ruleFields && changes.ruleFields.currentValue) {
      this.ruleFieldArray = changes.ruleFields.currentValue;
    }

    if (changes.isLive && changes.isLive.currentValue) {
      this.disableFieldsIfLive();
    }
  }

  stepSelectionChange(event: StepperSelectionEvent) {
    if (event.selectedStep.label === 'Review') {
      this.extractCampaignInfo();
    } else if (event.selectedStep.label === 'Codes') {
      setTimeout(() => {
        this.codesType.focus();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  campaignTypeChanged(event: MatSelectChange): void {
    this.campaign = {
      ...this.campaign,
      type: event.value
    };
  }

  addRule() {
    this.ruleList.addNewRule();
  }

  saveCampaign() {
    this.extractCampaignInfo();
    this.save.emit(this.campaign);
  }

  saveAndSubmitCampaign() {
    this.extractCampaignInfo();
    this.saveAndSubmit.emit(this.campaign);
  }

  cancelCampaign(): void {
    this.cancel.emit();
  }

  stepChanged(index: number) {
    this.stepper.selectedIndex = index;
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.j' })
      .subscribe(() => {
        this.stepper.previous();
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.k' })
      .subscribe(() => {
        this.stepper.next();
      }));
  }

  private extractCampaignInfo(): void {
    this.campaign = {
      ...this.campaign,
      ...this.campaignInformationForm.value,
      ...this.campaignMarketingInformationForm.value,
      bulletPoints: this.showMarketingBulletPoints ? this.bulletPointsFormControl.value : '',
      rules: this.ruleList.rules,
      isFlashSale: this.campaignInformationForm.get('isFlashSale').value ? this.campaignInformationForm.get('isFlashSale').value : false,
      validFrom: this.extractValidFromDate(),
      validTo: this.extractValidToDate(),
      codeDefinition: {
        ...this.campaign.codeDefinition,
        ...this.campaignCodeDefinitionForm.value
      }
    };
  }

  private extractValidFromDate(): number {
    if (this.campaignInformationForm.get('validFrom').value) {
      if (this.useDateTime) {
        return this.campaignInformationForm.get('validFrom').value.unix();
      } else {
        return this.campaignInformationForm.get('validFrom').value.startOf('day').unix();
      }
    } else {
      return 0;
    }
  }

  private extractValidToDate(): number {
    if (this.campaignInformationForm.get('validTo').value) {
      if (this.useDateTime) {
        return this.campaignInformationForm.get('validTo').value.unix();
      } else {
        return this.campaignInformationForm.get('validTo').value.startOf('day').unix();
      }
    } else {
      return 0;
    }
  }

  private displayCampaign(campaign: Campaign | null): void {
    // Set the local campaign property
    this.campaign = { ...campaign };

    if (this.campaign) {
      // Reset values
      this.campaignInformationForm.reset();
      this.campaignMarketingInformationForm.reset();
      this.campaignCodeDefinitionForm.reset();

      // Set form values
      this.setCampaignInfo();
      this.setCodeDefinition();
      this.isDirty.emit(false);
    }
  }

  private setCampaignInfo(): void {
    this.campaignInformationForm.patchValue({
      brand: this.campaign.brand,
      type: this.campaign.type,
      isFlashSale: this.campaign.isFlashSale,
      name: this.campaign.name,
      description: this.campaign.description,
      validFrom: this.campaign.validFrom ? moment.unix(this.campaign.validFrom) : 0,
      validTo: this.campaign.validTo ? moment.unix(this.campaign.validTo) : 0
    });

    this.campaignMarketingInformationForm.patchValue({
      shortMarketingMessage: this.campaign.shortMarketingMessage,
      longMarketingMessage: this.campaign.longMarketingMessage,
      bulletPoints: this.campaign.bulletPoints
    });
  }

  private setCodeDefinition(): void {
    if (this.campaign.codeDefinition) {
      this.campaignCodeDefinitionForm.patchValue({
        typeId: this.campaign.codeDefinition.typeId,
        code: this.campaign.codeDefinition.code,
        numberOfCodes: this.campaign.codeDefinition.numberOfCodes,
        codeLength: this.campaign.codeDefinition.codeLength,
        maxCampaignRedemptions: this.campaign.codeDefinition.maxCampaignRedemptions,
        maxRedemptionsPerSession: this.campaign.codeDefinition.maxRedemptionsPerSession,
        maxRedemptionsPerCode: this.campaign.codeDefinition.maxRedemptionsPerCode,
        prefix: this.campaign.codeDefinition.prefix,
        disallowedCharacters: this.campaign.codeDefinition.disallowedCharacters
      });
    }
  }
}
