import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as allCampaignActions from './all-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CampaignListTab } from '../campaign';

@Injectable()
export class AllCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadAllCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(allCampaignActions.AllCampaignActionTypes.loadCampaigns),
        map((action: allCampaignActions.LoadCampaigns) => action.payload),
        mergeMap((requestInfo) =>
            this._campaignService.getCampaigns(CampaignListTab.all,
                    requestInfo.pagination.index, requestInfo.pagination.size,
                    requestInfo.sorting.fieldName, requestInfo.sorting.direction).pipe(
                map(campaigns => (new allCampaignActions.LoadCampaignsSuccess(campaigns))),
                catchError(() => of(new allCampaignActions.LoadCampaignsFail('Error loading all campaign summaries')))
            )
        )
    );
}
