import { Action } from '@ngrx/store';
import { RuleField, CampaignEffectType } from '../campaign';

export enum RuleBuilderActionTypes {
    loadFields = '[RuleBuilder] Load fields',
    loadFieldsSuccess = '[RuleBuilder] Load fields success',
    loadFieldsFail = '[RuleBuilder] Load fields fail',
    loadCampaignEffectTypes = '[RuleBuilder] Load effect types',
    loadCampaignEffectTypesSuccess = '[RuleBuilder] Load effect types success',
    loadCampaignEffectTypesFail = '[RuleBuilder] Load effect types fail',
}

export class LoadFields implements Action {
    readonly type = RuleBuilderActionTypes.loadFields;
}

export class LoadFieldsSuccess implements Action {
    readonly type = RuleBuilderActionTypes.loadFieldsSuccess;
    constructor(public payload: RuleField[]) { }
}

export class LoadFieldsFail implements Action {
    readonly type = RuleBuilderActionTypes.loadFieldsFail;
    constructor(public payload: string) { }
}

export class LoadCampaignEffectTypes implements Action {
    readonly type = RuleBuilderActionTypes.loadCampaignEffectTypes;
}

export class LoadCampaignEffectTypesSuccess implements Action {
    readonly type = RuleBuilderActionTypes.loadCampaignEffectTypesSuccess;
    constructor(public payload: CampaignEffectType[]) { }
}

export class LoadCampaignEffectTypesFail implements Action {
    readonly type = RuleBuilderActionTypes.loadCampaignEffectTypesFail;
    constructor(public payload: string) { }
}

export type RuleBuilderActions = LoadFields
    | LoadFieldsSuccess
    | LoadFieldsFail
    | LoadCampaignEffectTypes
    | LoadCampaignEffectTypesSuccess
    | LoadCampaignEffectTypesFail;
