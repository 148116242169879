import { Action } from '@ngrx/store';

export enum AppActionTypes {
    setMessage = '[App] Set message'
}

export class SetMessage implements Action {
    readonly type = AppActionTypes.setMessage;
    constructor(public payload: string) { }
}

export type AppActions = SetMessage;
