import { Action } from '@ngrx/store';
import { ApprovedCampaignMessage, RejectedCampaignMessage,
    SubmitCampaignRequestForApprovalMessage, AppNotification } from '../notification';

export enum NotificationActionTypes {
    receivedNotification = '[Notifications] Received notification',
    markNotificationAsRead = '[Notifications] Mark notification as read',
    markNotificationAsReadSuccess = '[Notifications] Mark notification as read success',
    markNotificationAsReadFail = '[Notifications] Mark notification as read fail',
    markUserNotificationsAsRead = '[Notifications] Mark user notifications as read',
    markUserNotificationsAsReadSuccess = '[Notifications] Mark user notifications as read success',
    markUserNotificationsAsReadFail = '[Notifications] Mark user notifications as read fail',
    sendApproveCampaignMessage = '[Notifications] Send approve campaign message',
    sendApproveCampaignMessageSuccess = '[Notifications] Send approve campaign message success',
    sendApproveCampaignMessageFail = '[Notifications] Send approve campaign message fail',
    sendRejectedCampaignMessage = '[Notifications] Send reject campaign message',
    sendRejectedCampaignMessageSuccess = '[Notifications] Send reject campaign message success',
    sendRejectedCampaignMessageFail = '[Notifications] Send reject campaign message fail',
    sendCampaignForApprovalMessage = '[Notifications] Send campaign for approval message',
    sendCampaignRequestForApprovalMessageSuccess = '[Notifications] Send campaign for approval message success',
    sendCampaignRequestForApprovalMessageFail = '[Notifications] Send campaign for approval message fail',
    loadNotifications = '[Notifications] Load notifications',
    loadNotificationsSuccess = '[Notifications] Load notifications success',
    loadNotificationsFail = '[Notifications] Load notifications fail',
    selectUser = '[Notifications] Select user'
}

export class ReceivedNotification implements Action {
    readonly type = NotificationActionTypes.receivedNotification;
    constructor(public payload: AppNotification) { }
}

export class MarkNotificationAsRead implements Action {
    readonly type = NotificationActionTypes.markNotificationAsRead;
    constructor(public payload: number) { }
}

export class MarkNotificationAsReadSuccess implements Action {
    readonly type = NotificationActionTypes.markNotificationAsReadSuccess;
    constructor(public payload: number) { }
}

export class MarkNotificationAsReadFail implements Action {
    readonly type = NotificationActionTypes.markNotificationAsReadFail;
    constructor(public payload: string) { }
}

export class MarkUserNotificationsAsRead implements Action {
    readonly type = NotificationActionTypes.markUserNotificationsAsRead;
    constructor(public payload: string) { }
}

export class MarkUserNotificationsAsReadSuccess implements Action {
    readonly type = NotificationActionTypes.markUserNotificationsAsReadSuccess;
    constructor(public payload: string) { }
}

export class MarkUserNotificationsAsReadFail implements Action {
    readonly type = NotificationActionTypes.markUserNotificationsAsReadFail;
    constructor(public payload: string) { }
}

export class SendApproveCampaignMessage implements Action {
    readonly type = NotificationActionTypes.sendApproveCampaignMessage;
    constructor(public payload: ApprovedCampaignMessage) { }
}

export class SendApproveCampaignMessageSuccess implements Action {
    readonly type = NotificationActionTypes.sendApproveCampaignMessageSuccess;
}

export class SendApproveCampaignMessageFail implements Action {
    readonly type = NotificationActionTypes.sendApproveCampaignMessageFail;
    constructor(public payload: string) { }
}

export class SendRejectedCampaignMessage implements Action {
    readonly type = NotificationActionTypes.sendRejectedCampaignMessage;
    constructor(public payload: RejectedCampaignMessage) { }
}

export class SendRejectedCampaignMessageSuccess implements Action {
    readonly type = NotificationActionTypes.sendRejectedCampaignMessageSuccess;
}

export class SendRejectedCampaignMessageFail implements Action {
    readonly type = NotificationActionTypes.sendRejectedCampaignMessageFail;
    constructor(public payload: string) { }
}

export class SendCampaignRequestForApprovalMessage implements Action {
    readonly type = NotificationActionTypes.sendCampaignForApprovalMessage;
    constructor(public payload: SubmitCampaignRequestForApprovalMessage) { }
}

export class SendCampaignRequestForApprovalMessageSuccess implements Action {
    readonly type = NotificationActionTypes.sendCampaignRequestForApprovalMessageSuccess;
}

export class SendCampaignRequestForApprovalMessageFail implements Action {
    readonly type = NotificationActionTypes.sendCampaignRequestForApprovalMessageFail;
    constructor(public payload: string) { }
}

export class LoadNotifications implements Action {
    readonly type = NotificationActionTypes.loadNotifications;
}

export class LoadNotificationsSuccess implements Action {
    readonly type = NotificationActionTypes.loadNotificationsSuccess;
    constructor(public payload: AppNotification[]) { }
}

export class LoadNotificationsFail implements Action {
    readonly type = NotificationActionTypes.loadNotificationsFail;
    constructor(public payload: string) { }
}

export class SelectUser implements Action {
    readonly type = NotificationActionTypes.selectUser;
    constructor(public payload: string) { }
}

export type NotificationActions = ReceivedNotification
    | SendApproveCampaignMessage
    | SendApproveCampaignMessageSuccess
    | SendApproveCampaignMessageFail
    | SendRejectedCampaignMessage
    | SendRejectedCampaignMessageSuccess
    | SendRejectedCampaignMessageFail
    | SendCampaignRequestForApprovalMessage
    | SendCampaignRequestForApprovalMessageSuccess
    | SendCampaignRequestForApprovalMessageFail
    | LoadNotifications
    | LoadNotificationsSuccess
    | LoadNotificationsFail
    | MarkNotificationAsRead
    | MarkNotificationAsReadSuccess
    | MarkNotificationAsReadFail
    | MarkUserNotificationsAsRead
    | MarkUserNotificationsAsReadSuccess
    | MarkUserNotificationsAsReadFail
    | SelectUser;
