import { SmartUser } from '../smart-user';
import { UserActions, UserActionTypes } from './user.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SmartManagerUser } from '../smart-manager-user';

export interface UserState {
    currentUser: SmartUser;
    loaded: boolean;
    isUserLoggedIn: boolean;
    managers: SmartManagerUser[];
    isLoadingManagers: boolean;
    error: string;
}

const initialState: UserState = {
    currentUser : null,
    loaded: false,
    isUserLoggedIn: false,
    managers: [],
    isLoadingManagers: false,
    error: ''
};

const getUserFeatureState = createFeatureSelector<UserState>('users');

export const getCurrentUser = createSelector(
    getUserFeatureState,
    state => state.currentUser
);

export const getCurrentUserLoaded = createSelector(
    getUserFeatureState,
    state => state.loaded
);

export const getUserIsLoggedIn = createSelector(
    getUserFeatureState,
    state => state.isUserLoggedIn
);

export const getManagers = createSelector(
    getUserFeatureState,
    state => state.managers
);

export const getIsLoadingManagers = createSelector(
    getUserFeatureState,
    state => state.isLoadingManagers
);

export function reducer(state = initialState, action: UserActions): UserState {
    switch (action.type) {
        case UserActionTypes.loadUserProfileSuccess:
            return {
                ...state,
                isUserLoggedIn: true,
                loaded: true,
                currentUser: action.payload
            };
        case UserActionTypes.loadUserProfileFail:
            return {
                ...state,
                isUserLoggedIn: false,
                loaded: false,
                currentUser: null,
                error: action.payload
            };
        case UserActionTypes.loadManagersSuccess:
            return {
                ...state,
                managers: action.payload,
                isLoadingManagers: false
            };
        case UserActionTypes.loadManagersFail:
            return {
                ...state,
                managers: [],
                isLoadingManagers: false,
                error: action.payload
            };
        case UserActionTypes.setIsLoadingManagers:
            return {
                ...state,
                isLoadingManagers: true
            };
        case UserActionTypes.signOutSuccess:
            return {
                ...state,
                isUserLoggedIn: false,
                currentUser: null
            };
        default:
            return state;
    }
}

