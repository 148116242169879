import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

export function campaignDatesValidator(group: FormGroup): {[key: string]: boolean} {
    const validFrom = group.controls.validFrom
        ? group.controls.validFrom.value as moment.Moment
        : null;
    const validTo = group.controls.validTo
        ? group.controls.validTo.value as moment.Moment
        : null;

    if (!validFrom) {
        group.controls.validFrom.setErrors({required: true});
    }

    if (!validTo) {
        group.controls.validTo.setErrors({required: true});
    }

    if (validTo && validFrom && validTo.isBefore(validFrom)) {
        group.controls.validTo.setErrors({isBeforeValidFrom: true});
        return  {
            isBeforeValidFrom: true
        };
    }

    return null;
  }
