import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { ScheduledCampaignActions, ScheduledCampaignActionTypes } from './scheduled-campaign.actions';

export interface ScheduledCampaignState {
    scheduledCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: ScheduledCampaignState = {
    scheduledCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 10, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: ScheduledCampaignActions): ScheduledCampaignState {
    switch (action.type) {
        case ScheduledCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                scheduledCampaigns: action.payload,
                isLoading: false
            };
        case ScheduledCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                scheduledCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case ScheduledCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case ScheduledCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    pagination: action.payload
                }
            };
        case ScheduledCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
