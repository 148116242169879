import { ValidatorFn, AbstractControl } from '@angular/forms';

export function maxLinesMaxLengthValidator(maxLines: number, maxLengthPerLine?: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (control.value) {
            const lines = control.value.split('\n');
            if (lines.length > maxLines) {
                return {
                    'maxLinesMaxLength': {
                        'message': `Maximum allowed lines are ${maxLines}`
                    }
                };
            }

            if (maxLengthPerLine) {
                let tooLong = false;
                lines.forEach(line => {
                    if (line.length > maxLengthPerLine) {
                        tooLong = true;
                    }
                });

                if (tooLong) {
                    return {
                        'maxLinesMaxLength': {
                            'message': `Maximum length per line is ${maxLengthPerLine}`
                        }
                    };
                }
            }
        }
        return null;
    };
}
