export const environment = {
  production: false,
  clientRoot: 'https://em-medusa-appservice-stg.azurewebsites.net/',
  api: {
    rootUrl: 'https://stgdealsapi.buyagift.co.uk/api/v1/manage/',
    azureADAppPermission: 'api://aee432da-49f8-4a0f-9cb9-65dc96842d0c/Deals.Manage'
  },
  appInsights: {
    instrumentationKey: '1efc20bc-a479-4e7e-93e1-b763cb1f2e94'
  },
  app: {
    version: '1.0.20230424.2-rc'
  },
  signalR: {
    rootUrl: 'https://stg-medusa-notifications.buyagift.co.uk/',
    logLevel: 1,
    azureADAppPermission: 'https://buyagift.com/Smart.Discounting.Notifications.Server/notification.send.receive',
    notificationHub: 'https://stg-medusa-notifications.buyagift.co.uk/hubs/notification'
  }
};
