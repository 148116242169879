import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, 
  SimpleChanges } from '@angular/core';
import { Effect, CampaignEffectType, EffectTypeItem } from '../../campaign';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product } from '../../product';
import { DimensionInput } from '../dimension-input/dimension';
import { DeliveryMethod } from '../../postage';
import { Store, select } from '@ngrx/store';
import * as campaignState from '../../state';
import * as postageActions from '../../state/postage.actions';

@Component({
  selector: 'app-rule-effect',
  templateUrl: './rule-effect.component.html',
  styleUrls: ['./rule-effect.component.scss']
})
export class RuleEffectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedEffect: Effect;
  @Input() selectedCampaignType: number;
  @Input() isFlashSale: boolean;
  @Input() campaignEffectTypes: CampaignEffectType[];
  @Input() brandId: number;
  @Output() effectChange = new EventEmitter<void>();

  effect: Effect;
  campaignType: number;
  effectForm: FormGroup;
  deliveryMethods: DeliveryMethod[];
  effectTypes: EffectTypeItem[];

  private _subscriptions: Subscription[] = [];

  get isValid(): boolean {
    return this.effectForm.valid;
  }

  get typeIdFormControl(): AbstractControl {
    return this.effectForm.get('typeId');
  }

  get valueTypeFormControl(): AbstractControl {
    return this.effectForm.get('valueType');
  }

  get valueFormControl(): AbstractControl {
    return this.effectForm.get('value');
  }

  get postageFormControl(): AbstractControl {
    return this.effectForm.get('postage');
  }

  constructor(private _fb: FormBuilder,
      private _store: Store<campaignState.State>) {
    this.effectForm = _fb.group({
      typeId: [null, [Validators.required]],
      valueType: [null],
      value: [null, [Validators.required, Validators.min(1), Validators.pattern('\\d*(\\.\\d{0,2})?')]],
      product: [null],
      dimension: [null],
      postage: [null]
    });
  }

  private setConditionalValidators() {
    const valueType = this.effectForm.get('valueType');
    const value = this.effectForm.get('value');
    const product = this.effectForm.get('product');
    const dimension = this.effectForm.get('dimension');
    const postage = this.effectForm.get('postage');

    this._subscriptions.push(this.effectForm.get('typeId').valueChanges
      .subscribe(effectType => {
        switch (effectType) {
          case 1:
            valueType.setValidators([Validators.required]);
            product.setValidators(null);
            dimension.setValidators(null);
            postage.setValidators(null);
            break;
          case 2:
            valueType.setValidators([Validators.required]);
            product.setValidators([Validators.required]);
            dimension.setValidators(null);
            postage.setValidators(null);
            break;
          case 3:
            valueType.setValidators([Validators.required]);
            product.setValidators(null);
            dimension.setValidators([Validators.required]);
            postage.setValidators(null);
            break;
          case 4:
            valueType.setValidators(null);
            value.setValidators(null);
            product.setValidators(null);
            dimension.setValidators(null);
            postage.setValidators([Validators.required]);
            break;
        }

        valueType.reset();
        value.reset();
        product.reset();
        dimension.reset();
        postage.reset();
      }));
  }

  ngOnInit() {
    this.setConditionalValidators();
    this._subscriptions.push(this.effectForm.valueChanges.subscribe(() => {
      this.effect = this.getEffect();
      this.effectChange.emit();
    }));
    this._subscriptions.push(this._store.pipe(select(campaignState.getDeliveryMethods)).subscribe((deliveryMethods) => {
      this.deliveryMethods = deliveryMethods;
    }));
    this._store.dispatch(new postageActions.LoadDeliveryMethods());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedEffect && changes.selectedEffect.currentValue) {
      this.effect = {
        ...changes.selectedEffect.currentValue
      };
      this.displayEffect();
    }
    if (changes.selectedCampaignType && changes.selectedCampaignType.currentValue) {
      if (this.campaignEffectTypes) {
        this.refreshEffectTypes();
      }
    }
    if (changes.campaignEffectTypes && changes.campaignEffectTypes.currentValue) {
      if (this.selectedCampaignType) {
        this.refreshEffectTypes();
      }
    }
    if (changes.isFlashSale) {
      if (changes.isFlashSale.currentValue !== undefined && changes.isFlashSale.currentValue !== null) {
        this.refreshEffectTypes();
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  productChanged(product: Product) {
    this.effectForm.controls['product'].setValue(product);
  }

  dimensionChanged(dimensionInput: DimensionInput) {
    this.effectForm.controls['dimension'].setValue(dimensionInput);
  }

  private refreshEffectTypes() {
    if (this.campaignEffectTypes) {
      const result = this.campaignEffectTypes
      .find(effectType => effectType.campaignTypeId === this.selectedCampaignType);
      if (result) {
        this.effectTypes = result.effectTypeDetails.filter(effectType => effectType.justWithFlashSales === this.isFlashSale);
      }
    }
  }

  private getEffect(): Effect {
    const selectedDeliveryMethodId = +this.effectForm.get('postage').value;
    const effect = {
      ...this.effect,
      ...this.effectForm.value,
      isPercent: this.effectForm.get('valueType').value === 'percentOff'
    };

    if (selectedDeliveryMethodId) {
      effect.postage = {
        id: selectedDeliveryMethodId,
        name: this.deliveryMethods.find(deliveryMethod => deliveryMethod.id === selectedDeliveryMethodId).name
      };
    }

    return effect;
  }

  private displayEffect(): void {
    this.effectForm.patchValue({
      typeId: this.effect.typeId,
      valueType: this.effect.valueType,
      value: this.effect.value,
      product: this.effect.product,
      dimension: this.effect.dimension,
      postage: this.effect.postage
        ? this.effect.postage.id
        : null
    });
  }
}
