import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardPageComponent } from './dashboard/containers/dashboard-page/dashboard-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { ByeComponent } from './bye/bye.component';
import { SettingsComponent } from './settings/settings.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RoleGuard } from './role.guard';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardPageComponent, canActivate: [MsalGuard, RoleGuard],
    data: { roles: ['Discounting.Viewer']} },
  { path: 'settings', component: SettingsComponent, canActivate: [MsalGuard, RoleGuard],
    data: { roles: ['Discounting.Viewer'] } },
  { path: 'bye', component: ByeComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '403', component: NotAuthorisedComponent },
  { path: '**', component: NotFoundComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
