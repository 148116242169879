import { Component, OnInit, Output, EventEmitter, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCampaign from '../../state';
import * as searchActions from '../../state/filtered-campaign.actions';
import { CampaignSummaryResult, SearchRequest, CampaignSummary, SortDirection, SortFieldName } from '../../campaign';
import { Router } from '@angular/router';
import { PageEvent, Sort } from '@angular/material';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-campaign-search',
  templateUrl: './campaign-search.component.html',
  styleUrls: ['./campaign-search.component.scss']
})
export class CampaignSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() searchActivatedEvent = new EventEmitter<boolean>();

  searchForm: FormGroup;
  searchActivated = false;
  filteredCampaignSummaries: CampaignSummaryResult;
  loadingFilteredCampaigs$: Observable<boolean>;
  displayedColumns: string[] = ['id', 'brand', 'campaignType', 'name', 'createdBy', 'validFrom', 'validTo'];

  private _subscriptions: Subscription[] = [];

  get searchInputValue(): string {
    return this.searchForm.get('campaignSearchInput').value;
  }

  get searchInput(): AbstractControl {
    return this.searchForm.get('campaignSearchInput');
  }


  get emptySearch(): boolean {
    return this.filteredCampaignSummaries
      ? this.filteredCampaignSummaries.campaigns.length === 0
      : true;
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromCampaign.State>,
    private _renderer: Renderer2,
    private _hotkeysService: Hotkeys,
    private _router: Router,
    private _titleService: Title
  ) { }

  ngOnInit() {
    this.searchForm = this._fb.group({
      campaignSearchInput: [''],
    });

    this.loadingFilteredCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingFilteredCampaigns));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getFilteredCampaignSummaries))
      .subscribe(campSummaries => {
        if (campSummaries) {
          this.filteredCampaignSummaries = campSummaries;
        }
        this.initializeForm();
        this.subscribeToSearchInputChanges();
      }));

    this.loadFilteredCampaigns(this.getSearchRequest());
    this.registerHotkeys();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._renderer.selectRootElement('#inputSearch').focus();
    }, 100);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  viewCampaign(campaign: CampaignSummary) {
    this._router.navigateByUrl(`/campaigns/${campaign.id}/view`);
  }

  pagination(pageEvent: PageEvent) {
    const currentSorting = this.getSearchRequest().sortingInfo;
    this._store.dispatch(new searchActions
      .SetSearchRequestInfo({
        pagination: { index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length },
        searchInfo: { searchTerm: this.filteredCampaignSummaries.searchInfo.searchTerm },
        sortingInfo: { fieldName: currentSorting.fieldName, direction: currentSorting.direction }
      }));

    this.loadFilteredCampaigns(this.getSearchRequest());
  }

  sortData(sort: Sort): void {
    const currentPagination = this.getSearchRequest().pagination;
    this._store.dispatch(new searchActions
      .SetSearchRequestInfo({
        pagination: { index: 0, size: currentPagination.size, count: currentPagination.count },
        searchInfo: { searchTerm: this.filteredCampaignSummaries.searchInfo.searchTerm },
        sortingInfo: { fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }
      }));

    this.loadFilteredCampaigns(this.getSearchRequest());
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'esc' })
      .subscribe(() => {
        this.searchInput.setValue('');
      }));
  }

  private initializeForm(): void {
    if (this.filteredCampaignSummaries && this.filteredCampaignSummaries.searchInfo) {
      this.searchForm.patchValue({
        campaignSearchInput: this.filteredCampaignSummaries.searchInfo.searchTerm
      });
    }
    this.setSearchActivation(this.searchInputValue);
  }

  private setSearchActivation(searchTerm: string): void {
    this.searchActivated = searchTerm ? true : false;
    this.searchActivatedEvent.emit(this.searchActivated);
  }

  private subscribeToSearchInputChanges(): void {
    this._subscriptions.push(this.searchForm.get('campaignSearchInput')
      .valueChanges
      .debounceTime(900)
      .subscribe(value => {
        if (this.filteredCampaignSummaries && this.filteredCampaignSummaries.searchInfo
          && value !== this.filteredCampaignSummaries.searchInfo.searchTerm) {
          this.setSearchActivation(value);
          const searchRequest = this.filteredCampaignSummaries
            ? this.updateSearchRequest(this.filteredCampaignSummaries, value)
            : this.getDefaultSearchRequest(value);
          this.setTitle(value);
          this.loadFilteredCampaigns(searchRequest);
        }
      }));
  }

  private setTitle(searchTerm: string) {
    if (searchTerm) {
      this._titleService.setTitle(`Search: ${searchTerm}`);
    } else {
    this._titleService.setTitle('Campaigns');
    }
  }

  private loadFilteredCampaigns(searchRequest: SearchRequest): void {
    if (searchRequest.searchInfo.searchTerm) {
      this._store.dispatch(new searchActions.SetIsLoadingCampaigns());
      this._store.dispatch(new searchActions.LoadCampaigns(searchRequest));
    }
  }

  private getSearchRequest(): SearchRequest {
    return this.filteredCampaignSummaries
      ? this.extractSearchRequest(this.filteredCampaignSummaries)
      : this.getDefaultSearchRequest('');
  }

  private extractSearchRequest(campaignSummaryResult: CampaignSummaryResult): SearchRequest {
    return {
      pagination: campaignSummaryResult.pagination,
      searchInfo: campaignSummaryResult.searchInfo,
      sortingInfo: campaignSummaryResult.sortingInfo
    };
  }

  private updateSearchRequest(campaignSummaryResult: CampaignSummaryResult, searchTerm: string): SearchRequest {
    return {
      pagination: campaignSummaryResult.pagination,
      sortingInfo: campaignSummaryResult.sortingInfo,
      searchInfo: {
        ...campaignSummaryResult.searchInfo,
        searchTerm: searchTerm
      }
    };
  }

  private getDefaultSearchRequest(searchTerm: string): SearchRequest {
    return {
      pagination: {
        count: 0,
        index: 0,
        size: 20
      },
      searchInfo: {
        searchTerm: searchTerm
      },
      sortingInfo: {
        fieldName: SortFieldName.empty,
        direction: SortDirection.none
      }
    };
  }
}
