import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { QueryBuilderConfig, QueryBuilderComponent } from 'angular2-query-builder';
import { Ruleset, RuleField, CampaignEffectType, CampaignType, DragAndDropAction } from '../../campaign';
import { RuleEffectComponent } from '../rule-effect/rule-effect.component';
import { FieldMap } from 'angular2-query-builder/dist/components';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss']
})
export class RuleComponent implements OnInit, OnChanges {
  @ViewChild(RuleEffectComponent) ruleEffect: RuleEffectComponent;
  @ViewChild(QueryBuilderComponent) queryBuilder: QueryBuilderComponent;
  @Input() selectedRule: Ruleset;
  @Input() campaignType: number;
  @Input() isFlashSale: boolean;
  @Input() brandId: number;
  @Output() deleteRuleClick = new EventEmitter<void>();
  @Output() ruleChange = new EventEmitter<void>();
  @Output() dropEvent = new EventEmitter<DragAndDropAction>();

  deliveryMethods: DeliveryMethod[];
  ruleFields: RuleField[];
  campaignEffectTypes: CampaignEffectType[];
  config: QueryBuilderConfig;
  rule: Ruleset;
  mappedFields: FieldMap = {};

  private _isValid = false;
  private _hightlightDrop = false;

  get isValid(): boolean {
    return this._isValid && this.ruleEffect ? this.ruleEffect.isValid : false;
  }

  get allowAddConditionGroup(): boolean {
    if (this.campaignType === CampaignType.discount) {
      return true;
    } else {
      return false;
    }
  }

  get allowAndLogicalOperator(): boolean {
    return this.campaignType === CampaignType.discount;
  }

  get ruleSku(): string {
    return this.rule.sku;
  }

  get dropCssClass(): string[] {
    return !this._hightlightDrop
      ? ['drop']
      : ['drop', 'highlight'];
  }

  constructor() { }

  ngOnInit() {
    this.initializeQueryBuilder();
    this.mapRuleFields();
  }

  private mapRuleFields(): void {
    this.ruleFields.forEach((field) => {
      if (field.campaignTypeIds.includes(this.campaignType)) {
        this.mappedFields[field.name] = {
          name: field.description,
          type: field.valueType,
          operators: field.operators.map(operator => {
            return operator.value;
          }),
          options: field.name === 'delivery-method'
            ? this.deliveryMethods.map(deliveryMethod => {
              return {
                name: deliveryMethod.name,
                value: deliveryMethod.id
              };
            })
            : undefined
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRule && changes.selectedRule.currentValue) {
      this.rule = { ...changes.selectedRule.currentValue };
    }
  }

  ruleChanged() {
    this._isValid = this.validate(this.rule);
    this.ruleChange.emit();
  }

  effectChanged() {
    if (this.ruleEffect) {
      this.rule.effect = this.ruleEffect.effect;
    }
    this.ruleChanged();
  }

  drag(ev) {
    ev.dataTransfer.setData('ruleId', ev.target.id);
  }

  drop(ev) {
    ev.preventDefault();
    this._hightlightDrop = false;
    const sku = ev.dataTransfer.getData('ruleId');
    this.dropEvent.emit({ dragRuleSku: sku, dragOnTopOfRuleSku: this.ruleSku });
  }

  allowDrop(ev) {
    this._hightlightDrop = true;
    ev.preventDefault();
  }

  dropDragLeave(): void {
    this._hightlightDrop = false;
  }

  private validate(rule: Ruleset): boolean {
    if (!rule.rules || rule.rules.length === 0) {
      return rule.value !== null && rule.value !== undefined;
    } else {
      return this.validateRulesetArray(rule.rules);
    }
  }

  private validateRulesetArray(rules: Ruleset[]): boolean {
    let valid = true;
    rules.forEach(rule => {
      if (!this.validate(rule)) {
        valid = false;
      }
    });

    return valid;
  }

  private initializeQueryBuilder(): void {
    this.config = {
      fields: this.mappedFields
    };
  }
}
