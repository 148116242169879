import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as pendingApprovalCampaignActions from './pending-approval-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

@Injectable()
export class PendingApprovalCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadPendingApprovalCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(pendingApprovalCampaignActions.PendingApprovalCampaignActionTypes.loadCampaigns),
        map((action: pendingApprovalCampaignActions.LoadCampaigns) => action.payload),
        mergeMap((requestInfo) =>
            this._campaignService.getPendingCampaigns(requestInfo.pagination.index,
                requestInfo.pagination.size, requestInfo.sorting.fieldName, requestInfo.sorting.direction).pipe(
                map(campaigns => (new pendingApprovalCampaignActions.LoadCampaignsSuccess(campaigns))),
                catchError(() => of(new pendingApprovalCampaignActions.LoadCampaignsFail('Error loading campaigns pending approval')))
            )
        )
    );
}
