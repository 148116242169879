import { environment } from 'src/environments/environment';

export class Constants {
    public static clientId = '11f65e65-f30e-46d5-8c08-2376e141f3f2';
    public static stsAuthority = 'https://login.microsoftonline.com/1691bca2-aa30-424d-b587-61d9d74d6c21';
    public static protectedResourceMap: [string, string[]][] = [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [environment.api.rootUrl, [environment.api.azureADAppPermission]],
        [environment.signalR.rootUrl, [environment.signalR.azureADAppPermission]]
    ];
    public static roles = {
        manager: 'Discounting.Manager',
        creator: 'Discounting.Creator',
        viewer: 'Discounting.Viewer'
    };
}
