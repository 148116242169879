import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum DraftCampaignActionTypes {
    loadCampaigns = '[Draft Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Draft Campaigns] Load campaigns success',
    loadCampaignsFail = '[Draft Campaigns] Load campaigns fail',
    setPaginationInfo = '[Draft Campaigns] Set pagination info',
    setSortingInfo = '[Draft Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Draft Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = DraftCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = DraftCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = DraftCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = DraftCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = DraftCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = DraftCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type DraftCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
