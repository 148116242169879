import { AppActions, AppActionTypes } from './app.actions';
import * as fromUser from '../user/state/user.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AppState {
    message: string;
}

const initialState: AppState = {
    message: ''
};


export const getUserIsLoggedIn = fromUser.getUserIsLoggedIn;
const getAppFeatureState = createFeatureSelector<AppState>('applicationState');

export const getMessage = createSelector(
    getAppFeatureState,
    state => state.message
);


export function reducer(state = initialState, action: AppActions): AppState {
    switch (action.type) {
        case AppActionTypes.setMessage:
            return {
                ...state,
                message: action.payload
            };
        default:
            return state;
    }
}
