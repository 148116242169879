import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum AllCampaignActionTypes {
    loadCampaigns = '[All Campaigns] Load campaigns',
    loadCampaignsSuccess = '[All Campaigns] Load campaigns success',
    loadCampaignsFail = '[All Campaigns] Load campaigns fail',
    setPaginationInfo = '[All Campaigns] Set pagination info',
    setSortingInfo = '[All Campaigns] Set sorting info',
    setIsloadingCampaigns = '[All Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = AllCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = AllCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = AllCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = AllCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = AllCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = AllCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type AllCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
