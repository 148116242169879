import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DimensionName, DimensionValue } from './components/dimension-input/dimension';
import { catchError, tap } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DimensionService {
    private _dimensionsUrl = `${environment.api.rootUrl}dimensions`;

    constructor(private _http: HttpClient, private _helperService: HelperService) { }

    getDimensionNames(): Observable<DimensionName[]> {
        return this._http.get<DimensionName[]>(this._dimensionsUrl)
            .pipe(
                tap(data => console.log(JSON.stringify(data))),
                catchError(this._helperService.handleError)
            );
    }

    getDimensionValues(dimensionNameId: number): Observable<DimensionValue[]> {
      return this._http.get<DimensionValue[]>(`${this._dimensionsUrl}/${dimensionNameId}`)
        .pipe(
          tap(data => console.log(JSON.stringify(data))),
          catchError(this._helperService.handleError)
        );
    }
}
