import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { ExpiredCampaignActions, ExpiredCampaignActionTypes } from './expired-campaign.actions';

export interface ExpiredCampaignState {
    expiredCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: ExpiredCampaignState = {
    expiredCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 10, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: ExpiredCampaignActions): ExpiredCampaignState {
    switch (action.type) {
        case ExpiredCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                expiredCampaigns: action.payload,
                isLoading: false
            };
        case ExpiredCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                expiredCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case ExpiredCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case ExpiredCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                expiredCampaigns: {
                    ...state.expiredCampaigns,
                    pagination: action.payload
                }
            };
        case ExpiredCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                expiredCampaigns: {
                    ...state.expiredCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
