import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as draftCampaignActions from './draft-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CampaignListTab } from '../campaign';

@Injectable()
export class DraftCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadDraftCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(draftCampaignActions.DraftCampaignActionTypes.loadCampaigns),
        map((action: draftCampaignActions.LoadCampaigns) => action.payload),
        mergeMap((requestInfo) =>
            this._campaignService.getCampaigns(CampaignListTab.draft,
                    requestInfo.pagination.index, requestInfo.pagination.size,
                    requestInfo.sorting.fieldName, requestInfo.sorting.direction).pipe(
                map(campaigns => (new draftCampaignActions.LoadCampaignsSuccess(campaigns))),
                catchError(err => of(new draftCampaignActions.LoadCampaignsFail('Error laoding draft campaigns')))
            )
        )
    );
}
