import { Component, Input } from '@angular/core';
import { Ruleset } from '../../campaign';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-rule-condition-viewer',
  templateUrl: './rule-condition-viewer.component.html',
  styleUrls: ['./rule-condition-viewer.component.scss']
})
export class RuleConditionViewerComponent {
  @Input() rules: Ruleset[];
  @Input() condition: string;
  @Input() deliveryMethods: DeliveryMethod[];
  fieldMap: any = { 'basket-total': 'Basket Total', 'dimension': 'Dimension', 'product': 'Product', 'delivery-method': 'Delivery Method' };

  constructor() { }

  getDeliveryMethod(deliveryMethodId: number): DeliveryMethod {
    return this.deliveryMethods
      ? this.deliveryMethods.find( method => method.id === deliveryMethodId)
      : null;
  }
}
