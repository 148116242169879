import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { PendingApprovalCampaignActions, PendingApprovalCampaignActionTypes } from './pending-approval-campaign.actions';

export interface PendingApprovalCampaignState {
    pendingApprovalCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: PendingApprovalCampaignState = {
    pendingApprovalCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 3, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: PendingApprovalCampaignActions): PendingApprovalCampaignState {
    switch (action.type) {
        case PendingApprovalCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                pendingApprovalCampaigns: action.payload,
                isLoading: false
            };
        case PendingApprovalCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                pendingApprovalCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case PendingApprovalCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case PendingApprovalCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                pendingApprovalCampaigns: {
                    ...state.pendingApprovalCampaigns,
                    pagination: action.payload
                }
            };
        case PendingApprovalCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                pendingApprovalCampaigns: {
                    ...state.pendingApprovalCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
