import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum PendingApprovalCampaignActionTypes {
    loadCampaigns = '[Pending Approval Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Pending Approval Campaigns] Load campaigns success',
    loadCampaignsFail = '[Pending Approval Campaigns] Load campaigns fail',
    setPaginationInfo = '[Pending Approval Campaigns] Set pagination info',
    setSortingInfo = '[Pending Approval Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Pending Approval Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = PendingApprovalCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = PendingApprovalCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = PendingApprovalCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = PendingApprovalCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = PendingApprovalCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = PendingApprovalCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type PendingApprovalCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
