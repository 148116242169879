import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeliveryMethod } from './postage';
import { tap, catchError } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeliveryMethodService {
  private _deliveryMethodsUrl = `${environment.api.rootUrl}delivery-methods`;

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  getDeliveryMethods(): Observable<DeliveryMethod[]> {
    return this._http.get<DeliveryMethod[]>(this._deliveryMethodsUrl)
    .pipe(
      tap(data => console.log(JSON.stringify(data))),
      catchError(this._helperService.handleError)
    );
  }
}
