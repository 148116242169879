import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserState } from './user/state/user.reducer';
import { Store, select } from '@ngrx/store';
import * as fromUser from './user/state/user.reducer';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/switchMap';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _store: Store<UserState>, private _router: Router) {}


  waitForUserToLoad(): Observable<boolean> {
    return this._store.pipe(select(fromUser.getCurrentUserLoaded))
      .filter(loaded => loaded)
      .take(1);
  }

  isUserInRole(roles: Array<string>): Observable<boolean> {
    return this._store.pipe(select(fromUser.getCurrentUser))
    .map(user => {
      if (user && user.roles.filter(role => roles.includes(role)).length > 0) {
        return true;
      } else {
        this._router.navigateByUrl('/403');
        return false;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const roles = route.data.roles as Array<string>;

      return this.waitForUserToLoad()
        .switchMap(() => this.isUserInRole(roles));
  }
}
