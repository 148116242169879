import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { SmartUser } from 'src/app/user/smart-user';
import { Store, select } from '@ngrx/store';
import * as fromUser from '../../../user/state/user.reducer';
import * as fromCampaign from '../../../campaigns/state';
import { Constants } from 'src/app/constants';
import { CampaignSummaryResult, PaginationInfo, CampaignSummary, SortingInfo,
  SortFieldName, SortDirection, CampaignListingRequestInfo } from 'src/app/campaigns/campaign';
import * as liveCampaignActions from '../../../campaigns/state/live-campaign.actions';
import * as pendingApprovalCampaignActions from '../../../campaigns/state/pending-approval-campaign.actions';
import * as createdByMeCampaignActions from '../../../campaigns/state/created-by-me-campaign.actions';
import * as openedByMeCampaignActions from '../../../campaigns/state/opened-by-me-campaign.actions';
import { Router } from '@angular/router';
import { PageEvent, Sort } from '@angular/material';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { Title } from '@angular/platform-browser';
import { FaviconService } from 'src/app/favicon.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  pendingApprovalCampaignSummaries: CampaignSummaryResult;
  createdByMeCampaignSummaries: CampaignSummaryResult;
  openedByMeCampaignSummaries: CampaignSummaryResult;
  liveCampaignSummaries: CampaignSummaryResult;

  loadingPendingApprovalCampaigns$: Observable<boolean>;
  loadingCreatedByMeCampaigns$: Observable<boolean>;
  loadingOpenedByMeCampaigns$: Observable<boolean>;
  loadingLiveCampaigns$: Observable<boolean>;

  private _subscriptions: Subscription[] = [];
  private _currentUser: SmartUser;

  get isCreator(): boolean {
    return this._currentUser
      ? this._currentUser.roles.indexOf(Constants.roles.creator) > -1
      : false;
  }

  get isManager(): boolean {
    return this._currentUser
      ? this._currentUser.roles.indexOf(Constants.roles.manager) > -1
      : false;
  }

  get showMyCampaigns(): boolean {
    return this.isCreator && !this.isManager;
  }

  get showPendingApprovalCampaigns(): boolean {
    return this.isManager;
  }

  get showMyTasksSection(): boolean {
    return this.isCreator || this.isManager;
  }

  constructor(
    private _userStore: Store<fromUser.UserState>,
    private _store: Store<fromCampaign.State>,
    private _hotkeysService: Hotkeys,
    private _router: Router,
    private _titleService: Title,
    faviconService: FaviconService
  ) {
    faviconService.update();
  }

  ngOnInit() {
    this._titleService.setTitle('Dashboard');
    this.loadingLiveCampaigns$ = this._store.pipe(select(fromCampaign.getIsLoadingLiveCampaigns));
    this.loadingPendingApprovalCampaigns$ = this._store.pipe(select(fromCampaign.getIsLoadingPendingApprovalCampaigns));
    this.loadingCreatedByMeCampaigns$ = this._store.pipe(select(fromCampaign.getIsLoadingCreatedByMeCampaigns));
    this.loadingOpenedByMeCampaigns$ = this._store.pipe(select(fromCampaign.getIsLoadingOpenedByMeCampaigns));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getLiveCampaignSummaries)).subscribe(campSummaries => {
      this.liveCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getPendingApprovalCampaignSummaries)).subscribe(campSummaries => {
      this.pendingApprovalCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getCreatedByMeCampaignSummaries)).subscribe(campSummaries => {
      this.createdByMeCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getOpenedByMeCampaignSummaries)).subscribe(campSummaries => {
      this.openedByMeCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._userStore.pipe(select(fromUser.getCurrentUser)).subscribe(currentUser => {
      this._currentUser = currentUser;
    }));

    this.loadCampaigns();
    this.registerHotkeys();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  viewCampaign(campaign: CampaignSummary) {
    this._router.navigateByUrl(`/campaigns/${campaign.id}/view`);
  }

  pendingApprovalPagination(pageEvent: PageEvent): void {
    this._store.dispatch(new pendingApprovalCampaignActions
      .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
    this.loadPendingApprovalCampaigns();
  }

  openedByMePagination(pageEvent: PageEvent): void {
    this._store.dispatch(new openedByMeCampaignActions
        .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
    this.loadOpenedByMeCampaigns();
  }

  livePagination(pageEvent: PageEvent): void {
    this._store.dispatch(new liveCampaignActions
        .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
    this.loadLiveCampaigns();
  }

  createdByMePagination(pageEvent: PageEvent): void {
    this._store.dispatch(new createdByMeCampaignActions
        .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
    this.loadCreatedByMeCampaigns();
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.r' })
      .subscribe(() => {
        this.loadCampaigns();
      }));
  }

  pendingApprovalSorting(sort: Sort): void {
    const currentPagination = this.pendingApprovalCampaignSummaries
      ? this.pendingApprovalCampaignSummaries.pagination
      : this.getDefaultPagination();
    this._store.dispatch(new pendingApprovalCampaignActions
      .SetPaginationInfo({ index: 0, size: currentPagination.size, count: currentPagination.count }));
    this._store.dispatch(new pendingApprovalCampaignActions
      .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
    this.loadPendingApprovalCampaigns();
  }

  openedByMeSorting(sort: Sort): void {
    const currentPagination = this.openedByMeCampaignSummaries
      ? this.openedByMeCampaignSummaries.pagination
      : this.getDefaultPagination();
    this._store.dispatch(new openedByMeCampaignActions
      .SetPaginationInfo({ index: 0, size: currentPagination.size, count: currentPagination.count }));
    this._store.dispatch(new openedByMeCampaignActions
      .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
    this.loadOpenedByMeCampaigns();
  }

  liveSorting(sort: Sort): void {
    const currentPagination = this.liveCampaignSummaries
      ? this.liveCampaignSummaries.pagination
      : this.getDefaultPagination();
    this._store.dispatch(new liveCampaignActions
      .SetPaginationInfo({ index: 0, size: currentPagination.size, count: currentPagination.count }));
    this._store.dispatch(new liveCampaignActions
      .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
    this.loadLiveCampaigns();
  }

  createdByMeSorting(sort: Sort): void {
    const currentPagination = this.createdByMeCampaignSummaries
      ? this.createdByMeCampaignSummaries.pagination
      : this.getDefaultPagination();
    this._store.dispatch(new createdByMeCampaignActions
      .SetPaginationInfo({ index: 0, size: currentPagination.size, count: currentPagination.count }));
    this._store.dispatch(new createdByMeCampaignActions
      .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
    this.loadCreatedByMeCampaigns();
  }

  private loadCampaigns(): void {
    this.loadLiveCampaigns();
    this.loadOpenedByMeCampaigns();
    if (this.isManager) {
      this.loadPendingApprovalCampaigns();
    }
    if (this.isCreator && !this.isManager) {
      this.loadCreatedByMeCampaigns();
    }
  }

  loadLiveCampaigns(): void {
    this._store.dispatch(new liveCampaignActions.SetIsLoadingCampaigns());
    this._store.dispatch(new liveCampaignActions.LoadCampaigns(
      this.getCampaignListRequest(this.liveCampaignSummaries)));
  }

  loadPendingApprovalCampaigns(): void {
    this._store.dispatch(new pendingApprovalCampaignActions.SetIsLoadingCampaigns());
    this._store.dispatch(new pendingApprovalCampaignActions.LoadCampaigns(
      this.getCampaignListRequest(this.pendingApprovalCampaignSummaries)));
  }

  loadCreatedByMeCampaigns(): void {
    this._store.dispatch(new createdByMeCampaignActions.SetIsLoadingCampaigns());
    this._store.dispatch(new createdByMeCampaignActions.LoadCampaigns(
      this.getCampaignListRequest(this.createdByMeCampaignSummaries)));
  }

  loadOpenedByMeCampaigns(): void {
    this._store.dispatch(new openedByMeCampaignActions.SetIsLoadingCampaigns());
    this._store.dispatch(new openedByMeCampaignActions.LoadCampaigns(
      this.getCampaignListRequest(this.openedByMeCampaignSummaries)));
  }

  private getDefaultPagination(): PaginationInfo {
    return {
      count: 0,
      index: 0,
      size: 3
    };
  }

  private getDefaultSorting(): SortingInfo {
    return {
      fieldName: SortFieldName.empty,
      direction: SortDirection.none
    };
  }

  private getCampaignListRequest(campaignSummary: CampaignSummaryResult): CampaignListingRequestInfo {
    if (campaignSummary) {
      return {
        pagination: campaignSummary.pagination,
        sorting: campaignSummary.sortingInfo
      };
    } else {
      return {
        pagination: this.getDefaultPagination(),
        sorting: this.getDefaultSorting()
      };
    }
  }
}
