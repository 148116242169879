import { Product } from './product';
import { DimensionInput } from './components/dimension-input/dimension';

export interface Campaign {
    id: number;
    campaignGuid: string;
    type: number | null;
    concurrencyKey: string;
    brand: number;
    name: string;
    description: string;
    isFlashSale: boolean;
    validFrom: number | null;
    validTo: number | null;
    status: number;
    createdBy: string;
    createdOn: number | null;
    updatedBy: string;
    updatedOn: number | null;
    approvedBy: string;
    approvedOn: number | null;
    longMarketingMessage: string;
    shortMarketingMessage: string;
    bulletPoints: string;
    codeDefinition: CodeDefinition;
    priority: number;
    nominatedManager: string;
    rules: Ruleset[];
}

export interface Ruleset {
    id: number;
    sku: string;
    priority: number;
    name: string;
    condition: string;
    rules: Ruleset[];
    field: string;
    operator: string;
    value: any;
    effect: Effect;
}

export interface RuleField {
    id: number;
    description: string;
    name: string;
    valueType: string;
    operators: RuleFieldOperator[];
    campaignTypeIds: number[];
}

export interface RuleFieldOperator {
    id: number;
    value: string;
}

export interface CampaignRuleMessaging {
    productPageMessage: string;
    listingMessage: string;
}

export interface Effect {
    typeId: number;
    description: string;
    valueType: string;
    isPercent: boolean;
    value: number;
    product: Product;
    dimension: DimensionInput;
    postage: any;
}

export interface CodeDefinition {
    typeId: CodeType;
    isDynamic: boolean;
    code: string;
    numberOfCodes: number | null;
    codeLength: number | null;
    disallowedCharacters: string;
    prefix: string;
    maxCampaignRedemptions: number | null;
    maxRedemptionsPerSession: number | null;
    maxRedemptionsPerCode: number | null;
    campaignDiscountRequestStatusId: CampaignCodeRequestStatus;
}

export interface CampaignSummary {
    id: number;
    campaignType: string;
    name: string;
    status: CampaignStatus;
    brand: string;
    createdBy: string;
    validFrom: number | null;
    validTo: number | null;
    lastHourRedemptions: number;
    totalRedemptions: number;
}

export interface CampaignSummaryResult {
    campaigns: CampaignSummary[];
    pagination: PaginationInfo;
    searchInfo: SearchInfo;
    sortingInfo: SortingInfo;
}

export interface SortingInfo {
    fieldName: SortFieldName;
    direction: SortDirection;
}

export interface PaginationInfo {
    count: number;
    index: number;
    size: number;
}

export interface CampaignListingRequestInfo {
    pagination: PaginationInfo;
    sorting: SortingInfo;
}

export interface SearchInfo {
    searchTerm: string;
}

export interface SearchRequest {
    pagination: PaginationInfo;
    searchInfo: SearchInfo;
    sortingInfo: SortingInfo;
}

export interface CampaignInConflict {
    campaignId: number;
    priority: number;
    name: string;
    validFrom: number;
    validTo: number;
}

export interface CampaignPriority {
    campaignId: number;
    priority: number;
}

export interface CampaignConflictDetail {
    campaignSummary: CampaignSummary;
    products: Product[];
}

export interface EffectTypeItem {
    id: number;
    description: string;
    justWithFlashSales: boolean;
}

export interface CampaignEffectType {
    campaignTypeId: number;
    effectTypeDetails: EffectTypeItem[];
}

export interface EffectTypeMap {
    [description: string]: number;
}

export interface CampaignTypeItem {
    id: number;
    name: string;
    allowFlashSales: boolean;
}

export interface CampaignBrandItem {
    id: number;
    brandName: string;
}

export interface GenericCodeAvailability {
    isAvailable: boolean;
    campaignId: number | null;
    discountCode: string;
}

export interface DragAndDropAction {
    dragRuleSku: string;
    dragOnTopOfRuleSku: string;
}

export enum CodeType {
    generic = 1,
    unique = 2
}

export enum CampaignListTab {
    live = 0,
    pending = 1,
    draft = 2,
    scheduled = 3,
    expired = 4,
    all = 5
}

export enum CampaignStatus {
    draft = 1,
    approved = 2,
    pending = 3,
    rejected = 4
}

export enum CampaignType {
    discount = 1,
    sitewidePromotion = 2,
    urlPromotion = 3
}

export enum ErrorCode {
    GenericError = 0,
    ConcurrencyError = 0
}

export enum SortDirection {
    asc = 'asc',
    desc = 'desc',
    none = ''
}

export enum SortFieldName {
    empty = '',
    id = 'id',
    campaignType = 'campaignType',
    brand = 'brand',
    createdBy = 'createdby',
    validFrom = 'validfrom',
    validTo = 'validTo',
    lastHourRedemptions = 'lasthourredemption',
    totalRedemptions = 'totalredemptions'
}

export enum CampaignCodeRequestStatus {
    pending = 1,
    processing = 2,
    done = 3,
    failed = 4
}
