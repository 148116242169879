import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Campaign,
  CampaignSummaryResult,
  CampaignListTab,
  CampaignSummary,
  CampaignStatus,
  Ruleset,
  CodeType,
  CampaignTypeItem,
  CampaignBrandItem,
  RuleField,
  CampaignInConflict,
  CampaignPriority,
  GenericCodeAvailability,
  CampaignType,
  SortDirection,
  SortFieldName,
  CampaignCodeRequestStatus
} from './campaign';
import { catchError, tap, map } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';
import { CampaignModel, RulesetModel } from './campaign.model';
import { Store, select } from '@ngrx/store';
import * as fromCampaign from '../../app/campaigns/state';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private _campaignsUrl = `${environment.api.rootUrl}campaigns`;
  private _campaignStatusesUrl = `${environment.api.rootUrl}statuses`;
  private _campaignBrandsUrl = `${environment.api.rootUrl}brands`;
  private _campaignTypesUrl = `${environment.api.rootUrl}campaigntypes`;
  private _campaignGenericCodeAvailability = `${environment.api.rootUrl}code`;
  private _ruleFields: RuleField[];

  constructor(private _http: HttpClient,
    private _campaignStore: Store<fromCampaign.State>,
    private _helperService: HelperService) {
    this._campaignStore.pipe(select(fromCampaign.getRuleBuilderFields)).subscribe(ruleFields => {
      this._ruleFields = ruleFields;
    });
  }

  getPendingCampaigns(pageIndex: number = 1, pageSize: number = 3,
    sortFieldName: SortFieldName = SortFieldName.empty,
    sortDirection: SortDirection = SortDirection.none): Observable<CampaignSummaryResult> {
    const url = sortDirection
      ? `${this._campaignsUrl}/pending?page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
      : `${this._campaignsUrl}/pending?page=${pageIndex}&limit=${pageSize}`;
    return this._http.get<CampaignSummary[]>(url, { observe: 'response' })
      .pipe(
        map(data => {
          return {
            campaigns: <CampaignSummary[]>data.body,
            pagination: {
              count: data.headers.get('Pagination-Count')
                ? Number(data.headers.get('Pagination-Count'))
                : null,
              index: data.headers.get('Pagination-Index')
                ? Number(data.headers.get('Pagination-Index'))
                : null,
              size: data.headers.get('Pagination-Size')
                ? Number(data.headers.get('Pagination-Size'))
                : null
            },
            searchInfo: {
              searchTerm: ''
            },
            sortingInfo: {
              fieldName: data.headers.get('Sort-Field')
                ? <SortFieldName>data.headers.get('Sort-Field')
                : SortFieldName.empty,
              direction: data.headers.get('Sort-Order')
              ? <SortDirection>data.headers.get('Sort-Order')
              : SortDirection.none,
            }
          };
        }),
        catchError(this._helperService.handleError)
      );
  }

  getMyDraftCampaigns(pageIndex: number = 1, pageSize: number = 3,
    sortFieldName: SortFieldName = SortFieldName.empty,
    sortDirection: SortDirection = SortDirection.none): Observable<CampaignSummaryResult> {
    const url = sortDirection
      ? `${this._campaignsUrl}/mydrafts?page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
      : `${this._campaignsUrl}/mydrafts?page=${pageIndex}&limit=${pageSize}`;
    return this._http.get<CampaignSummary[]>(url, { observe: 'response' })
      .pipe(
        map(data => {
          return {
            campaigns: <CampaignSummary[]>data.body,
            pagination: {
              count: data.headers.get('Pagination-Count')
                ? Number(data.headers.get('Pagination-Count'))
                : null,
              index: data.headers.get('Pagination-Index')
                ? Number(data.headers.get('Pagination-Index'))
                : null,
              size: data.headers.get('Pagination-Size')
                ? Number(data.headers.get('Pagination-Size'))
                : null
            },
            searchInfo: {
              searchTerm: ''
            },
            sortingInfo: {
              fieldName: data.headers.get('Sort-Field')
                ? <SortFieldName>data.headers.get('Sort-Field')
                : SortFieldName.empty,
              direction: data.headers.get('Sort-Order')
              ? <SortDirection>data.headers.get('Sort-Order')
              : SortDirection.none,
            }
          };
        }),
        catchError(this._helperService.handleError)
      );
  }

  getRecentlyViewed(pageIndex: number = 1, pageSize: number = 3,
    sortFieldName: SortFieldName = SortFieldName.empty,
    sortDirection: SortDirection = SortDirection.none): Observable<CampaignSummaryResult> {
    const url = sortDirection
      ? `${this._campaignsUrl}/recentlyviewed?page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
      : `${this._campaignsUrl}/recentlyviewed?page=${pageIndex}&limit=${pageSize}`;
    return this._http.get<CampaignSummary[]>(url, { observe: 'response' })
      .pipe(
        map(data => {
          return {
            campaigns: <CampaignSummary[]>data.body,
            pagination: {
              count: data.headers.get('Pagination-Count')
                ? Number(data.headers.get('Pagination-Count'))
                : null,
              index: data.headers.get('Pagination-Index')
                ? Number(data.headers.get('Pagination-Index'))
                : null,
              size: data.headers.get('Pagination-Size')
                ? Number(data.headers.get('Pagination-Size'))
                : null
            },
            searchInfo: {
              searchTerm: ''
            },
            sortingInfo: {
              fieldName: data.headers.get('Sort-Field')
                ? <SortFieldName>data.headers.get('Sort-Field')
                : SortFieldName.empty,
              direction: data.headers.get('Sort-Order')
              ? <SortDirection>data.headers.get('Sort-Order')
              : SortDirection.none,
            }
          };
        }),
        catchError(this._helperService.handleError)
      );
  }

  getCampaigns(campaignListTab: CampaignListTab, pageIndex: number = 0, pageSize: number = 10,
    sortFieldName: SortFieldName = SortFieldName.empty,
    sortDirection: SortDirection = SortDirection.none): Observable<CampaignSummaryResult> {
    const url = campaignListTab === CampaignListTab.all
      ? sortDirection
        ? `${this._campaignsUrl}?page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
        : `${this._campaignsUrl}?page=${pageIndex}&limit=${pageSize}`
      : sortDirection
        // tslint:disable-next-line: max-line-length
        ? `${this._campaignsUrl}/${CampaignListTab[campaignListTab]}?page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
        : `${this._campaignsUrl}/${CampaignListTab[campaignListTab]}?page=${pageIndex}&limit=${pageSize}`;
    return this._http.get<CampaignSummary[]>(url, {
        observe: 'response'
      })
      .pipe(
        map(data => {
          return {
            campaigns: <CampaignSummary[]>data.body,
            pagination: {
              count: data.headers.get('Pagination-Count')
                ? Number(data.headers.get('Pagination-Count'))
                : null,
              index: data.headers.get('Pagination-Index')
                ? Number(data.headers.get('Pagination-Index'))
                : null,
              size: data.headers.get('Pagination-Size')
                ? Number(data.headers.get('Pagination-Size'))
                : null
            },
            searchInfo: {
              searchTerm: ''
            },
            sortingInfo: {
              fieldName: data.headers.get('Sort-Field')
                ? <SortFieldName>data.headers.get('Sort-Field')
                : SortFieldName.empty,
              direction: data.headers.get('Sort-Order')
              ? <SortDirection>data.headers.get('Sort-Order')
              : SortDirection.none,
            }
          };
        }),
        catchError(this._helperService.handleError)
      );
  }

  searchCampaigns(searchTerm: string, pageIndex: number = 0, pageSize: number = 10,
    sortFieldName: SortFieldName = SortFieldName.empty,
    sortDirection: SortDirection = SortDirection.none): Observable<CampaignSummaryResult> {
    const url = sortDirection
      ? `${this._campaignsUrl}/search?term=${searchTerm}&page=${pageIndex}&limit=${pageSize}&sort=${sortFieldName}:${sortDirection}`
      : `${this._campaignsUrl}/search?term=${searchTerm}&page=${pageIndex}&limit=${pageSize}`;
    return this._http.get<CampaignSummary[]>(url, {
      observe: 'response'
    })
    .pipe(
      map(data => {
        return {
          campaigns: <CampaignSummary[]>data.body,
            pagination: {
              count: data.headers.get('Pagination-Count')
                ? Number(data.headers.get('Pagination-Count'))
                : null,
              index: data.headers.get('Pagination-Index')
                ? Number(data.headers.get('Pagination-Index'))
                : null,
              size: data.headers.get('Pagination-Size')
                ? Number(data.headers.get('Pagination-Size'))
                : null
            },
            searchInfo: {
              searchTerm: searchTerm
            },
            sortingInfo: {
              fieldName: data.headers.get('Sort-Field')
                ? <SortFieldName>data.headers.get('Sort-Field')
                : SortFieldName.empty,
              direction: data.headers.get('Sort-Order')
              ? <SortDirection>data.headers.get('Sort-Order')
              : SortDirection.none,
            }
        };
      })
    );
  }

  getCampaign(id: string): Observable<Campaign> {
    if (id === 'new') {
      return of(this.initializeCampaign());
    } else if (id === 'clone') {
      return of(this.cloneCurrentCampaign());
    }

    const url = `${this._campaignsUrl}/${id}`;
    return this._http.get<CampaignModel>(url)
      .pipe(
        tap(data => console.log('getCampaign: ' + JSON.stringify(data))),
        map(campaignModel => this.mapCampaignFromModel(campaignModel)),
        catchError(this._helperService.handleError)
      );
  }

  saveCampaign(campaign: CampaignModel): Observable<CampaignModel> {
    console.log('Saving campaign');
    console.log(campaign);
    if (campaign.id === 0) {
      return this.createCampaign(campaign);
    } else {
      return this.updateCampaign(campaign);
    }
  }

  getCampaignConflicts(campaignId: number): Observable<CampaignInConflict[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get<CampaignInConflict[]>(`${this._campaignsUrl}/${campaignId}/conflicts`, { headers: headers })
      .pipe(
        tap(data => console.log('getCampaignClonflicts: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  updatePriorities(priorities: CampaignPriority[]): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._campaignsUrl}/priorities`;
    return this._http.post<CampaignPriority[]>(url, priorities, { headers: headers })
      .pipe(
        tap(data => console.log('updatePriorities: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  getCampaignStatuses(): Observable<CampaignStatus[]> {
    return this._http.get<CampaignStatus[]>(this._campaignStatusesUrl)
      .pipe(
        tap(data => console.log('getStatuses: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  getCampaignTypes(): Observable<CampaignTypeItem[]> {
    return this._http.get<CampaignTypeItem[]>(this._campaignTypesUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  getCampaignBrands(): Observable<CampaignBrandItem[]> {
    return this._http.get<CampaignBrandItem[]>(this._campaignBrandsUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  updateCampaign(campaign: CampaignModel): Observable<CampaignModel> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._campaignsUrl}/${campaign.id}`;
    return this._http.post<CampaignModel>(url, campaign, { headers: headers })
      .pipe(
        tap(() => console.log('updateCampaign: ' + campaign.id)),
        map(() => campaign),
        catchError(this._helperService.handleError)
      );
  }

  deleteCampaign(id: number): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._campaignsUrl}/${id}`;
    return this._http.delete<boolean>(url, { headers: headers })
      .pipe(
        tap(_ => console.log('deleteCampaign: ' + id)),
        catchError(this._helperService.handleError)
      );
  }

  approveCampaign(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._campaignsUrl}/${id}/approve`;
    return this._http.post<Campaign>(url, { headers: headers })
      .pipe(
        tap(_ => console.log('approveCampaign: ' + id)),
        catchError(this._helperService.handleError)
      );
  }

  rejectCampaign(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._campaignsUrl}/${id}/reject`;
    return this._http.post<Campaign>(url, { headers: headers })
      .pipe(
        tap(_ => console.log('rejectCampaign: ' + id)),
        catchError(this._helperService.handleError)
      );
  }

  isGenericCodeAvailable(code: string): Observable<GenericCodeAvailability> {
    return this._http.get<GenericCodeAvailability>(`${this._campaignGenericCodeAvailability}/${code}/isavailable`)
      .pipe(
        tap(data => console.log('isCodeAvailable: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  downloadCampaignCodes(campaignId: number): Observable<Blob> {
    const url = `${this._campaignsUrl}/${campaignId}/codes`;
    return this._http.get<Blob>(url, {responseType: 'blob' as 'json'})
      .pipe(
        catchError(this._helperService.handleError)
      );
  }

  mapCampaignToModel(campaign: Campaign): CampaignModel {
    return {
      ...campaign,
      codeDefinition: campaign.type === CampaignType.discount
        ? campaign.codeDefinition
        : null,
      rules: campaign.rules.map(ruleset => this.mapRulesetToModel(ruleset))
    };
  }

  private createCampaign(campaign: CampaignModel): Observable<CampaignModel> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post<CampaignModel>(this._campaignsUrl, campaign, { headers: headers })
      .pipe(
        tap(data => console.log('createCampaign: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  private mapCampaignFromModel(campaign: CampaignModel): Campaign {
    return {
      ...campaign,
      rules: campaign.rules.map(ruleset => this.mapRulesetFromModel(ruleset, true))
    };
  }

  private mapRulesetToModel(ruleset: Ruleset): RulesetModel {
    const ruleField = this._ruleFields.find(field => field.name === ruleset.field);
    const ruleFieldId = ruleField ? ruleField.id : undefined;
    const r = {
      id: ruleset.id,
      sku: ruleset.sku,
      name: ruleset.name,
      priority: ruleset.priority,
      conditionId: ruleset.condition === 'AND'
        ? 1
        : ruleset.condition === 'OR' ? 2 : null,
      fieldId: ruleFieldId,
      operatorId: ruleField
        ? ruleField.operators.find(operator => operator.value === ruleset.operator).id
        : undefined,
      effect: ruleset.effect
        ? {
          typeId: ruleset.effect.typeId,
          isPercent: ruleset.effect.isPercent,
          value: ruleset.effect.typeId === 4
            ? null
            : ruleset.effect.value,
          product: ruleset.effect.product,
          dimension: ruleset.effect.dimension,
          deliveryMethod: ruleset.effect.postage
        }
        : null,
      rules: ruleset.rules
        ? ruleset.rules.map(rules => this.mapRulesetToModel(rules))
        : undefined,
      ...this.mapRuleValueToModel(ruleFieldId, ruleset.value)
    };
    return r;
  }

  private mapRuleValueToModel(fieldId: number, value: any): any {
    switch (fieldId) {
      case 1:
        return {
          basketValue: value
        };
      case 2:
        return {
          dimensionValueId: value.dimensionValueId
        };
      case 3:
        return {
          productId: value.id
        };
      case 4:
        return {
          deliveryMethodId: value
        };
    }
  }

  private mapRulesetFromModel(ruleset: RulesetModel, root: boolean): Ruleset {
    const ruleField = this._ruleFields.find(field => field.id === ruleset.fieldId);
    const ruleFieldId = ruleField ? ruleField.id : undefined;
    const r = {
      id: ruleset.id,
      sku: this.newGuid(),
      priority: ruleset.priority,
      name: ruleset.name,
      condition: ruleset.conditionId === 1
        ? 'AND'
        : ruleset.conditionId === 2 ? 'OR' : null,
      field: ruleField ? ruleField.name : undefined,
      operator: ruleField ? ruleField.operators.find(operator => operator.id === ruleset.operatorId).value : undefined,
      effect: root ? {
        ...ruleset.effect,
        typeId: ruleset.effect
          ? ruleset.effect.typeId
          : null,
        valueType: ruleset.effect
          ? ruleset.effect.isPercent
            ? 'percentOff'
            : 'poundOff'
          : null,
        postage: ruleset.effect
          ? ruleset.effect.deliveryMethod
          : null
      } : null,
      rules: ruleset.rules
        ? ruleset.rules.map(rules => this.mapRulesetFromModel(rules, false))
        : undefined,
      ...this.mapRuleValueFromModel(ruleFieldId, ruleset)
    };
    return r;
  }

  private mapRuleValueFromModel(fieldId: number, ruleset: RulesetModel): any {
    switch (fieldId) {
      case 1:
        return {
          value: ruleset.basketValue
        };
      case 2:
        return {
          value: {
            dimensionNameId: ruleset.dimensionNameId,
            dimensionName: ruleset.dimensionName,
            dimensionValueId: ruleset.dimensionValueId,
            dimensionValue: ruleset.dimensionValue
          },
        };
      case 3:
        return {
          value: {
            id: ruleset.productId,
            code: ruleset.productCode,
            name: ruleset.productName
          }
        };
      case 4:
        return {
          value: ruleset.deliveryMethodId
        };
    }
  }

  private cloneCurrentCampaign(): Campaign {
    let campaignToClone: Campaign;
    this._campaignStore.take(1).pipe(select(fromCampaign.getCurrentCampaign)).subscribe(campaign => {
      campaignToClone = campaign;
    });

    return {
      ...campaignToClone,
      id: 0,
      status: CampaignStatus.draft,
      name: `${campaignToClone.name} (clone)`,
      concurrencyKey: '',
      codeDefinition: {
        ...campaignToClone.codeDefinition,
        campaignDiscountRequestStatusId: CampaignCodeRequestStatus.pending
      }
    };
  }

  private initializeCampaign(): Campaign {
    return {
      id: 0,
      campaignGuid: this.newGuid(),
      concurrencyKey: '',
      type: null,
      brand: null,
      name: '',
      description: '',
      validFrom: null,
      validTo: null,
      longMarketingMessage: null,
      shortMarketingMessage: null,
      isFlashSale: false,
      bulletPoints: null,
      status: 1,
      createdBy: '',
      createdOn: null,
      updatedBy: '',
      updatedOn: null,
      approvedBy: '',
      approvedOn: null,
      nominatedManager: '',
      codeDefinition: {
        code: '',
        codeLength: null,
        disallowedCharacters: '',
        isDynamic: false,
        maxCampaignRedemptions: null,
        maxRedemptionsPerCode: null,
        maxRedemptionsPerSession: null,
        numberOfCodes: null,
        prefix: '',
        typeId: CodeType.generic,
        campaignDiscountRequestStatusId: CampaignCodeRequestStatus.pending
      },
      priority: 0,
      rules: []
    };
  }

  newGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
}
