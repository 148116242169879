import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum ExpiredCampaignActionTypes {
    loadCampaigns = '[Expired Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Expired Campaigns] Load campaigns success',
    loadCampaignsFail = '[Expired Campaigns] Load campaigns fail',
    setPaginationInfo = '[Expired Campaigns] Set pagination info',
    setSortingInfo = '[Expired Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Expired Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = ExpiredCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = ExpiredCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = ExpiredCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = ExpiredCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = ExpiredCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = ExpiredCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type ExpiredCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
