import { CampaignListTab, Campaign, CampaignInConflict, } from '../campaign';
import { CampaignActions, CampaignActionTypes } from './campaign.actions';
import { CampaignModel } from '../campaign.model';

export interface CampaignState {
    currentCampaign: Campaign;
    currentCampaignLoaded: boolean;
    readyToSaveCampaign: CampaignModel;
    isSaving: boolean;
    isDeleting: boolean;
    isUpdatingPendingStatus: boolean;
    isUpdatingPriorities: boolean;
    conflicts: CampaignInConflict[];
    currentCampaignListTab: CampaignListTab;
    isLoadingConflicts: boolean;
    error: string;
}

const initialState: CampaignState = {
    currentCampaign: null,
    currentCampaignLoaded: false,
    readyToSaveCampaign: null,
    isSaving: false,
    isDeleting: false,
    isUpdatingPendingStatus: false,
    isUpdatingPriorities: false,
    conflicts: [],
    currentCampaignListTab: CampaignListTab.live,
    isLoadingConflicts: false,
    error: ''
};

export function reducer(state = initialState, action: CampaignActions): CampaignState {
    switch (action.type) {
        case CampaignActionTypes.setCurrentCampaignListTab:
            return {
                ...state,
                currentCampaignListTab: action.payload
            };
        case CampaignActionTypes.clearCurrentCampaign:
            return {
                ...state,
                currentCampaign: null,
                currentCampaignLoaded: false
            };
        case CampaignActionTypes.getCampaignSuccess:
            return {
                ...state,
                currentCampaign: action.payload,
                currentCampaignLoaded: true
            };
        case CampaignActionTypes.getCampaignFail:
            return {
                ...state,
                currentCampaign: null,
                currentCampaignLoaded: false,
            };
        case CampaignActionTypes.getCampaignConflictsSuccess:
            return {
                ...state,
                isLoadingConflicts: false,
                conflicts: action.payload
            };
        case CampaignActionTypes.getCampaignConflictsFail:
            return {
                ...state,
                isLoadingConflicts: false,
                error: action.payload
            };
        case CampaignActionTypes.setIsLoadingCampaignConflicts:
            return {
                ...state,
                isLoadingConflicts: true
            };
        case CampaignActionTypes.saveCampaignSuccess:
            return {
                ...state,
                readyToSaveCampaign: action.payload,
                isSaving: false
            };
        case CampaignActionTypes.saveCampaignFail:
            return {
                ...state,
                isSaving: false,
                error: action.payload
            };
        case CampaignActionTypes.saveCampaignOutOfDateError:
            return {
                ...state,
                isSaving: false
            };
        case CampaignActionTypes.setIsSavingCampaign:
            return {
                ...state,
                isSaving: true
            };
        case CampaignActionTypes.setIsDeletingCampaign:
            return {
                ...state,
                isDeleting: true
            };
        case CampaignActionTypes.deleteCampaignSuccess:
            return {
                ...state,
                isDeleting: false
            };
        case CampaignActionTypes.deleteCampaignFail:
            return {
                ...state,
                isDeleting: false,
                error: action.payload
            };
        case CampaignActionTypes.setIsUpdatingPendingStatusOfCampaign:
            return {
                ...state,
                isUpdatingPendingStatus: true
            };
        case CampaignActionTypes.approveCampaignSuccess:
            return {
                ...state,
                isUpdatingPendingStatus: false
            };
        case CampaignActionTypes.approveCampaignFail:
            return {
                ...state,
                isUpdatingPendingStatus: false,
                error: action.payload
            };
        case CampaignActionTypes.rejectCampaignSuccess:
            return {
                ...state,
                isUpdatingPendingStatus: false
            };
        case CampaignActionTypes.rejectCampaignFail:
            return {
                ...state,
                isUpdatingPendingStatus: false,
                error: action.payload
            };
        case CampaignActionTypes.updatePrioritiesSuccess:
            return {
                ...state,
                isUpdatingPriorities: false
            };
        case CampaignActionTypes.updatePrioritiesFail:
            return {
                ...state,
                isUpdatingPriorities: false,
                error: action.payload
            };
        case CampaignActionTypes.setIsUpdatingPriorities:
            return {
                ...state,
                isUpdatingPriorities: true
            };
        default:
            return state;
    }
}
