import { Component, OnInit, OnDestroy } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromNotification from './notifications/state/notifications.reducer';
import * as notificationActions from './notifications/state/notifications.actions';
import { UserState } from './user/state/user.reducer';
import * as fromUser from './user/state/user.reducer';
import * as userActions from './user/state/user.actions';
import * as fromApp from './state/app.reducer';
import * as appActions from './state/app.actions';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { SmartUser } from './user/smart-user';
import * as ruleBuilderActions from '../app/campaigns/state/rule-builder.actions';
import * as fromCampaign from '../app/campaigns/state';
import { AppInsights } from 'applicationinsights-js';
import { environment } from 'src/environments/environment';
import { RoutingStateService } from './routing-state.service';
import { Hotkeys } from './shared/hotkeys.service';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _snackbarReference: MatSnackBarRef<SimpleSnackBar>;
  private _subscriptions: Subscription[] = [];

  isLoggedIn: boolean;
  currentUser: SmartUser;
  unreadNotifications$: Observable<number>;

  constructor(
    private _broadcastService: BroadcastService,
    private _notificationsService: NotificationsService,
    private _msalService: MsalService,
    private _store: Store<UserState>,
    private _campaignStore: Store<fromCampaign.State>,
    private _appStore: Store<fromApp.AppState>,
    private _notificationStore: Store<fromNotification.NotificationState>,
    private _snackBar: MatSnackBar,
    private _hotkeysService: Hotkeys,
    private _router: Router,
    private _routingStateService: RoutingStateService) {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup({
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableCorsCorrelation: true
      });
    }
    _routingStateService.loadRouting();
  }

  ngOnInit() {
    this.unreadNotifications$ = this._notificationStore.pipe(select(fromNotification.getUnreadNotificationsCount));
    this._subscriptions.push(this._store.pipe(select(fromUser.getCurrentUser)).subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = currentUser;
        AppInsights.setAuthenticatedUserContext(this.currentUser.email);
      }
    }));

    this._subscriptions.push(this._store.pipe(select(fromUser.getUserIsLoggedIn)).subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    }));

    // this._subscriptions.push(this._broadcastService.subscribe('msal:loginFailure', (payload) => {
    //   console.warn('login failure ' + JSON.stringify(payload));
    // }));

    // this._subscriptions.push(this._broadcastService.subscribe('msal:loginSuccess', (payload) => {
    //   console.log('login success');
    // }));

    this._subscriptions.push(this._broadcastService.subscribe('msal:acquireTokenFailure', () => {
      console.log('acquire token failure');
      this._msalService.loginRedirect();
      // if (this.isLoggedIn) {
      //   this._msalService.loginRedirect();
      // } else {
      //   this._router.navigateByUrl('/bye');
      // }
    }));

    // this._subscriptions.push(this._broadcastService.subscribe('msal:acquireTokenSuccess', () => {
    //   console.log('acquire token success');
    // }));

    this._subscriptions.push(this._store.pipe(select(fromApp.getMessage))
      .subscribe(message => {
        if (message) {
          this._snackbarReference = this._snackBar.open(message, null, {
            duration: 5000
          });
          this._subscriptions.push(this._snackbarReference.afterDismissed().subscribe(() => {
            this._appStore.dispatch(new appActions.SetMessage(''));
          }));
        }
      }));

    if (!this.currentUser) {
      this._store.dispatch(new userActions.LoadUserProfile());
    }
    this._campaignStore.dispatch(new ruleBuilderActions.LoadFields());
    this._notificationStore.dispatch(new notificationActions.LoadNotifications());
    this._notificationsService.initHub();
    this.registerHotkeys();
  }

  ngOnDestroy(): void {
    this._broadcastService.getMSALSubject().next(1);
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  logout(): void {
    AppInsights.clearAuthenticatedUserContext();
    this._store.dispatch(new userActions.SignOut());
  }

  goBack(): void {
    const previousRoute = this._routingStateService.getPreviousUrl();
    this._router.navigateByUrl(previousRoute);
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.n' })
      .subscribe(() => {
        this._router.navigateByUrl(`/campaigns/new/edit`);
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.h' })
      .subscribe(() => {
        this._router.navigateByUrl(`/dashboard`);
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.s' })
      .subscribe(() => {
        this._router.navigateByUrl(`/campaigns`);
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.m' })
      .subscribe(() => {
        this._router.navigateByUrl(`/notifications`);
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.q' })
      .subscribe(() => {
        this.logout();
      }));
  }
}
