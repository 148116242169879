import { Action } from '@ngrx/store';
import { Campaign, CampaignListTab, CampaignInConflict, CampaignPriority } from '../campaign';
import { CampaignModel } from '../campaign.model';

export enum CampaignActionTypes {
    saveCampaign = '[Campaign] Save campaign',
    saveCampaignSuccess = '[Campaign] Save campaign success',
    saveCampaignFail = '[Campaign] Save campaign fail',
    saveCampaignOutOfDateError = '[Campaign] Save campaign fail with out of date error',
    setIsSavingCampaign = '[Campaign] Set is saving campaign',
    getCampaign = '[Campaign] Get campaign',
    getCampaignSuccess = '[Campaign] Get campaign success',
    getCampaignFail = '[Campaign] Get campaign fail',
    deleteCampaign = '[Campaign] Delete campaign',
    deleteCampaignSuccess = '[Campaign] Delete campaign success',
    deleteCampaignFail = '[Campaign] Delete campaign fail',
    setIsDeletingCampaign = '[Campaign] Set is deleting campaign',
    approveCampaign = '[Campaign] Approve campaign',
    approveCampaignSuccess = '[Campaign] Approve campaign success',
    approveCampaignFail = '[Campaign] Approve campaign fail',
    rejectCampaign = '[Campaign] Reject campaign',
    rejectCampaignSuccess = '[Campaign] Reject campaign success',
    rejectCampaignFail = '[Campaign] Reject campaign fail',
    setIsUpdatingPendingStatusOfCampaign = '[Campaign] Set is changing pending status of campaign',
    getCampaignConflicts = '[Campaign] Get campaign conflicts',
    getCampaignConflictsSuccess = '[Campaign] Get campaign conflicts success',
    getCampaignConflictsFail = '[Campaign] Get campaign conflicts fail',
    setIsLoadingCampaignConflicts = '[Campaign] Set is loading campaign conflicts',
    setCurrentCampaignListTab = '[Campaign] Set current campaign list tab',
    updatePriorities = '[Campaign] Update priorities',
    updatePrioritiesSuccess = '[Campaign] Update priorities success',
    updatePrioritiesFail = '[Campaign] Update priorities fail',
    setIsUpdatingPriorities = '[Campaign] Set is updating priorities',
    clearCurrentCampaign = '[Campaign] Clear current campaign',
}

export class SaveCampaign implements Action {
    readonly type = CampaignActionTypes.saveCampaign;
    constructor(public payload: CampaignModel) { }
}

export class SaveCampaignSuccess implements Action {
    readonly type = CampaignActionTypes.saveCampaignSuccess;
    constructor(public payload: CampaignModel) { }
}

export class SaveCampaignFail implements Action {
    readonly type = CampaignActionTypes.saveCampaignFail;
    constructor(public payload: string) { }
}

export class SaveCampaignOutOfDateError implements Action {
    readonly type = CampaignActionTypes.saveCampaignOutOfDateError;
    constructor() { }
}

export class SetIsSavingCampaign implements Action {
    readonly type = CampaignActionTypes.setIsSavingCampaign;
}

export class GetCampaign implements Action {
    readonly type = CampaignActionTypes.getCampaign;
    constructor(public payload: string) { }
}

export class GetCampaignSuccess implements Action {
    readonly type = CampaignActionTypes.getCampaignSuccess;
    constructor(public payload: Campaign) { }
}

export class GetCampaignFail implements Action {
    readonly type = CampaignActionTypes.getCampaignFail;
    constructor(public payload: string) { }
}

export class GetCampaignConflicts implements Action {
    readonly type = CampaignActionTypes.getCampaignConflicts;
    constructor(public payload: number) { }
}

export class GetCampaignConflictsSuccess implements Action {
    readonly type = CampaignActionTypes.getCampaignConflictsSuccess;
    constructor(public payload: CampaignInConflict[]) { }
}

export class GetCampaignConflictsFail implements Action {
    readonly type = CampaignActionTypes.getCampaignConflictsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaignConflicts implements Action {
    readonly type = CampaignActionTypes.setIsLoadingCampaignConflicts;
}

export class UpdatePriorities implements Action {
    readonly type = CampaignActionTypes.updatePriorities;
    constructor(public payload: CampaignPriority[]) { }
}

export class UpdatePrioritiesSuccess implements Action {
    readonly type = CampaignActionTypes.updatePrioritiesSuccess;
}

export class UpdatePrioritiesFail implements Action {
    readonly type = CampaignActionTypes.updatePrioritiesFail;
    constructor(public payload: string) { }
}

export class SetIsUpdatingPriorities implements Action {
    readonly type = CampaignActionTypes.setIsUpdatingPriorities;
}

export class SetCurrentCampaignListTab implements Action {
    readonly type = CampaignActionTypes.setCurrentCampaignListTab;
    constructor(public payload: CampaignListTab) { }
}

export class ClearCurrentCampaign implements Action {
    readonly type = CampaignActionTypes.clearCurrentCampaign;
}

export class DeleteCampaign implements Action {
    readonly type = CampaignActionTypes.deleteCampaign;
    constructor(public payload: number) { }
}

export class DeleteCampaignSuccess implements Action {
    readonly type = CampaignActionTypes.deleteCampaignSuccess;
    constructor(public payload: string) { }
}

export class DeleteCampaignFail implements Action {
    readonly type = CampaignActionTypes.deleteCampaignFail;
    constructor(public payload: string) { }
}

export class SetIsDeletingCampaign implements Action {
    readonly type = CampaignActionTypes.setIsDeletingCampaign;
}

export class ApproveCampaign implements Action {
    readonly type = CampaignActionTypes.approveCampaign;
    constructor(public payload: number) { }
}

export class ApproveCampaignSuccess implements Action {
    readonly type = CampaignActionTypes.approveCampaignSuccess;
    constructor(public payload: string) { }
}

export class ApproveCampaignFail implements Action {
    readonly type = CampaignActionTypes.approveCampaignFail;
    constructor(public payload: string) { }
}

export class RejectCampaign implements Action {
    readonly type = CampaignActionTypes.rejectCampaign;
    constructor(public payload: number) { }
}

export class RejectCampaignSuccess implements Action {
    readonly type = CampaignActionTypes.rejectCampaignSuccess;
    constructor(public payload: string) { }
}

export class RejectCampaignFail implements Action {
    readonly type = CampaignActionTypes.rejectCampaignFail;
    constructor(public payload: string) { }
}

export class SetIsChangingPendingStatusOfCampaign implements Action {
    readonly type = CampaignActionTypes.setIsUpdatingPendingStatusOfCampaign;
}

export type CampaignActions = SetCurrentCampaignListTab
    | ClearCurrentCampaign
    | SaveCampaign
    | SaveCampaignSuccess
    | SaveCampaignFail
    | SaveCampaignOutOfDateError
    | SetIsSavingCampaign
    | GetCampaignConflicts
    | GetCampaignConflictsSuccess
    | GetCampaignConflictsFail
    | SetIsLoadingCampaignConflicts
    | GetCampaign
    | GetCampaignSuccess
    | GetCampaignFail
    | DeleteCampaign
    | DeleteCampaignSuccess
    | DeleteCampaignFail
    | SetIsDeletingCampaign
    | ApproveCampaign
    | ApproveCampaignSuccess
    | ApproveCampaignFail
    | RejectCampaign
    | RejectCampaignSuccess
    | RejectCampaignFail
    | UpdatePriorities
    | UpdatePrioritiesSuccess
    | UpdatePrioritiesFail
    | SetIsUpdatingPriorities
    | SetIsChangingPendingStatusOfCampaign;
