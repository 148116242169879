import { DeliveryMethod } from '../postage';
import { PostageActions, PostageActionTypes } from './postage.actions';

export interface PostageStage {
    deliveryMethods: DeliveryMethod[];
    deliveryMethodsLoaded: boolean;
    isLoading: boolean;
    error: string;
}

const initialState: PostageStage = {
    deliveryMethods: [],
    deliveryMethodsLoaded: false,
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: PostageActions) {
    switch (action.type) {
        case PostageActionTypes.loadDeliveryMethodsSuccess:
            return {
                ...state,
                deliveryMethods: action.payload,
                deliveryMethodsLoaded: true,
                isLoading: false
            };
        case PostageActionTypes.loadDeliveryMethodsFail:
            return {
                ...state,
                deliveryMethods: [],
                deliveryMethodsLoaded: false,
                isLoading: false,
                error: action.payload
            };
        case PostageActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}
