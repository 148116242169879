import { Product } from '../product';
import { ProductActions, ProductActionTypes } from './product.actions';

export interface ProductState {
    filteredProducts: Product[];
    isLoading: boolean;
    error: string;
}

const initialState: ProductState = {
    filteredProducts: [],
    isLoading: false,
    error: ''
};

export const getFilteredProducts = (state: ProductState) => state.filteredProducts;
export const getError = (state: ProductState) => state.error;


export function reducer(state = initialState, action: ProductActions) {
    switch (action.type) {
        case ProductActionTypes.searchProductsSuccess:
            return {
                ...state,
                filteredProducts: action.payload,
                isLoading: false
            };
        case ProductActionTypes.searchProductsFail:
            return {
                ...state,
                filteredProducts: [],
                error: action.payload,
                isLoading: false
            };
        case ProductActionTypes.setIsLoadingProducts:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
}
