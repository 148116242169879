import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CampaignEditPageComponent } from './containers/campaign-edit-page/campaign-edit-page.componet';

@Injectable({
  providedIn: 'root'
})
export class CampaignEditPageGuard implements CanDeactivate<CampaignEditPageComponent> {
  canDeactivate(component: CampaignEditPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (component.isDirty) {
      return confirm(`Navigate away and lose all changes on campaign?`);
    }
    return true;
  }

}
