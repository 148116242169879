import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Campaign, CampaignInConflict } from '../../campaign';
import { Observable, Subscription } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import * as fromCampaign from '../../state';
import * as campaignActions from '../../state/campaign.actions';

@Component({
  selector: 'app-confirm-approve-dialog',
  templateUrl: './confirm-approve-dialog.component.html',
  styleUrls: ['./confirm-approve-dialog.component.scss']
})
export class ConfirmApproveDialogComponent implements OnInit, OnDestroy {
  isloadingConflicts$: Observable<boolean>;
  campaignConflictsChecked = false;
  errorCheckingConflicts = false;
  conflictsFixed = false;
  campaignConflicts: CampaignInConflict[] = [];

  private _subscriptions: Subscription[] = [];

  get hasConflicts(): boolean {
    return this.campaignConflicts.length > 0;
  }

  get isReadyToApprove(): boolean {
    return this.campaignConflictsChecked && (!this.hasConflicts || (this.hasConflicts && this.conflictsFixed));
  }

  constructor(
    private _store: Store<fromCampaign.State>,
    private _actionSubject: ActionsSubject,
    public dialogRef: MatDialogRef<ConfirmApproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Campaign) { }

  ngOnInit(): void {
    this._store.dispatch(new campaignActions.SetIsLoadingCampaignConflicts);
    this._store.dispatch(new campaignActions.GetCampaignConflicts(this.data.id));
    this.isloadingConflicts$ = this._store.pipe(select(fromCampaign.getIsLoadingConflicts));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getConflicts))
      .subscribe(conflicts => {
        if (conflicts) {
          this.campaignConflicts = conflicts;
        }
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.getCampaignConflictsFail)
      .subscribe(() => {
        this.errorCheckingConflicts = true;
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.getCampaignConflictsSuccess)
      .subscribe(() => {
        this.errorCheckingConflicts = false;
        this.campaignConflictsChecked = true;
      }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancelConflictResolution(): void {
    this.dialogRef.close(false);
  }

  setConflictsFixed(): void {
    this.conflictsFixed = true;
  }

  loadConflicts(): void {
    this._store.dispatch(new campaignActions.SetIsLoadingCampaignConflicts);
    this._store.dispatch(new campaignActions.GetCampaignConflicts(this.data.id));
  }
}
