import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Campaign, CampaignTypeItem, CampaignBrandItem, CampaignStatus, CampaignType, CampaignCodeRequestStatus } from '../../campaign';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as campaignPropertyActions from '../../state/campaign-property.actions';
import * as postageActions from '../../state/postage.actions';

import * as fromCampaign from '../../state';
import * as campaignActions from '../../state/campaign.actions';
import * as fromNotification from '../../../notifications/state/notifications.reducer';
import * as appActions from '../../../state/app.actions';
import * as notificationActions from '../../../notifications/state/notifications.actions';
import * as fromUser from '../../../user/state/user.reducer';
import * as fromApp from '../../../state/app.reducer';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { CampaignViewComponent } from '../../components/campaign-view/campaign-view.component';
import { SmartUser } from 'src/app/user/smart-user';
import { Constants } from 'src/app/constants';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteDialogComponent } from '../../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmRejectDialogComponent } from '../../components/confirm-reject-dialog/confirm-reject-dialog.component';
import { ConfirmApproveDialogComponent } from '../../components/confirm-approve-dialog/confirm-approve-dialog.component';
import { CampaignService } from '../../campaign.service';
import { RoutingStateService } from 'src/app/routing-state.service';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { DeliveryMethod } from '../../postage';
import { ManageConflictsDialogComponent } from '../../components/manage-conflicts-dialog/manage-conflicts-dialog.component';
import { Title } from '@angular/platform-browser';
import { FaviconService } from 'src/app/favicon.service';

@Component({
  templateUrl: './campaign-view-page.component.html'
})
export class CampaignViewPageComponent implements OnInit, OnDestroy {
  @ViewChild('downloadCodesLink') private _downloadCodesLink: ElementRef;
  @ViewChild(CampaignViewComponent) campaignViewComponent: CampaignViewComponent;
  campaignTypes$: Observable<CampaignTypeItem[]>;
  campaignBrands$: Observable<CampaignBrandItem[]>;
  deliveryMethods$: Observable<DeliveryMethod[]>;
  loaded$: Observable<boolean>;
  isDownloadingCodes = false;

  campaign: Campaign;

  private user: SmartUser;
  private _subscriptions: Subscription[] = [];
  private _isDeleting: boolean;
  private _isUpdatingPendingStatus: boolean;
  private _escHotkeySubscription: Subscription;
  private _cloningCampaign = false;

  constructor(
    private _campaignServices: CampaignService,
    private _store: Store<fromCampaign.State>,
    private _userStore: Store<fromUser.UserState>,
    private _activatedRoute: ActivatedRoute,
    private _appStore: Store<fromApp.AppState>,
    private _notificationStore: Store<fromNotification.NotificationState>,
    private _actionSubject: ActionsSubject,
    private _dialog: MatDialog,
    private _router: Router,
    private _hotkeysService: Hotkeys,
    private _routingStateService: RoutingStateService,
    private _titleService: Title,
    faviconService: FaviconService) {
    faviconService.update();
  }


  get isLive(): boolean {
    if (this.campaign && this.campaign.status === CampaignStatus.approved) {
      const todayDate = moment();
      const fromDate = moment(this.campaign.validFrom * 1000);
      const toDate = moment(this.campaign.validTo * 1000);
      return todayDate.isBetween(fromDate, toDate);
    }
    return false;
  }

  get isExpired(): boolean {
    if (this.campaign) {
      const todayDate = moment();
      const toDate = moment(this.campaign.validTo * 1000);
      return todayDate.isAfter(toDate);
    }
    return false;
  }

  get isApproved(): boolean {
    return this.campaign && this.campaign.status === CampaignStatus.approved;
  }

  get showEditButton(): boolean {
    return this.campaign && this.canEdit && !this._isDeleting;
  }

  get isLoading(): boolean {
    return this._isDeleting || this._isUpdatingPendingStatus;
  }

  get canClone(): boolean {
    return this.user.roles.includes(Constants.roles.creator);
  }

  public get canEdit(): boolean {
    if (!this.campaign) {
      return false;
    }

    switch (this.campaign.status) {
      case CampaignStatus.draft:
      case CampaignStatus.pending:
      case CampaignStatus.rejected:
        return this.user.roles.includes(Constants.roles.creator);
      case CampaignStatus.approved:
        if (this.isExpired) {
          return false;
        }

        if (this.isLive) {
          return this.user.roles.includes(Constants.roles.manager);
        } else {
          return this.user.roles.includes(Constants.roles.creator);
        }
      default:
        return false;
    }
  }

  public get canApprove(): boolean {
    if (!this.campaign) {
      return false;
    }

    if (this.campaign.status === CampaignStatus.pending) {
      return this.user.roles.includes(Constants.roles.manager) && this.user.email != this.campaign.createdBy;
    }

    return false;
  }

  public get canDownloadCodes(): boolean {
    return this.user.roles.includes(Constants.roles.creator)
      || this.user.roles.includes(Constants.roles.manager);
  }

  get codesAreReadyToDownload(): boolean {
    return this.campaign
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.campaignDiscountRequestStatusId === CampaignCodeRequestStatus.done;
  }

  get isManager(): boolean {
    return this.user.roles.includes(Constants.roles.manager);
  }

  get canManageConflicts(): boolean {
    return this.campaign
      && this.isManager
      && this.isApproved
      && this.campaign.type === CampaignType.sitewidePromotion;
  }

  public get canDelete(): boolean {
    if (!this.campaign) {
      return false;
    }

    switch (this.campaign.status) {
      case CampaignStatus.draft:
        return this.user.roles.includes(Constants.roles.creator);
      default:
        return false;
    }
  }

  ngOnInit(): void {
    this.loaded$ = this._store.pipe(select(fromCampaign.getCurrentCampaignLoaded));
    this.deliveryMethods$ = this._store.pipe(select(fromCampaign.getDeliveryMethods));
    this._store.dispatch(new postageActions.LoadDeliveryMethods());
    this._store.dispatch(new campaignPropertyActions.LoadCampaignTypes());
    this._store.dispatch(new campaignPropertyActions.LoadCampaignBrands());
    this._subscriptions.push(this._activatedRoute.params
      .pipe(map(params => new campaignActions.GetCampaign(params.id)))
      .subscribe(action => this._store.dispatch(action)));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.getCampaignFail)
      .subscribe(() => {
        this._router.navigateByUrl('/404');
      }));
    this._subscriptions.push(this._userStore.pipe(select(fromUser.getCurrentUser)).subscribe(currentUser => {
      this.user = currentUser;
    }));
    this._subscriptions.push(this._store.pipe(select(fromCampaign.getCurrentCampaign)).subscribe((camp) => {
      this.campaign = camp;
      if (this.campaign) {
        this._titleService.setTitle(`(V) ${this.campaign.id}: ${this.campaign.name}`);
      }
    }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.deleteCampaignFail)
      .subscribe(() => {
        this._store.dispatch(new appActions.SetMessage(':-( Something went wrong deleting the campaign, please try again.'));
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.deleteCampaignSuccess)
      .subscribe(() => {
        this._router.navigateByUrl('/campaigns');
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.rejectCampaignFail)
      .subscribe(() => {
        this._store.dispatch(new appActions.SetMessage(':-( Something went wrong rejecting the campaign, please try again.'));
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.rejectCampaignSuccess)
      .subscribe(() => {
        this._notificationStore.dispatch(new notificationActions.SendRejectedCampaignMessage({
          rejectedBy: this.user.email,
          rejectedTimestamp: moment().unix(),
          campaignCreatedBy: this.campaign.createdBy,
          campaignId: this.campaign.id
        }));
        this._router.navigateByUrl('/campaigns');
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.approveCampaignFail)
      .subscribe(() => {
        this._store.dispatch(new appActions.SetMessage(':-( Something went wrong approving the campaign, please try again.'));
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.approveCampaignSuccess)
      .subscribe(() => {
        this._notificationStore.dispatch(new notificationActions.SendApproveCampaignMessage({
          approvedBy: this.user.email,
          approvedTimestamp: moment().unix(),
          campaignCreatedBy: this.campaign.createdBy,
          campaignId: this.campaign.id
        }));
        this._router.navigateByUrl('/dashboard');
      }));
    this._subscriptions.push(this._store.pipe(select(fromCampaign.getIsDeletingCampaign)).subscribe(deleting => {
      this._isDeleting = deleting;
    }));
    this._subscriptions.push(this._store.pipe(select(fromCampaign.getIsUpdatingPendingStatusOfCampaign))
      .subscribe(updatingPendingStaus => {
        this._isUpdatingPendingStatus = updatingPendingStaus;
      }));
    this.campaignTypes$ = this._store.pipe(select(fromCampaign.getCampaignTypes));
    this.campaignBrands$ = this._store.pipe(select(fromCampaign.getCampaignBrands));
    this.registerHotkeys();
    this.registerEscKey();
  }

  ngOnDestroy(): void {
    if (!this._cloningCampaign) {
      this._store.dispatch(new campaignActions.ClearCurrentCampaign);
    }
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.disposeEscKey();
  }

  approve(): void {
    this.disposeEscKey();
    const dialogRef = this._dialog.open(ConfirmApproveDialogComponent, {
      width: '700px',
      data: this.campaign
    });

    dialogRef.afterClosed().subscribe(result => {
      this.registerEscKey();
      if (result) {
        this._store.dispatch(new campaignActions.SetIsChangingPendingStatusOfCampaign);
        this._store.dispatch(new campaignActions.ApproveCampaign(this.campaign.id));
      }
    });
  }

  reject(): void {
    this.disposeEscKey();
    const dialogRef = this._dialog.open(ConfirmRejectDialogComponent, {
      width: '500px',
      data: this.campaign.name
    });

    dialogRef.afterClosed().subscribe(result => {
      this.registerEscKey();
      if (result) {
        this._store.dispatch(new campaignActions.SetIsChangingPendingStatusOfCampaign);
        this._store.dispatch(new campaignActions.RejectCampaign(this.campaign.id));
      }
    });
  }

  delete(): void {
    this.disposeEscKey();
    const dialogRef = this._dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: this.campaign.name
    });

    dialogRef.afterClosed().subscribe(result => {
      this.registerEscKey();
      if (result) {
        this._store.dispatch(new campaignActions.SetIsDeletingCampaign);
        this._store.dispatch(new campaignActions.DeleteCampaign(this.campaign.id));
      }
    });
  }

  manageConflicts(): void {
    this.disposeEscKey();
    const dialogRef = this._dialog.open(ManageConflictsDialogComponent, {
      width: '500px',
      data: this.campaign
    });

    dialogRef.afterClosed().subscribe(() => {
      this.registerEscKey();
    });
  }

  edit(): void {
    this._router.navigateByUrl(`/campaigns/${this.campaign.id}/edit`);
  }

  clone(): void {
    this._cloningCampaign = true;
    this._router.navigateByUrl(`/campaigns/clone/edit`);
    this._appStore.dispatch(new appActions.SetMessage('Campaign cloned'));
  }

  downloadCodes(): void {
    this.isDownloadingCodes = true;
    this._appStore.dispatch(new appActions.SetMessage('Downloading codes, please wait'));
    this._subscriptions.push(this._campaignServices.downloadCampaignCodes(this.campaign.id).subscribe(
      blob => {
        const url = window.URL.createObjectURL(blob);
        const link = this._downloadCodesLink.nativeElement;
        link.href = url;
        link.download = `${this.campaign.id}.zip`;
        link.click();
        this.isDownloadingCodes = false;
        window.URL.revokeObjectURL(url);
      }
    ));
  }

  copyCampaignIdentifierToClipboard(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.campaign.campaignGuid;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._appStore.dispatch(new appActions.SetMessage('Copied to clipboard'));
  }

  goBack(): void {
    const previousRoute = this._routingStateService.getPreviousUrl();
    this._router.navigateByUrl(previousRoute);
  }

  private registerEscKey(): void {
    this._escHotkeySubscription = this._hotkeysService.addShortcut({ keys: 'esc' })
      .subscribe(() => {
        this.goBack();
      });
  }

  private disposeEscKey(): void {
    if (this._escHotkeySubscription) {
      this._escHotkeySubscription.unsubscribe();
    }
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.e' })
      .subscribe(() => {
        if (this.canEdit) {
          this.edit();
        }
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.d' })
      .subscribe(() => {
        if (this.canDelete) {
          this.delete();
        }
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.c' })
      .subscribe(() => {
        this.clone();
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.a' })
      .subscribe(() => {
        if (this.canApprove) {
          this.approve();
        }
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.r' })
      .subscribe(() => {
        if (this.canApprove) {
          this.reject();
        }
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.u' })
      .subscribe(() => {
        if (this.canDownloadCodes && this.codesAreReadyToDownload) {
          this.downloadCodes();
        }
      }));
  }
}
