import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as scheduledCampaignActions from './scheduled-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CampaignListTab } from '../campaign';

@Injectable()
export class ScheduledCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadScheduledCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(scheduledCampaignActions.ScheduledCampaignActionTypes.loadCampaigns),
        map((action: scheduledCampaignActions.LoadCampaigns) => action.payload),
        mergeMap((requestInfo) =>
            this._campaignService.getCampaigns(CampaignListTab.scheduled,
                    requestInfo.pagination.index, requestInfo.pagination.size,
                    requestInfo.sorting.fieldName, requestInfo.sorting.direction).pipe(
                map(campaigns => (new scheduledCampaignActions.LoadCampaignsSuccess(campaigns))),
                catchError(err => of(new scheduledCampaignActions.LoadCampaignsFail('Error loading scheduled campaigns')))
            )
        )
    );
}
