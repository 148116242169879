import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SmartUser } from './smart-user';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { SmartManagerUser } from './smart-manager-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _accountUrl = `${environment.api.rootUrl}account`;

  constructor(private _msalService: MsalService,
    private _http: HttpClient,
    private _helperService: HelperService) {
  }

  logout(): void {
    this._msalService.logout();
  }

  getUserProfile(): Observable<SmartUser> {
    return this._http.get<SmartUser>(`${this._accountUrl}/authcontext`)
      .pipe(
        tap(data => console.log('get user profile: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }

  getManagers(): Observable<SmartManagerUser[]> {
    return this._http.get<SmartManagerUser[]>(`${this._accountUrl}/roles/managers`)
      .pipe(
        tap(data => console.log('get managers: ' + JSON.stringify(data))),
        catchError(this._helperService.handleError)
      );
  }
}
