import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';

/* NgRx */
import * as campaignPropertyActions from './campaign-property.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import * as fromCampaign from '../state';

@Injectable()
export class CampaignPropertyEffects {
    constructor(private _campaignService: CampaignService,
        private _campaignStore: Store<fromCampaign.State>,
        private _actions$: Actions) { }

    @Effect()
    loadCamapignTypes$: Observable<Action> = this._actions$.pipe(
        ofType(campaignPropertyActions.CampaignPropertyActionTypes.loadCampaignTypes),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getCampaignTypesLoaded))),
        filter(([action, campaignTypesLoaded]) => !campaignTypesLoaded),
        mergeMap(() => this._campaignService.getCampaignTypes().pipe(
            map(campaignTypes => (new campaignPropertyActions.LoadCampaignTypesSuccess(campaignTypes))),
            catchError(() => of(new campaignPropertyActions.LoadCampaignTypesFail('Error loading campaign types')))
        ))
    );

    @Effect()
    loadCamapignBrands$: Observable<Action> = this._actions$.pipe(
        ofType(campaignPropertyActions.CampaignPropertyActionTypes.loadCampaignBrands),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getCampaignBrandsLoaded))),
        filter(([action, campaignBrandsLoaded]) => !campaignBrandsLoaded),
        mergeMap(() => this._campaignService.getCampaignBrands().pipe(
            map(campaignBrands => (new campaignPropertyActions.LoadCampaignBrandsSuccess(campaignBrands))),
            catchError(() => of(new campaignPropertyActions.LoadCampaignBrandsFail('Error loading campaign brands')))
        ))
    );

    @Effect()
    getCampaignStatuses$: Observable<Action> = this._actions$.pipe(
        ofType(campaignPropertyActions.CampaignPropertyActionTypes.loadCampaignStatuses),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getCampaignStatusesLoaded))),
        filter(([action, campaignStatusesLoaded]) => !campaignStatusesLoaded),
        mergeMap(() => this._campaignService.getCampaignStatuses().pipe(
            map(campaignStatuses => (new campaignPropertyActions.LoadCampaignStatusesSuccess(campaignStatuses))),
            catchError(() => of(new campaignPropertyActions.LoadCampaignStatusesFail('Error loading campaign statuses')))
        ))
    );
}
