import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { ApprovedCampaignMessage, RejectedCampaignMessage,
  SubmitCampaignRequestForApprovalMessage, AppNotification } from './notification';
import * as notificationActions from './state/notifications.actions';
import * as fromNotification from './state/notifications.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private _notificationsUrl = `${environment.signalR.rootUrl}api/v1/notifications`;
  private _hubConnection: HubConnection | undefined;

  constructor(private _auth: MsalService,
    private _http: HttpClient,
    private _helperService: HelperService,
    private _store: Store<fromNotification.NotificationState>) {
  }

  sendCampaignApprovedMessage(message: ApprovedCampaignMessage): void {
    if (this._hubConnection) {
      this._hubConnection.invoke('SendApprovedCampaignNotification', message).catch(err => console.error(err));
    }
  }

  sendCampaignRejectedMessage(message: RejectedCampaignMessage): void {
    if (this._hubConnection) {
      this._hubConnection.invoke('SendRejectedCampaignNotification', message).catch(err => console.error(err));
    }
  }

  sendRequestCampaignApprovalMessage(message: SubmitCampaignRequestForApprovalMessage): void {
    if (this._hubConnection) {
      this._hubConnection.invoke('SendCampaignPendingApprovalNotification', message).catch(err => console.error(err));
    }
  }

  markNotificationAsRead(notificationId: number): Observable<number> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._notificationsUrl}/${notificationId}/markasread`;
    return this._http.put(url, null, { headers: headers })
      .pipe(
        tap(() => console.log(`Mar notification ${notificationId} as read`)),
        map(() => notificationId),
        catchError(this._helperService.handleError)
      );
  }

  markUserNotificationsAsRead(userId: string): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._notificationsUrl}/user/${userId}/markasread`;
    return this._http.put(url, null, { headers: headers })
      .pipe(
        tap(() => console.log(`Mark notifications from ${userId} notifications as read`)),
        map(() => userId),
        catchError(this._helperService.handleError)
      );
  }

  getNotifications(): Observable<AppNotification[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this._notificationsUrl}`;
    return this._http.get<AppNotification[]>(url, { headers }).pipe(
      tap(data => console.log(JSON.stringify(data))),
      catchError(this._helperService.handleError)
    );
  }

  initHub() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalR.notificationHub, {
        accessTokenFactory: () => {
          const scopes = this._auth.getScopesForEndpoint(environment.signalR.rootUrl);
          const tokenStored = this._auth.getCachedTokenInternal(scopes);
          if (tokenStored && tokenStored.token) {
            return tokenStored.token;
          } else {
            return this._auth.acquireTokenSilent(scopes);
          }
        }
      })
      .configureLogging(<LogLevel.Debug>environment.signalR.logLevel)
      .build();

    this._hubConnection.start().catch(err => console.error(err.toString()));

    this._hubConnection.onclose(() => {
      setTimeout(() => {
        this._hubConnection.start().catch(err => console.error(err.toString()));
      }, 5000);
    });

    this._hubConnection.on('SendNotification', (notification: AppNotification) => {
        this._store.dispatch(new notificationActions.ReceivedNotification(notification));
    });
  }
}
