import { Component, OnInit, OnDestroy } from '@angular/core';

import * as fromNotification from '../../../notifications/state/notifications.reducer';
import * as notificationActions from '../../../notifications/state/notifications.actions';
import { Store, select } from '@ngrx/store';
import { NotificationUser, AppNotification } from '../../notification';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit, OnDestroy {
  usersWithNotifications$: Observable<NotificationUser[]>;
  allNotifications$: Observable<AppNotification[]>;
  userNotifications$: Observable<AppNotification[]>;
  selectedUser: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _titleService: Title,
    private _notificationStore: Store<fromNotification.NotificationState>,
    private _router: Router) { }

  ngOnInit() {
    this._titleService.setTitle('Notifications');
    this.usersWithNotifications$ = this._notificationStore.pipe(select(fromNotification.getUsersWithNotifications));
    this.allNotifications$ = this._notificationStore.pipe(select(fromNotification.getNotifications));

    this._subscriptions.push(this._notificationStore.pipe(select(fromNotification.getSelectedUser))
      .subscribe(selectedUser => {
        this.selectedUser = selectedUser;
        this.userNotifications$ = this.allNotifications$
          .map(notifications => notifications.filter(notification => notification.from === selectedUser));
      }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (subscription) {
          subscription.unsubscribe();
      }
  });
  }

  selectUser(user: NotificationUser): void {
    this._notificationStore.dispatch(new notificationActions.SelectUser(user.name));
    this._notificationStore.dispatch(
      new notificationActions.MarkUserNotificationsAsRead(user.name));
  }

  notificationAction(notification: AppNotification): void {
    this._notificationStore.dispatch(new notificationActions.MarkNotificationAsRead(notification.id));
    if (notification.campaignId) {
      this._router.navigateByUrl(`/campaigns/${notification.campaignId}/view`);
    }

  }
}
