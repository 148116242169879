import { Component, Input, ViewContainerRef } from '@angular/core';

@Component({
// tslint:disable-next-line: component-selector
   selector: 'div[app-type=section]',
   template: ''
})
export class RuleListSectionComponent {
  @Input() active: boolean;

   constructor(public viewContainerRef: ViewContainerRef) { }
}
