import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CampaignService } from '../campaigns/campaign.service';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeValidator {
  constructor(private _campaignService: CampaignService) {}

  isCodeAvailable(currentCampaignId: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this._campaignService.isGenericCodeAvailable(control.value).pipe(
        map(codeAvailability => {
          if (codeAvailability.isAvailable) {
            return null;
          }

          if (codeAvailability.campaignId === currentCampaignId) {
            return null;
          }

          return { uniqueCode: true };
        }),
        catchError(() => of({ uniqueCode: true }))
      );
    };
  }
}
