import { Action } from '@ngrx/store';
import { CampaignSummaryResult, PaginationInfo, CampaignListingRequestInfo, SortingInfo } from '../campaign';

export enum CreatedByMeCampaignActionTypes {
    loadCampaigns = '[Created by me Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Created by me Campaigns] Load campaigns success',
    loadCampaignsFail = '[Created by me Campaigns] Load campaigns fail',
    setPaginationInfo = '[Created by me Campaigns] Set pagination info',
    setSortingInfo = '[Created by me Campaigns] Set sorting info',
    setIsloadingCampaigns = '[Created by me Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = CreatedByMeCampaignActionTypes.loadCampaigns;
    constructor(public payload: CampaignListingRequestInfo) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = CreatedByMeCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = CreatedByMeCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = CreatedByMeCampaignActionTypes.setIsloadingCampaigns;
}

export class SetPaginationInfo implements Action {
    readonly type = CreatedByMeCampaignActionTypes.setPaginationInfo;
    constructor(public payload: PaginationInfo) { }
}

export class SetSortingInfo implements Action {
    readonly type = CreatedByMeCampaignActionTypes.setSortingInfo;
    constructor(public payload: SortingInfo) { }
}

export type CreatedByMeCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSortingInfo
    | SetPaginationInfo;
