import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignInConflict, CampaignPriority, Campaign } from '../../campaign';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { uniqueValueValidator } from 'src/app/shared/unique-value.directive';
import { ActionsSubject, Store, select } from '@ngrx/store';
import * as fromCampaign from '../../state';
import * as fromApp from '../../../state/app.reducer';
import * as campaignActions from '../../state/campaign.actions';
import * as appActions from '../../../state/app.actions';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-campaign-conflicts',
  templateUrl: './campaign-conflicts.component.html',
  styleUrls: ['./campaign-conflicts.component.scss']
})
export class CampaignConflictsComponent implements OnInit {
  @Input() conflicts: CampaignInConflict[];
  @Input() campaign: Campaign;
  @Output() cancel = new EventEmitter<void>();
  @Output() conflictsFixed = new EventEmitter<void>();

  conflictsForm: FormGroup;
  isUpdatingPriorities$: Observable<boolean>;

  private _subscriptions: Subscription[] = [];

  get campaignsInConflict() {
    return this.conflictsForm.get('campaigns') as FormArray;
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromCampaign.State>,
    private _appStore: Store<fromApp.AppState>,
    private _actionSubject: ActionsSubject) {
    this.conflictsForm = this._fb.group({
      campaigns: this._fb.array([], [uniqueValueValidator()])
    });
  }

  ngOnInit(): void {
    this.isUpdatingPriorities$ = this._store.pipe(select(fromCampaign.getIsUpdatingPriorities));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.updatePrioritiesFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage(':-( Something went wrong updating the prioroties, please try again.'));
      }));
    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === campaignActions.CampaignActionTypes.updatePrioritiesSuccess)
      .subscribe(() => {
        this.conflictsFixed.emit();
      }));
    this.initializeForm();
  }

  cancelForm(): void {
    this.cancel.emit();
  }

  updatePriorities(): void {
    if (this.conflictsForm.valid) {
      const priorities = this.extractPrioritiesForm();
      this._store.dispatch(new campaignActions.SetIsUpdatingPriorities);
      this._store.dispatch(new campaignActions.UpdatePriorities(priorities));
    }
  }

  private initializeForm(): void {
    this.addCampaign({
      campaignId: this.campaign.id,
      name: this.campaign.name,
      priority: this.campaign.priority,
      validFrom: this.campaign.validFrom,
      validTo: this.campaign.validTo
    });
    this.conflicts.forEach(camp => {
      this.addCampaign(camp);
    });
  }

  private addCampaign(campaignConflict: CampaignInConflict): void {
    this.campaignsInConflict.push(this._fb.group({
      campaignName: [campaignConflict.name],
      campaignValidFrom: [campaignConflict.validFrom],
      campaignValidTo: [campaignConflict.validTo],
      campaignId: [campaignConflict.campaignId, [Validators.required]],
      campaignPriority: [campaignConflict.priority, [Validators.required]]
    }));
  }

  private extractPrioritiesForm(): CampaignPriority[] {
    return this.campaignsInConflict.value.map(item => {
      return {
        campaignId: item.campaignId,
        priority: item.campaignPriority
      };
    });
  }
}
