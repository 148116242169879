import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Campaign, CampaignBrandItem, CampaignTypeItem, CodeType,
  CampaignStatus, CampaignType, CampaignCodeRequestStatus } from '../../campaign';
import * as moment from 'moment';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnChanges {
  @Input() selectedCampaign: Campaign;
  @Input() campaignTypes: CampaignTypeItem[];
  @Input() campaignBrands: CampaignBrandItem[];
  @Input() canDelete: boolean;
  @Input() canClone: boolean;
  @Input() canApprove: boolean;
  @Input() canDownloadCodes: boolean;
  @Input() isLoading: boolean;
  @Input() isDownloadingCodes: boolean;
  @Input() canManageConflicts: boolean;
  @Input() deliveryMethods: DeliveryMethod[];
  @Output() deleteCampaign = new EventEmitter<void>();
  @Output() approveCampaign = new EventEmitter<void>();
  @Output() rejectCampaign = new EventEmitter<void>();
  @Output() cloneCampaign = new EventEmitter<void>();
  @Output() downloadCampaignCodes = new EventEmitter<void>();
  @Output() manageConflictsEvent = new EventEmitter<void>();
  @Output() copyIdentifierToClipboard = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();
  campaign: Campaign;

  constructor() { }

  get campaignBrand(): string {
    if (this.campaign && this.campaign.brand) {
      if (this.campaignBrands) {
        const result = this.campaignBrands.find(brand => brand.id === this.campaign.brand);
        if (result) {
          return result.brandName;
        }
      }
    }
    return '';
  }

  get campaignType(): string {
    if (this.campaign && this.campaign.type) {
      return this.campaignTypes.find(type => type.id === this.campaign.type).name;
    }
    return '';
  }

  get campaignStatus(): string {
    return CampaignStatus[this.campaign.status];
  }

  get isPromotionCampaign(): boolean {
    if (this.campaign && this.campaign.type && this.campaignTypes) {
      const campType = this.campaignTypes.find(type => type.id === this.campaign.type);
      if (campType) {
        return campType.allowFlashSales;
      }
    }
    return false;
  }

  get isURLPromotion(): boolean {
    if (this.campaign && this.campaign.type) {
      return this.campaign.type === CampaignType.urlPromotion;
    }
    return false;
  }

  get hasGenericDiscountCodes(): boolean {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.typeId === CodeType.generic;
    }
    return false;
  }

  get showCodeGenerationInformartion(): boolean {
    return this.campaign
      && this.campaign.status === CampaignStatus.approved
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.typeId === CodeType.unique;
  }

  get codesAreWaitingToBeGenearted(): boolean {
    return this.campaign
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.campaignDiscountRequestStatusId === CampaignCodeRequestStatus.pending;
  }

  get codesAreReadyToDownload(): boolean {
    return this.campaign
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.campaignDiscountRequestStatusId === CampaignCodeRequestStatus.done;
  }

  get generatingCodes(): boolean {
    return this.campaign
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.campaignDiscountRequestStatusId === CampaignCodeRequestStatus.processing;
  }

  get codeGenerationFailed(): boolean {
    return this.campaign
      && this.campaign.codeDefinition
      && this.campaign.codeDefinition.campaignDiscountRequestStatusId === CampaignCodeRequestStatus.failed;
  }

  get isLive(): boolean {
    if (this.campaign && this.campaign.status === CampaignStatus.approved) {
      const today = moment();
      const from = moment(this.campaign.validFrom * 1000);
      const to = moment(this.campaign.validTo * 1000);
      return today.isBetween(from, to);
    }
    return false;
  }

  get isExpired(): boolean {
    if (this.campaign && this.campaign.status === CampaignStatus.approved) {
      const today = moment();
      const to = moment(this.campaign.validTo * 1000);
      return today.isAfter(to);
    }
    return false;
  }

  get isScheduled(): boolean {
    if (this.campaign && this.campaign.status === CampaignStatus.approved) {
      const today = moment();
      const from = moment(this.campaign.validFrom * 1000);
      return today.isBefore(from);
    }
    return false;
  }

  get isApproved(): boolean {
    return this.campaign && this.campaign.status === CampaignStatus.approved;
  }

  get approvedStatusCssClass(): string[] {
    return this.isLive
      ? ['status', 'is-live']
      : this.isScheduled
        ? ['status', 'is-scheduled']
        : ['status', 'is-expired'];
  }

  get maxCampaignRedemptionsParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.maxCampaignRedemptions > 0
        ? `£${this.campaign.codeDefinition.maxCampaignRedemptions}.00`
        : 'Unlimited';
    }

    return '';
  }

  get disallowedCharactersParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
     return this.campaign.codeDefinition.disallowedCharacters
      ? this.campaign.codeDefinition.disallowedCharacters
      : '-';
    }

    return '';
  }

  get maxRedemptionsPerCodeParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.maxRedemptionsPerCode > 0
        ? `${this.campaign.codeDefinition.maxRedemptionsPerCode} times`
        : 'Unlimited';
    }

    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCampaign) {
      this.displayCampaign(changes.selectedCampaign.currentValue);
    }
  }

  displayCampaign(campaign: Campaign) {
    if (campaign) {
      this.campaign = campaign;
    }
  }

  delete(): void {
    this.deleteCampaign.emit();
  }

  approve(): void {
    this.approveCampaign.emit();
  }

  reject(): void {
    this.rejectCampaign.emit();
  }

  clone(): void {
    this.cloneCampaign.emit();
  }

  downloadCodes(): void {
    this.downloadCampaignCodes.emit();
  }

  manageConflicts(): void {
    this.manageConflictsEvent.emit();
  }

  copyCampaignIdentifierToClipboard(): void {
    this.copyIdentifierToClipboard.emit();
  }

  back(): void {
    this.goBack.emit();
  }
}
