import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HelperService } from '../shared/helper.service';
import { environment } from 'src/environments/environment';
import { RuleField, CampaignEffectType } from './campaign';

@Injectable({
    providedIn: 'root'
})
export class RuleBuilderService {
    private _ruleFieldsUrl = `${environment.api.rootUrl}conditiontypes`;
    private _ruleCampaignEffectTypesUrl = `${environment.api.rootUrl}effecttypes/campaign`;

    constructor(private _http: HttpClient, private _helperService: HelperService) { }

    getFields(): Observable<RuleField[]> {
        return this._http.get<RuleField[]>(this._ruleFieldsUrl)
            .pipe(
                tap(data => console.log(JSON.stringify(data))),
                catchError(this._helperService.handleError)
            );
    }

    getCampaignEffectTypes(): Observable<CampaignEffectType[]> {
      return this._http.get<CampaignEffectType[]>(this._ruleCampaignEffectTypesUrl)
            .pipe(
                tap(data => console.log(JSON.stringify(data))),
                catchError(this._helperService.handleError)
            );
    }
}
