import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

/* NgRx */
import * as filteredCampaignActions from './filtered-campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

@Injectable()
export class FilteredCampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _actions$: Actions) { }

    @Effect()
    loadAllCampaignSummaries$: Observable<Action> = this._actions$.pipe(
        ofType(filteredCampaignActions.FilteredCampaignActionTypes.loadCampaigns),
        map((action: filteredCampaignActions.LoadCampaigns) => action.payload),
        switchMap(searchReuest =>
            this._campaignService.searchCampaigns(searchReuest.searchInfo.searchTerm,
                    searchReuest.pagination.index, searchReuest.pagination.size,
                    searchReuest.sortingInfo.fieldName, searchReuest.sortingInfo.direction).pipe(
                        map(campaigns => (new filteredCampaignActions.LoadCampaignsSuccess(campaigns))),
                        catchError(() => of(new filteredCampaignActions.LoadCampaignsFail('Error loading filtered campaign summaries')))
            )
        )
    );
}
