import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewChildren,
  QueryList,
  ComponentFactory,
  AfterViewInit,
  ComponentRef,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  SimpleChange
} from '@angular/core';
import { RuleComponent } from '../rule/rule.component';
import { RuleListSectionComponent } from '../../rule-list-section.component';
import { Ruleset, Campaign, RuleField, CampaignEffectType, DragAndDropAction } from '../../campaign';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCampaign from '../../state';
import * as postageActions from '../../state/postage.actions';
import { DeliveryMethod } from '../../postage';
import { CampaignService } from '../../campaign.service';

@Component({
  selector: 'app-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.scss']
})
export class RuleListComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @ViewChildren(RuleListSectionComponent) sections: QueryList<RuleListSectionComponent>;
  @Input() selectedCampaign: Campaign;
  @Input() ruleFields: RuleField[];
  @Input() campaignEffectTypes: CampaignEffectType[];
  @Output() rulesChange = new EventEmitter<boolean>();
  @Output() isValid = new EventEmitter<boolean>();

  private _campaign: Campaign;
  private _componentFactory: ComponentFactory<RuleComponent>;
  private _activeSections: RuleListSectionComponent[];
  private _ruleComponents: ComponentRef<RuleComponent>[] = [];
  private _ruleDeleteSubscriptions: Subscription[] = [];

  private _ruleFields: RuleField[];
  private _deliveryMethods: DeliveryMethod[];
  private _campaignEffectTypes: CampaignEffectType[];
  private _subscriptions: Subscription[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private _campaignService: CampaignService,
    private _store: Store<fromCampaign.State>) { }

  get rules(): Ruleset[] {
    return this._campaign ? this._campaign.rules : [];
  }

  get campaignType(): number {
    return this._campaign ? this._campaign.type : null;
  }

  get isFlashSale(): boolean {
    return this._campaign
      ? this._campaign.isFlashSale
        ? this._campaign.isFlashSale
        : false
      : false;
  }

  get brandId(): number {
    return this._campaign
      ? this._campaign.brand
      : 1;
  }

  ngOnInit() {
    this._subscriptions.push(this._store.pipe(select(fromCampaign.getDeliveryMethods)).subscribe(deleveryMethods => {
      this._deliveryMethods = deleveryMethods;
    }));
    this._componentFactory = this.componentFactoryResolver.resolveComponentFactory(RuleComponent);
    this._store.dispatch(new postageActions.LoadDeliveryMethods());
  }

  ngOnDestroy() {
    console.log('Destroy rule list component');
    this._ruleComponents.forEach(component => {
      component.destroy();
      console.log('Rule component destroyed');
    });
    this._ruleDeleteSubscriptions.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCampaign && changes.selectedCampaign.currentValue) {
      setTimeout(() => {
        const camp = changes.selectedCampaign.currentValue as Campaign;
        this._campaign = { ...camp };
        if (!changes.selectedCampaign.isFirstChange()) {
          this.displayRules();
          this.validateRules();
        }
      });
    }

    if (changes.ruleFields && changes.ruleFields.currentValue) {
      this._ruleFields = changes.ruleFields.currentValue;
    }

    if (changes.campaignEffectTypes && changes.campaignEffectTypes.currentValue) {
      this._campaignEffectTypes = changes.campaignEffectTypes.currentValue;
    }
    this.rulesChange.emit(false);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._activeSections = this.sections.reduce((result, section, index) => {
        if (section.active) {
          result.push(section);
        }
        return result;
      }, []);
      this.displayRules();
    });
  }

  addNewRule(): void {
    const priority = this.rules ? this.rules.length + 1 : 1;
    const newRule = {
      id: 0,
      sku: this._campaignService.newGuid(),
      priority: priority,
      condition: 'OR',
      conditionId: 1,
      rules: [],
      effect: {
        typeId: null,
        description: '',
        valueType: null,
        isPercent: false,
        value: 0,
        dimension: null,
        product: null,
        postage: null
      },
      field: null,
      fieldId: null,
      name: null,
      operator: null,
      operatorId: null,
      value: null
    };
    this._campaign.rules.push(newRule);
    this.addRule(newRule, this.campaignType, this.isFlashSale, this.brandId);
  }

  addRule(rule: Ruleset, campaignType: number, isFlashSale: boolean, brandId: number): void {
    if (this._activeSections) {
      this._activeSections.forEach((section) => {
        const componentRef = section.viewContainerRef.createComponent(this._componentFactory);
        (<RuleComponent>componentRef.instance).selectedRule = rule;
        (<RuleComponent>componentRef.instance).campaignType = campaignType;
        (<RuleComponent>componentRef.instance).isFlashSale = isFlashSale;
        (<RuleComponent>componentRef.instance).brandId = brandId;
        (<RuleComponent>componentRef.instance).ruleFields = this._ruleFields;
        (<RuleComponent>componentRef.instance).deliveryMethods = this._deliveryMethods;
        (<RuleComponent>componentRef.instance).campaignEffectTypes = this._campaignEffectTypes;
        (<RuleComponent>componentRef.instance).deleteRuleClick.subscribe(() => {
          this.deleteRule(componentRef);
        });
        (<RuleComponent>componentRef.instance).ruleChange.subscribe(() => {
          this.ruleChange(<RuleComponent>componentRef.instance);
        });
        (<RuleComponent>componentRef.instance).dropEvent.subscribe((event: DragAndDropAction) => {
          this.changeRulePriority(event);
        });
        (<RuleComponent>componentRef.instance).ngOnChanges({ selectedRule: new SimpleChange('', rule, true) });
        this._ruleDeleteSubscriptions.push((<RuleComponent>componentRef.instance).ruleChange.subscribe(() => {
          this.ruleChange(<RuleComponent>componentRef.instance);
        }));
        this._ruleComponents.push(componentRef);
      });
    }
  }

  deleteRule(componentRef: ComponentRef<RuleComponent>): void {
    const deletedRule = (<RuleComponent>componentRef.instance).rule;
    this._campaign.rules = this.rules.filter((rule) => {
        return rule.sku !== deletedRule.sku;
      });
    this._ruleComponents = this._ruleComponents.filter((ruleComponentRef) => {
      const ruleComponent = <RuleComponent>ruleComponentRef.instance;
      return ruleComponent.rule.sku !== deletedRule.sku;
    });

    componentRef.destroy();
    this.validateRules();
    this.rulesChange.emit(true);
  }

  ruleChange(ruleComponent: RuleComponent): void {
    const ruleFilteredResult = this._campaign.rules.filter(r => r.sku === ruleComponent.rule.sku);

    if (ruleFilteredResult && ruleFilteredResult.length === 1) {
      const ruleIndex = this._campaign.rules.findIndex(rule => rule.sku === ruleComponent.rule.sku);
      this._campaign.rules[ruleIndex] = {
        ...ruleFilteredResult[0],
        ...ruleComponent.rule
      };
    }

    this.validateRules();
    this.rulesChange.emit(true);
  }

  changeRulePriority(ev: DragAndDropAction) {
    const draggedRule = this.rules.find((rule) => {
      return rule.sku === ev.dragRuleSku;
    });
    const previousRuleOnPosition = this.rules.find((rule) => {
      return rule.sku === ev.dragOnTopOfRuleSku;
    });

    draggedRule.priority = previousRuleOnPosition.priority;
    previousRuleOnPosition.priority++;

    this.rules.forEach(rule => {
      if (rule.priority > draggedRule.priority && rule.sku !== previousRuleOnPosition.sku) {
        rule.priority++;
      }
    });

    this.displayRules();
  }

  private displayRules() {
    // Reset values
    this._ruleComponents.forEach(component => component.destroy());
    this._ruleComponents = [];
    this._ruleDeleteSubscriptions.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });

    // Add rules
    if (this.rules && this.rules.length > 0) {
      // sort by priority
      this._campaign.rules = this.rules.sort((a: Ruleset, b: Ruleset) => a.priority < b.priority ? -1 : 1);

      this.rules.forEach((rule) => this.addRule(rule, this.campaignType, this.isFlashSale, this.brandId));
    }
  }

  private validateRules(): void {
    const invalidComponents = this._ruleComponents.filter(ruleComponent => {
      return !(<RuleComponent>ruleComponent.instance).isValid;
    });
    this.isValid.emit(invalidComponents.length === 0 && this._ruleComponents.length > 0);
  }
}
