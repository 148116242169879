import { Injectable } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as notificationActions from './notifications.actions';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApprovedCampaignMessage, RejectedCampaignMessage,
    SubmitCampaignRequestForApprovalMessage, AppNotification } from '../notification';
import { MatSnackBar } from '@angular/material';
import { NotificationSnackBarComponent } from '../components/notification-snack-bar/notification-snack-bar.component';


@Injectable()
export class NotificationEffects {
    constructor(private _notificationService: NotificationsService,
        private _snackBar: MatSnackBar,
        private _actions$: Actions) { }

        @Effect()
        sendApprovedCampaignMessage$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.sendApproveCampaignMessage),
            map((action: notificationActions.SendApproveCampaignMessage) => action.payload),
            mergeMap((message: ApprovedCampaignMessage) => {
                try {
                    this._notificationService.sendCampaignApprovedMessage(message);
                    return of(new notificationActions.SendApproveCampaignMessageSuccess());
                } catch (error) {
                    return of(new notificationActions.SendApproveCampaignMessageFail(error));
                }
            })
        );

        @Effect()
        sendRejectedCampaignMessage$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.sendRejectedCampaignMessage),
            map((action: notificationActions.SendRejectedCampaignMessage) => action.payload),
            mergeMap((message: RejectedCampaignMessage) => {
                try {
                    this._notificationService.sendCampaignRejectedMessage(message);
                    return of(new notificationActions.SendRejectedCampaignMessageSuccess());
                } catch (error) {
                    return of(new notificationActions.SendRejectedCampaignMessageFail(error));
                }
            })
        );

        @Effect()
        sendCampaignForApprovalMessage$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.sendCampaignForApprovalMessage),
            map((action: notificationActions.SendCampaignRequestForApprovalMessage) => action.payload),
            mergeMap((message: SubmitCampaignRequestForApprovalMessage) => {
                try {
                    this._notificationService.sendRequestCampaignApprovalMessage(message);
                    return of(new notificationActions.SendCampaignRequestForApprovalMessageSuccess());
                } catch (error) {
                    return of(new notificationActions.SendCampaignRequestForApprovalMessageFail(error));
                }
            })
        );

        @Effect()
        markNotificationAsRead$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.markNotificationAsRead),
            map((action: notificationActions.MarkNotificationAsRead) => action.payload),
            mergeMap((notificationId: number) =>
            this._notificationService.markNotificationAsRead(notificationId).pipe(
                map(() => (new notificationActions.MarkNotificationAsReadSuccess(notificationId))),
                catchError(() => of(new notificationActions.MarkNotificationAsReadFail('Error marking notification as read')))
                )
            )
        );

        @Effect()
        markUserNotificationsAsRead$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.markUserNotificationsAsRead),
            map((action: notificationActions.MarkUserNotificationsAsRead) => action.payload),
            mergeMap((userId: string) =>
            this._notificationService.markUserNotificationsAsRead(userId).pipe(
                map(() => (new notificationActions.MarkUserNotificationsAsReadSuccess(userId))),
                catchError(() => of(new notificationActions.MarkUserNotificationsAsReadFail('Error marking user notifications as read')))
                )
            )
        );

        @Effect()
        loadNotifications$: Observable<Action> = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.loadNotifications),
            map((action: notificationActions.LoadNotificationsSuccess) => action.payload),
            mergeMap(() =>
            this._notificationService.getNotifications().pipe(
                map((notifications: AppNotification[]) => (new notificationActions.LoadNotificationsSuccess(notifications))),
                catchError(() => of(new notificationActions.LoadNotificationsFail('Error loading notifications')))
                )
            )
        );

        @Effect({ dispatch: false })
        showCampaignNotification$ = this._actions$.pipe(
            ofType(notificationActions.NotificationActionTypes.receivedNotification),
            map((action: notificationActions.ReceivedNotification) => {
                this._snackBar.openFromComponent(NotificationSnackBarComponent, {
                    horizontalPosition: 'right',
                    verticalPosition: 'bottom',
                    data: action.payload
                });
            })
        );
}
