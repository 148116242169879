import { Injectable } from '@angular/core';
import { DeliveryMethodService } from '../delivery-method.service';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { mergeMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import * as postageActions from './postage.actions';
import * as fromCampaign from '../state';

@Injectable()
export class PostageEffects {
    constructor(private _deliveryMethodService: DeliveryMethodService,
        private _campaignStore: Store<fromCampaign.State>,
        private _actions$: Actions) { }

    @Effect()
    getDeliveryMethods$: Observable<Action> = this._actions$.pipe(
        ofType(postageActions.PostageActionTypes.loadDeliveryMethods),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getDeliveryMethodsLoaded))),
        filter(([action, deliveryMethodsLoaded]) => !deliveryMethodsLoaded),
        mergeMap(() => this._deliveryMethodService.getDeliveryMethods().pipe(
            map(deliveryMethods => (new postageActions.LoadDeliveryMethodsSuccess(deliveryMethods))),
            catchError(err => of(new postageActions.LoadDeliveryMethodsFail('Error loading delivery methods')))
        ))
    );
}
