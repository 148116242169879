import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SmartManagerUser } from 'src/app/user/smart-manager-user';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-submit-to-approval-dialog',
  templateUrl: './submit-to-approval-dialog.component.html',
  styleUrls: ['./submit-to-approval-dialog.component.scss']
})
export class SubmitToApprovalDialogComponent implements OnDestroy, OnInit {
  private _managersSubscription: Subscription;

  selectedManager: string;

  constructor(public dialogRef: MatDialogRef<SubmitToApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<SmartManagerUser[]>) {
    }

  ngOnInit(): void {
    this._managersSubscription = this.data.subscribe(managers => {
      this.selectedManager = managers[0].email;
    });
  }

  ngOnDestroy(): void {
    this._managersSubscription.unsubscribe();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
