import { DimensionName, DimensionValue } from '../components/dimension-input/dimension';
import { DimensionActions, DimensionActionTypes } from './dimension.actions';

export interface DimensionState {
    dimensionNames: DimensionName[];
    dimensionNamesLoaded: boolean;
    filteredDimensionValues: DimensionValue[];
    isLoadingDimensionNames: boolean;
    isLoadingDimensionValues: boolean;
    error: string;
}

const initialState: DimensionState = {
    dimensionNames: [],
    dimensionNamesLoaded: false,
    filteredDimensionValues: [],
    isLoadingDimensionNames: false,
    isLoadingDimensionValues: false,
    error: ''
};

export function reducer(state = initialState, action: DimensionActions) {
    switch (action.type) {
        case DimensionActionTypes.loadDimensionNamesSuccess:
            return {
                ...state,
                dimensionNames: action.payload,
                dimensionNamesLoaded: true,
                isLoadingDimensionNames: false
            };
        case DimensionActionTypes.loadDimensionNamesFail:
            return {
                ...state,
                dimensionNames: [],
                dimensionNamesLoaded: false,
                isLoadingDimensionNames: false
            };
        case DimensionActionTypes.loadDimensionValuesSuccess:
            return {
                ...state,
                filteredDimensionValues: action.payload,
                isLoadingDimensionValues: false
            };
        case DimensionActionTypes.loadDimensionValuesFail:
            return {
                ...state,
                filteredDimensionValues: [],
                isLoadingDimensionValues: false
            };
        case DimensionActionTypes.setIsLoadingDimensionNames:
            return {
                ...state,
                isLoadingDimensionNames: true
            };
        case DimensionActionTypes.setIsLoadingDimensionValues:
            return {
                ...state,
                isLoadingDimensionValues: true
            };
        default:
            return state;
    }
}
