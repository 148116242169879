import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoutingStateService {
    private _history = [];

    constructor(
        private _router: Router
    ) { }

    public loadRouting(): void {
        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this._history = [...this._history, urlAfterRedirects];
            });
    }

    public getHistory(): string[] {
        return this._history;
    }

    public getPreviousUrl(): string {
        const maxLoop = 10;
        let iterationCount = 1;
        let index = 2; // Previous route index
        let previousPage: string;
        const currentPage = this._history[this._history.length - 1] || '/dashboard';
        let checkNext = true;

        while (checkNext) {
            if (iterationCount >= maxLoop) {
                break;
            }

            previousPage = this._history[this._history.length - index] || '/dashboard';
            if (previousPage.endsWith('/edit')) {
                index--;
                iterationCount++;
                continue;
            }

            if (previousPage === currentPage) {
                index--;
                iterationCount++;
                continue;
            }

            index--;
            iterationCount++;
            checkNext = false;
        }

        return previousPage;
    }
}
