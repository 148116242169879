import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationUser } from '../../notification';

@Component({
  selector: 'app-notification-users',
  templateUrl: './notification-users.component.html',
  styleUrls: ['./notification-users.component.scss']
})
export class NotificationUsersComponent implements OnInit {
  @Input() users: NotificationUser[];
  @Input() selectedUser: string;
  @Output() selectedUserEvent = new EventEmitter<NotificationUser>();
  constructor() { }

  ngOnInit() {
  }

  selectUser(user: NotificationUser): void {
    this.selectedUserEvent.emit(user);
  }
}
