import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { DimensionService } from '../dimension.service';
import { Observable, of } from 'rxjs';
import { Action, Store, select } from '@ngrx/store';
import * as dimensionActions from './dimension.actions';
import { catchError, mergeMap, map, withLatestFrom, filter } from 'rxjs/operators';
import * as fromCampaign from '../state';

@Injectable()
export class DimensionEffects {
    constructor(private _dimensionService: DimensionService,
        private _campaignStore: Store<fromCampaign.State>,
        private _actions$: Actions) { }

    @Effect()
    loadDimensionNames$: Observable<Action> = this._actions$.pipe(
        ofType(dimensionActions.DimensionActionTypes.loadDimensionNames),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getDimensionNamesLoaded))),
        filter(([action, dimensionNamesLoaded]) => !dimensionNamesLoaded),
        mergeMap(() => this._dimensionService.getDimensionNames().pipe(
            map(dimensionNames => (new dimensionActions.LoadDimensionNamesSuccess(dimensionNames))),
            catchError(err => of(new dimensionActions.LoadDimensionNamesFail('Error loading dimension names')))
        ))
    );

    @Effect()
    loadDimensionValues$: Observable<Action> = this._actions$.pipe(
        ofType(dimensionActions.DimensionActionTypes.loadDimensionValues),
        map((action: dimensionActions.LoadDimnesionValues) => action.payload),
        mergeMap((dimensionNameId: number) =>
            this._dimensionService.getDimensionValues(dimensionNameId).pipe(
            map(dimensionValues => (new dimensionActions.LoadDimnesionValuesSuccess(dimensionValues))),
            catchError(err => of(new dimensionActions.LoadDimnesionValuesFail('Error loading dimension values')))
        ))
    );
}
