import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SmartUser } from './smart-user';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loggedInUser: SmartUser;
  @Output() logoutUser = new EventEmitter<void>();

  user: SmartUser;

  constructor() {
  }

  logout() {
    this.logoutUser.emit();
  }

  ngOnInit() {
    this.user = this.loggedInUser;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loggedInUser && changes.loggedInUser.currentValue) {
      this.user = changes.loggedInUser.currentValue;
    }
  }

  ngOnDestroy(): void {
  }
}
