import { Action } from '@ngrx/store';
import { CampaignSummaryResult, SearchRequest } from '../campaign';

export enum FilteredCampaignActionTypes {
    loadCampaigns = '[Filtered Campaigns] Load campaigns',
    loadCampaignsSuccess = '[Filtered Campaigns] Load campaigns success',
    loadCampaignsFail = '[Filtered Campaigns] Load campaigns fail',
    setSearchRequestInfo = '[Filtered Campaigns] Set search request info',
    setIsloadingCampaigns = '[Filtered Campaigns] loading'
}

export class LoadCampaigns implements Action {
    readonly type = FilteredCampaignActionTypes.loadCampaigns;
    constructor(public payload: SearchRequest) { }
}

export class LoadCampaignsSuccess implements Action {
    readonly type = FilteredCampaignActionTypes.loadCampaignsSuccess;
    constructor(public payload: CampaignSummaryResult) { }
}

export class LoadCampaignsFail implements Action {
    readonly type = FilteredCampaignActionTypes.loadCampaignsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingCampaigns implements Action {
    readonly type = FilteredCampaignActionTypes.setIsloadingCampaigns;
}

export class SetSearchRequestInfo implements Action {
    readonly type = FilteredCampaignActionTypes.setSearchRequestInfo;
    constructor(public payload: SearchRequest) { }
}

export type FilteredCampaignActions = LoadCampaigns
    | LoadCampaignsSuccess
    | LoadCampaignsFail
    | SetIsLoadingCampaigns
    | SetSearchRequestInfo;
