import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import * as fromNotification from '../../state/notifications.reducer';
import * as notificationActions from '../../state/notifications.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppNotification } from '../../notification';

@Component({
  selector: 'app-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.scss']
})
export class NotificationSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: AppNotification,
  private _snackBarRef: MatSnackBarRef<NotificationSnackBarComponent>,
  private _notificationStore: Store<fromNotification.NotificationState>,
  private _router: Router) {
    _snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snack-bar was dismissed');
    });

    _snackBarRef.onAction().subscribe(() => {
      console.log('The snack-bar action was triggered!');
    });
  }

  viewCampaign(): void {
    this._notificationStore.dispatch(new notificationActions.MarkNotificationAsRead(this.data.id));
    this._router.navigateByUrl(`/campaigns/${this.data.campaignId}/view`);
    this._snackBarRef.dismiss();
  }

  close(): void {
    this._snackBarRef.dismiss();
  }
}
