import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CampaignSummaryResult, CampaignListTab, CampaignSummary, PaginationInfo, CampaignListingRequestInfo,
  SortingInfo, SortFieldName, SortDirection } from '../../campaign';
import { Store, select, ActionsSubject } from '@ngrx/store';
import * as fromCampaign from '../../state';
import * as campaignActions from '../../state/campaign.actions';
import * as liveCampaignActions from '../../state/live-campaign.actions';
import * as tabPendingApprovalCampaignActions from '../../../campaigns/state/tab-pending-approval-campaign.actions';
import * as draftCampaignActions from '../../state/draft-campaign.actions';
import * as scheduledCampaignActions from '../../state/scheduled-campaign.actions';
import * as expiredCampaignActions from '../../state/expired-campaign.actions';
import * as allCampaignActions from '../../state/all-campaign.actions';
import * as fromApp from '../../../state/app.reducer';
import * as fromUser from '../../../user/state/user.reducer';
import * as appActions from '../../../state/app.actions';
import { MatTabChangeEvent, PageEvent, Sort } from '@angular/material';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SmartUser } from 'src/app/user/smart-user';
import { Constants } from 'src/app/constants';
import { Hotkeys } from 'src/app/shared/hotkeys.service';
import { Title } from '@angular/platform-browser';
import { FaviconService } from 'src/app/favicon.service';


@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit, OnDestroy {
  componentActive = true;
  currentTab: CampaignListTab;
  liveCampaignSummaries: CampaignSummaryResult;
  pendingCampaignSummaries: CampaignSummaryResult;
  draftCampaignSummaries: CampaignSummaryResult;
  scheduledCampaignSummaries: CampaignSummaryResult;
  expiredCampaignSummaries: CampaignSummaryResult;
  allCampaignSummaries: CampaignSummaryResult;
  loadingLiveCampaigs$: Observable<boolean>;
  loadingPendingCampaigs$: Observable<boolean>;
  loadingDraftCampaigs$: Observable<boolean>;
  loadingScheduledCampaigs$: Observable<boolean>;
  loadingExpiredCampaigs$: Observable<boolean>;
  loadingAllCampaigs$: Observable<boolean>;
  displayedColumns: string[] = ['id', 'brand', 'campaignType', 'name', 'createdBy', 'validFrom', 'validTo'];
  searchActivated = false;

  private _subscriptions: Subscription[] = [];
  private _currentUser: SmartUser;

  get isCreator(): boolean {
    return this._currentUser
      ? this._currentUser.roles.indexOf(Constants.roles.creator) > -1
      : false;
  }

  constructor(
    private _store: Store<fromCampaign.State>,
    private _userStore: Store<fromUser.UserState>,
    private _appStore: Store<fromApp.AppState>,
    private _router: Router,
    private _hotkeysService: Hotkeys,
    private _actionSubject: ActionsSubject,
    private _titleService: Title,
    faviconService: FaviconService) {
    faviconService.update();
  }


  ngOnInit(): void {
    this.loadingLiveCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingLiveCampaigns));
    this.loadingPendingCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingTabPendingApprovalCampaigns));
    this.loadingDraftCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingDraftCampaigns));
    this.loadingScheduledCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingScheduledCampaigns));
    this.loadingExpiredCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingExpiredCampaigns));
    this.loadingAllCampaigs$ = this._store.pipe(select(fromCampaign.getIsLoadingAllCampaigns));

    this._subscriptions.push(this._userStore.pipe(select(fromUser.getCurrentUser)).subscribe(currentUser => {
      this._currentUser = currentUser;
    }));

    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === liveCampaignActions.LiveCampaignActionTypes.loadCampaignsFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage('Error loading live campaigns'));
      }));

    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === draftCampaignActions.DraftCampaignActionTypes.loadCampaignsFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage('Error loading draft campaigns'));
      }));

    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === scheduledCampaignActions.ScheduledCampaignActionTypes.loadCampaignsFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage('Error loading scheduled campaigns'));
      }));

    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === expiredCampaignActions.ExpiredCampaignActionTypes.loadCampaignsFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage('Error loading expired campaigns'));
      }));

    this._subscriptions.push(this._actionSubject
      .filter(action => action.type === allCampaignActions.AllCampaignActionTypes.loadCampaignsFail)
      .subscribe(() => {
        this._appStore.dispatch(new appActions.SetMessage('Error loading all campaigns'));
      }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getLiveCampaignSummaries)).subscribe(campSummaries => {
      this.liveCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getTabPendingApprovalCampaignSummaries)).subscribe(campaignSummaries => {
      this.pendingCampaignSummaries = campaignSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getDraftCampaignSummaries)).subscribe(campSummaries => {
      this.draftCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getScheduledCampaignSummaries)).subscribe(campSummaries => {
      this.scheduledCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getExpiredCampaignSummaries)).subscribe(campSummaries => {
      this.expiredCampaignSummaries = campSummaries;
    }));

    this._subscriptions.push(this._store.pipe(select(fromCampaign.getAllCampaignSummaries)).subscribe(campSummaries => {
      this.allCampaignSummaries = campSummaries;
    }));

    this._store.pipe(
      select(fromCampaign.getCurrentCampaignListTab),
      takeWhile(() => this.componentActive)
    ).subscribe(
      currentCampaignListTab => {
        this.currentTab = currentCampaignListTab;
        this._titleService.setTitle(`${this.capitalize(CampaignListTab[this.currentTab])} Campaigns`);
      }
    );

    this.loadCampaigns();
    this.registerHotkeys();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this._subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  tabChanged(tabChangedEvent: MatTabChangeEvent) {
    this._store.dispatch(new campaignActions.SetCurrentCampaignListTab(tabChangedEvent.index));
    this.loadCampaigns();
  }

  searchActivatedEvent(active: boolean) {
    this.searchActivated = active;
  }

  private capitalize(string: String): string {
    if (typeof string !== 'string') {
      return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private registerHotkeys(): void {
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'alt.r' })
      .subscribe(() => {
        this.loadCampaigns();
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'arrowright' })
      .subscribe(() => {
        this.nextTab();
      }));
    this._subscriptions.push(this._hotkeysService.addShortcut({ keys: 'arrowleft' })
      .subscribe(() => {
        this.previousTab();
      }));
  }

  private nextTab(): void {
    if (this.currentTab === CampaignListTab.all) {
      this.currentTab = CampaignListTab.live;
    } else {
      this.currentTab = <CampaignListTab>(this.currentTab + 1);
    }
  }

  private previousTab(): void {
    if (this.currentTab === CampaignListTab.live) {
      this.currentTab = CampaignListTab.all;
    } else {
      this.currentTab = <CampaignListTab>(<number>this.currentTab - 1);
    }
  }

  private loadCampaigns() {
    switch (this.currentTab) {
      case CampaignListTab.live:
        this._store.dispatch(new liveCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new liveCampaignActions.LoadCampaigns(this.getCampaignListRequest(this.liveCampaignSummaries)));
        break;
      case CampaignListTab.pending:
        this._store.dispatch(new tabPendingApprovalCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new tabPendingApprovalCampaignActions
          .LoadCampaigns(this.getCampaignListRequest(this.pendingCampaignSummaries)));
        break;
      case CampaignListTab.draft:
        this._store.dispatch(new draftCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new draftCampaignActions.LoadCampaigns(this.getCampaignListRequest(this.draftCampaignSummaries)));
        break;
      case CampaignListTab.scheduled:
        this._store.dispatch(new scheduledCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new scheduledCampaignActions.LoadCampaigns(this.getCampaignListRequest(this.scheduledCampaignSummaries)));
        break;
      case CampaignListTab.expired:
        this._store.dispatch(new expiredCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new expiredCampaignActions.LoadCampaigns(this.getCampaignListRequest(this.expiredCampaignSummaries)));
        break;
      case CampaignListTab.all:
        this._store.dispatch(new allCampaignActions.SetIsLoadingCampaigns());
        this._store.dispatch(new allCampaignActions.LoadCampaigns(this.getCampaignListRequest(this.allCampaignSummaries)));
        break;
    }
  }

  viewCampaign(campaign: CampaignSummary) {
    this._router.navigateByUrl(`/campaigns/${campaign.id}/view`);
  }

  pagination(pageEvent: PageEvent) {
    switch (this.currentTab) {
      case CampaignListTab.live:
        this._store.dispatch(new liveCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
      case CampaignListTab.pending:
        this._store.dispatch(new tabPendingApprovalCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
      case CampaignListTab.draft:
        this._store.dispatch(new draftCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
      case CampaignListTab.scheduled:
        this._store.dispatch(new scheduledCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
      case CampaignListTab.expired:
        this._store.dispatch(new expiredCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
      case CampaignListTab.all:
        this._store.dispatch(new allCampaignActions
          .SetPaginationInfo({ index: pageEvent.pageIndex, size: pageEvent.pageSize, count: pageEvent.length }));
        this.loadCampaigns();
        break;
    }
  }

  sortData(sort: Sort) {
    const currentPaginationInfo = this.getCurrentPaginationInfo();
    switch (this.currentTab) {
      case CampaignListTab.live:
        this._store.dispatch(new liveCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new liveCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
      case CampaignListTab.pending:
        this._store.dispatch(new tabPendingApprovalCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new tabPendingApprovalCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
      case CampaignListTab.draft:
        this._store.dispatch(new draftCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new draftCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
      case CampaignListTab.scheduled:
        this._store.dispatch(new scheduledCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new scheduledCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
      case CampaignListTab.expired:
        this._store.dispatch(new expiredCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new expiredCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
      case CampaignListTab.all:
        this._store.dispatch(new allCampaignActions
          .SetPaginationInfo({ index: 0, size: currentPaginationInfo.size, count: currentPaginationInfo.count }));
        this._store.dispatch(new allCampaignActions
          .SetSortingInfo({ fieldName: <SortFieldName>sort.active, direction: <SortDirection>sort.direction }));
        this.loadCampaigns();
        break;
    }
  }

  private getDefaultPagination(): PaginationInfo {
    return {
      count: 0,
      index: 0,
      size: 20
    };
  }

  private getDefaultSorting(): SortingInfo {
    return {
      fieldName: SortFieldName.empty,
      direction: SortDirection.none
    };
  }

  private getCurrentPaginationInfo(): PaginationInfo {
    switch (this.currentTab) {
      case CampaignListTab.live:
        return this.liveCampaignSummaries
          ? this.liveCampaignSummaries.pagination
          : this.getDefaultPagination();
      case CampaignListTab.pending:
        return this.pendingCampaignSummaries
          ? this.pendingCampaignSummaries.pagination
          : this.getDefaultPagination();
      case CampaignListTab.draft:
        return this.draftCampaignSummaries
          ? this.draftCampaignSummaries.pagination
          : this.getDefaultPagination();
      case CampaignListTab.scheduled:
        return this.scheduledCampaignSummaries
          ? this.scheduledCampaignSummaries.pagination
          : this.getDefaultPagination();
      case CampaignListTab.expired:
        return this.expiredCampaignSummaries
          ? this.expiredCampaignSummaries.pagination
          : this.getDefaultPagination();
      case CampaignListTab.all:
        return this.allCampaignSummaries
          ? this.allCampaignSummaries.pagination
          : this.getDefaultPagination();
      default:
        return this.getDefaultPagination();
    }
  }

  private getCampaignListRequest(campaignSummary: CampaignSummaryResult): CampaignListingRequestInfo {
    if (campaignSummary) {
      return {
        pagination: campaignSummary.pagination,
        sorting: campaignSummary.sortingInfo
      };
    } else {
      return {
        pagination: this.getDefaultPagination(),
        sorting: this.getDefaultSorting()
      };
    }
  }
}
