import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotkeys-help-dialog',
  templateUrl: './hotkeys-help-dialog.component.html',
  styleUrls: ['./hotkeys-help-dialog.component.scss']
})
export class HotkeysHelpDialogComponent implements OnInit {
  hotkeys = [
    {
      section: 'Generic hotkeys',
      hotkey: [
        {
          keys: ['Esc'],
          description: 'Cancel'
        },
        {
          keys: ['Shift &#8679;', '?'],
          description: 'Open this help'
        },
        {
          keys: ['Alt', 'n'],
          description: 'New campaign'
        },
        {
          keys: ['Alt', 'q'],
          description: 'Log out'
        },
        {
          keys: ['Alt', 'h'],
          description: 'Go to dashboard'
        },
        {
          keys: ['Alt', 'm'],
          description: 'Go to notifications'
        },
        {
          keys: ['Alt', 's'],
          description: 'Search campaign'
        },
        {
          keys: ['Alt', 'r'],
          description: 'Refresh tables'
        }
      ]
    },
    {
      section: 'On Campaigns screen',
      hotkey: [
        {
          keys: ['&#8594;'],
          description: 'Move to next tab'
        },
        {
          keys: ['&#8592;'],
          description: 'Move to previous tab'
        }
      ]
    },
    {
      section: 'On Campaign view screen',
      hotkey: [
        {
          keys: ['Alt', 'e'],
          description: 'Edit campaign'
        },
        {
          keys: ['Alt', 'd'],
          description: 'Delete camaign'
        },
        {
          keys: ['Alt', 'd'],
          description: 'Delete camaign'
        },
        {
          keys: ['Alt', 'c'],
          description: 'Clone camaign'
        },
        {
          keys: ['Alt', 'a'],
          description: 'Approve camaign'
        },
        {
          keys: ['Alt', 'r'],
          description: 'Reject camaign'
        },
        {
          keys: ['Alt', 'u'],
          description: 'Download camaign unique codes'
        }
      ]
    },
    {
      section: 'On Campaign edit screen',
      hotkey: [
        {
          keys: ['Alt', 'j'],
          description: 'Go to previous step'
        },
        {
          keys: ['Alt', 'k'],
          description: 'Go to next step'
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {

  }

}
