import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { OpenedByMeCampaignActions, OpenedByMeCampaignActionTypes } from './opened-by-me-campaign.actions';

export interface OpenedByMeCampaignState {
    openedByMeCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: OpenedByMeCampaignState = {
    openedByMeCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 3, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: OpenedByMeCampaignActions): OpenedByMeCampaignState {
    switch (action.type) {
        case OpenedByMeCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                openedByMeCampaigns: action.payload,
                isLoading: false
            };
        case OpenedByMeCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                openedByMeCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case OpenedByMeCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case OpenedByMeCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                openedByMeCampaigns: {
                    ...state.openedByMeCampaigns,
                    pagination: action.payload
                }
            };
        case OpenedByMeCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                openedByMeCampaigns: {
                    ...state.openedByMeCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
