import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Campaign, CampaignTypeItem, CampaignBrandItem, CodeType } from '../../campaign';
import { RuleListComponent } from '../rule-list/rule-list.component';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-campaign-review',
  templateUrl: './campaign-review.component.html',
  styleUrls: ['./campaign-review.component.scss']
})
export class CampaignReviewComponent implements OnInit, OnChanges {
  @ViewChild(RuleListComponent) ruleList: RuleListComponent;
  @Input() selectedCampaign: Campaign;
  @Input() campaignTypes: CampaignTypeItem[];
  @Input() campaignBrands: CampaignBrandItem[];
  @Input() deliveryMethods: DeliveryMethod[];
  @Output() stepChanged = new EventEmitter<number>();

  campaign: Campaign;

  constructor() { }

  get campaignBrand(): string {
    if (this.campaign && this.campaign.brand) {
      const result = this.campaignBrands.find(brand => brand.id === this.campaign.brand);
      if (result) {
        return result.brandName;
      }
    }
    return '';
  }

  get campaignType(): string {
    if (this.campaign && this.campaign.type) {
      const result = this.campaignTypes.find(type => type.id === this.campaign.type);
      if (result) {
        return result.name;
      }
    }
    return '';
  }

  get isPromotionCampaign(): boolean {
    if (this.campaign && this.campaign.type) {
      const result = this.campaignTypes.find(type => type.id === this.campaign.type);
      if (result) {
        return result.allowFlashSales;
      }
    }
    return false;
  }

  get hasGenericDiscountCodes(): boolean {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.typeId === CodeType.generic;
    }
    return false;
  }

  get maxCampaignRedemptionsParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.maxCampaignRedemptions > 0
        ? `£${this.campaign.codeDefinition.maxCampaignRedemptions}.00`
        : 'Unlimited';
    }

    return '';
  }

  get disallowedCharactersParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
     return this.campaign.codeDefinition.disallowedCharacters
      ? this.campaign.codeDefinition.disallowedCharacters
      : '-';
    }

    return '';
  }

  get maxRedemptionsPerCodeParsed(): string {
    if (this.campaign && this.campaign.codeDefinition) {
      return this.campaign.codeDefinition.maxRedemptionsPerCode > 0
        ? `${this.campaign.codeDefinition.maxRedemptionsPerCode} times`
        : 'Unlimited';
    }

    return '';
  }

  ngOnInit(): void {
    console.log('Initialize campaign review');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCampaign && changes.selectedCampaign.currentValue) {
      const campaign: any = changes.selectedCampaign.currentValue as Campaign;
      this.campaign = campaign;
    }
  }

  jumpToStep(index: number): void {
    this.stepChanged.emit(index);
  }
}
