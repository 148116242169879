import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './state/user.effects';
import { UserMenuComponent } from './user-menu.component';
import { AuthService } from './auth.service';

@NgModule({
    imports: [
      SharedModule,
      StoreModule.forFeature('users', reducer),
      EffectsModule.forFeature([UserEffects])
    ],
    declarations: [
        UserMenuComponent
    ],
    exports: [
      UserMenuComponent
    ],
    providers: [
      AuthService
    ]
  })
  export class UserModule { }
