import { CampaignSummaryResult, SortFieldName, SortDirection } from '../campaign';
import { CreatedByMeCampaignActions, CreatedByMeCampaignActionTypes } from './created-by-me-campaign.actions';

export interface CreatedByMeCampaignState {
    createdByMeCampaigns: CampaignSummaryResult;
    isLoading: boolean;
    error: string;
}

const initialState: CreatedByMeCampaignState = {
    createdByMeCampaigns: {
        campaigns: [],
        pagination: { index: 0, size: 3, count: 0 },
        searchInfo: { searchTerm: '' },
        sortingInfo: { fieldName: SortFieldName.empty, direction: SortDirection.none }
    },
    isLoading: false,
    error: ''
};

export function reducer(state = initialState, action: CreatedByMeCampaignActions): CreatedByMeCampaignState {
    switch (action.type) {
        case CreatedByMeCampaignActionTypes.loadCampaignsSuccess:
            return {
                ...state,
                createdByMeCampaigns: action.payload,
                isLoading: false
            };
        case CreatedByMeCampaignActionTypes.loadCampaignsFail:
            return {
                ...state,
                createdByMeCampaigns: null,
                isLoading: false,
                error: action.payload
            };
        case CreatedByMeCampaignActionTypes.setIsloadingCampaigns:
            return {
                ...state,
                isLoading: true
            };
        case CreatedByMeCampaignActionTypes.setPaginationInfo:
            return {
                ...state,
                createdByMeCampaigns: {
                    ...state.createdByMeCampaigns,
                    pagination: action.payload
                }
            };
        case CreatedByMeCampaignActionTypes.setSortingInfo:
            return {
                ...state,
                createdByMeCampaigns: {
                    ...state.createdByMeCampaigns,
                    sortingInfo: action.payload
                }
            };
        default:
            return state;
    }
}
