import { Action } from '@ngrx/store';
import { SmartUser } from '../smart-user';
import { SmartManagerUser } from '../smart-manager-user';

export enum UserActionTypes {
    signOut = '[User] Sign out',
    signOutSuccess = '[User] Sign out success',
    loadUserProfile = '[User] Load user profile',
    loadUserProfileSuccess = '[User] Load user profile success',
    loadUserProfileFail = '[User] Load user profile fail',
    loadManagers = '[User] Load managers',
    loadManagersSuccess = '[User] Load managers success',
    loadManagersFail = '[User] Load managers fail',
    setIsLoadingManagers = '[User] Set is loading managers to true',
}

export class SignOut implements Action {
    readonly type = UserActionTypes.signOut;
}

export class SignOutSuccess implements Action {
    readonly type = UserActionTypes.signOutSuccess;
}

export class LoadUserProfile implements Action {
    readonly type = UserActionTypes.loadUserProfile;
}

export class LoadUserProfileSuccess implements Action {
    readonly type = UserActionTypes.loadUserProfileSuccess;
    constructor(public payload: SmartUser) { }
}

export class LoadUserProfileFail implements Action {
    readonly type = UserActionTypes.loadUserProfileFail;
    constructor(public payload: string) { }
}

export class LoadManagers implements Action {
    readonly type = UserActionTypes.loadManagers;
}

export class LoadManagersSuccess implements Action {
    readonly type = UserActionTypes.loadManagersSuccess;
    constructor(public payload: SmartManagerUser[]) { }
}

export class LoadManagersFail implements Action {
    readonly type = UserActionTypes.loadManagersFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingManagers implements Action {
    readonly type = UserActionTypes.setIsLoadingManagers;
}

export type UserActions = SignOut
    | SignOutSuccess
    | LoadUserProfile
    | LoadUserProfileSuccess
    | LoadUserProfileFail
    | LoadManagers
    | LoadManagersSuccess
    | LoadManagersFail
    | SetIsLoadingManagers;
