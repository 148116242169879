import { Action } from '@ngrx/store';
import { CampaignTypeItem, CampaignBrandItem, CampaignStatus } from '../campaign';

export enum CampaignPropertyActionTypes {
    loadCampaignTypes = '[Campaign Property] Load campaign types',
    loadCampaignTypesSuccess = '[Campaign Property] Load campaign types success',
    loadCampaignTypesFail = '[Campaign Property] Load campaign types fail',
    loadCampaignBrands = '[Campaign Property] Load campaign brands',
    loadCampaignBrandsSuccess = '[Campaign Property] Load campaign brands success',
    loadCampaignBrandsFail = '[Campaign Property] Load campaign brands fail',
    loadCampaignStatuses = '[Campaign Property] Load statuses',
    loadCampaignStatusesSuccess = '[Campaign Property] Load statuses success',
    loadCampaignStatusesFail = '[Campaign Property] Load statuses fail',
}

export class LoadCampaignTypes implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignTypes;
}

export class LoadCampaignTypesSuccess implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignTypesSuccess;
    constructor(public payload: CampaignTypeItem[]) { }
}

export class LoadCampaignTypesFail implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignTypesFail;
    constructor(public payload: string) { }
}

export class LoadCampaignBrands implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignBrands;
}

export class LoadCampaignBrandsSuccess implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignBrandsSuccess;
    constructor(public payload: CampaignBrandItem[]) { }
}

export class LoadCampaignBrandsFail implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignBrandsFail;
    constructor(public payload: string) { }
}

export class LoadCampaignStatuses implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignStatuses;
}

export class LoadCampaignStatusesSuccess implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignStatusesSuccess;
    constructor(public payload: CampaignStatus[]) { }
}

export class LoadCampaignStatusesFail implements Action {
    readonly type = CampaignPropertyActionTypes.loadCampaignStatusesFail;
    constructor(public payload: string) { }
}

export type CampaignPropertyActions = LoadCampaignTypes
    | LoadCampaignTypesSuccess
    | LoadCampaignTypesFail
    | LoadCampaignBrands
    | LoadCampaignBrandsSuccess
    | LoadCampaignBrandsFail
    | LoadCampaignStatuses
    | LoadCampaignStatusesSuccess
    | LoadCampaignStatusesFail;
