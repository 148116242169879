import { Injectable } from '@angular/core';
import { CampaignService } from '../campaign.service';

/* RxJs */
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/* NgRx */
import * as campaignActions from './campaign.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ErrorCode, CampaignPriority } from '../campaign';
import { MatSnackBar } from '@angular/material';
import { CampaignModel } from '../campaign.model';

@Injectable()
export class CampaignEffects {
    constructor(private _campaignService: CampaignService,
        private _snackBar: MatSnackBar,
        private _actions$: Actions) { }

    @Effect()
    getCampaign$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.getCampaign),
        map((action: campaignActions.GetCampaign) => action.payload),
        mergeMap((campaignId: string) =>
            this._campaignService.getCampaign(campaignId).pipe(
                map(campaign => (new campaignActions.GetCampaignSuccess(campaign))),
                catchError(() => of(new campaignActions.GetCampaignFail('Error getting campaign')))
            )
        )
    );

    @Effect()
    getCampaignConflicts$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.getCampaignConflicts),
        map((action: campaignActions.GetCampaignConflicts) => action.payload),
        mergeMap((campaignId: number) =>
            this._campaignService.getCampaignConflicts(campaignId).pipe(
                map(conflicts => (new campaignActions.GetCampaignConflictsSuccess(conflicts))),
                catchError(() => of(new campaignActions.GetCampaignConflictsFail('Error getting conflicts')))
            )
        )
    );

    @Effect()
    saveCampaign$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.saveCampaign),
        map((action: campaignActions.SaveCampaign) => action.payload),
        mergeMap((campaign: CampaignModel) =>
            this._campaignService.saveCampaign(campaign).pipe(
                map(newCampaign => (new campaignActions.SaveCampaignSuccess(newCampaign))),
                catchError( (code: ErrorCode) => {
                    switch (code) {
                        case ErrorCode.ConcurrencyError:
                            return of(new campaignActions.SaveCampaignOutOfDateError);
                        default:
                            return of(new campaignActions.SaveCampaignFail('Error saving campaign'));
                    }
                })
            )
        )
    );

    @Effect()
    deleteCampaign$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.deleteCampaign),
        map((action: campaignActions.DeleteCampaign) => action.payload),
        mergeMap((campaignId: number) =>
            this._campaignService.deleteCampaign(campaignId).pipe(
                map(success => {
                    if (success) {
                        return new campaignActions.DeleteCampaignSuccess('Deleted campaign successfuly');
                    }
                    return new campaignActions.DeleteCampaignFail('Error deleting campaign');
                }),
                catchError(() => of(new campaignActions.DeleteCampaignFail('Error deleting campaign')))
            )
        )
    );

    @Effect()
    approveCampaign$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.approveCampaign),
        map((action: campaignActions.ApproveCampaign) => action.payload),
        mergeMap((campaignId: number) =>
            this._campaignService.approveCampaign(campaignId).pipe(
                map(() => (new campaignActions.ApproveCampaignSuccess('Campaign approved successfuly'))),
                catchError(() => of(new campaignActions.ApproveCampaignFail('Error approving campaign')))
            )
        )
    );

    @Effect()
    rejectCampaign$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.rejectCampaign),
        map((action: campaignActions.RejectCampaign) => action.payload),
        mergeMap((campaignId: number) =>
            this._campaignService.rejectCampaign(campaignId).pipe(
                map(() => (new campaignActions.RejectCampaignSuccess('Campaign rejected successfuly'))),
                catchError(() => of(new campaignActions.RejectCampaignFail('Error rejecting campaign')))
            )
        )
    );

    @Effect()
    updatePriorities$: Observable<Action> = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.updatePriorities),
        map((action: campaignActions.UpdatePriorities) => action.payload),
        mergeMap((priorities: CampaignPriority[]) =>
            this._campaignService.updatePriorities(priorities).pipe(
                map(() => (new campaignActions.UpdatePrioritiesSuccess())),
                catchError(() => of(new campaignActions.UpdatePrioritiesFail('Failed to update priorities')))
            )
        )
    );

    @Effect({ dispatch: false })
    saveCampaignSuccessMessage$ = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.saveCampaignSuccess),
        map((action: campaignActions.SaveCampaign) => {
            this._snackBar.open(`Campaign '${action.payload.name}' saved`, null, {
                duration: 2000,
              });
        })
    );

    @Effect({ dispatch: false })
    deleteCampaignSuccessMessage$ = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.deleteCampaignSuccess),
        map(() => {
            this._snackBar.open(`Campaign deleted`, null, {
                duration: 2000,
              });
        })
    );

    @Effect({ dispatch: false })
    approveCampaignSuccessMessage$ = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.approveCampaignSuccess),
        map(() => {
            this._snackBar.open(`Campaign approved`, null, {
                duration: 2000,
              });
        })
    );

    @Effect({ dispatch: false })
    rejectCampaignSuccessMessage$ = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.rejectCampaignSuccess),
        map(() => {
            this._snackBar.open(`Campaign rejected`, null, {
                duration: 2000,
              });
        })
    );

    @Effect({ dispatch: false })
    updatePrioritiesSuccessMessage$ = this._actions$.pipe(
        ofType(campaignActions.CampaignActionTypes.updatePrioritiesSuccess),
        map(() => {
            this._snackBar.open(`Campaigns priority updated`, null, {
                duration: 2000,
              });
        })
    );
}
