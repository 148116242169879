import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from '../campaigns/campaign';
import { AppInsights } from 'applicationinsights-js';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      switch (err.status) {
        case 409: // Entity out of date (consurrency problem)
          AppInsights.defaultClient.trackException(err);
          return throwError(ErrorCode.ConcurrencyError);

        default:
          console.warn('status code error not handled');
          break;
      }
    }

    AppInsights.defaultClient.trackException(err);
    return throwError(ErrorCode.GenericError);
  }
}
