import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store, select } from '@ngrx/store';
import { mergeMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import * as ruleBuilderActions from './rule-builder.actions';
import { RuleBuilderService } from '../rule-builder.service';
import * as fromCampaign from '../state';

@Injectable()
export class RuleBuilderEffects {
    constructor(private _rulebuilderService: RuleBuilderService,
        private _campaignStore: Store<fromCampaign.State>,
        private _actions$: Actions) { }

    @Effect()
    getRuleFields$: Observable<Action> = this._actions$.pipe(
        ofType(ruleBuilderActions.RuleBuilderActionTypes.loadFields),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getRuleBuilderFieldsLoaded))),
        filter(([action, fieldsLoaded]) => !fieldsLoaded),
        mergeMap(() => this._rulebuilderService.getFields().pipe(
            map(ruleFields => (new ruleBuilderActions.LoadFieldsSuccess(ruleFields))),
            catchError(err => of(new ruleBuilderActions.LoadFieldsFail('Error loading fields')))
        ))
    );

    @Effect()
    getRuleCampaignEffectTypes$: Observable<Action> = this._actions$.pipe(
        ofType(ruleBuilderActions.RuleBuilderActionTypes.loadCampaignEffectTypes),
        withLatestFrom(this._campaignStore.pipe(select(fromCampaign.getCamapignEffectTypesLoaded))),
        filter(([action, effectTypesLoaded]) => !effectTypesLoaded),
        mergeMap(() => this._rulebuilderService.getCampaignEffectTypes().pipe(
            map(campaignEffectTypes => (new ruleBuilderActions.LoadCampaignEffectTypesSuccess(campaignEffectTypes))),
            catchError(err => of(new ruleBuilderActions.LoadCampaignEffectTypesFail('Error loadging effect types')))
        ))
    );
}
