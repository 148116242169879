import { Component, OnInit, Input } from '@angular/core';
import { Ruleset } from '../../campaign';
import { DeliveryMethod } from '../../postage';

@Component({
  selector: 'app-rule-viewer',
  templateUrl: './rule-viewer.component.html',
  styleUrls: ['./rule-viewer.component.scss']
})
export class RuleViewerComponent {
  @Input() rules: Ruleset[];
  @Input() deliveryMethods: DeliveryMethod[];

  constructor() { }

}
