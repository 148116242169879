import { RuleField, CampaignEffectType } from '../campaign';
import { RuleBuilderActions, RuleBuilderActionTypes } from './rule-builder.actions';

export interface RuleBuilderStage {
    fields: RuleField[];
    fieldsLoaded: boolean;
    campaignEffectTypes: CampaignEffectType[];
    campaignEffectTypesLoaded: boolean;
    error: string;
}

const initialState: RuleBuilderStage = {
    fields: [],
    fieldsLoaded: false,
    campaignEffectTypes: null,
    campaignEffectTypesLoaded: false,
    error: ''
};

export function reducer(state = initialState, action: RuleBuilderActions) {
    switch (action.type) {
        case RuleBuilderActionTypes.loadFieldsSuccess:
            return {
                ...state,
                fields: action.payload,
                fieldsLoaded: true
            };
        case RuleBuilderActionTypes.loadFieldsFail:
            return {
                ...state,
                fields: [],
                fieldsLoaded: false,
                error: action.payload
            };
        case RuleBuilderActionTypes.loadCampaignEffectTypesSuccess:
            return {
                ...state,
                campaignEffectTypes: action.payload,
                campaignEffectTypesLoaded: true
            };
        case RuleBuilderActionTypes.loadCampaignEffectTypesFail:
            return {
                ...state,
                campaignEffectTypes: [],
                campaignEffectTypesLoaded: false,
                error: action.payload
            };
        default:
            return state;
    }
}
