import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CampaignSummary, PaginationInfo } from 'src/app/campaigns/campaign';
import { PageEvent, Sort } from '@angular/material';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent {
  @Input() campaigns: CampaignSummary[];
  @Input() pagination: PaginationInfo;
  @Input() loading: boolean;
  @Output() viewCampaign = new EventEmitter<CampaignSummary>();
  @Output() paginationEvent = new EventEmitter<PageEvent>();
  @Output() sortEvent = new EventEmitter<Sort>();
  @Output() refreshEvent = new EventEmitter<void>();

  pendingApprovalColumns: string[] = ['id', 'createdBy', 'brand'];

  get showTable(): boolean {
    return this.campaigns && this.campaigns.length > 0;
  }

  constructor() { }

  viewSelectedCampaign(campaign: CampaignSummary): void {
    this.viewCampaign.emit(campaign);
  }

  paginationAction(pageEvent: PageEvent): void {
    this.paginationEvent.emit(pageEvent);
  }

  sortData(sort: Sort): void {
    this.sortEvent.emit(sort);
  }

  refresh(): void {
    this.refreshEvent.emit();
  }
}
