import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() unreadNotificationsCount: number;

  constructor() { }

  get year(): number {
    return new Date().getFullYear();
  }

  get version(): string {
    return environment.app.version;
  }

  ngOnInit() {
  }

}
