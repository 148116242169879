import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../user/state/user.reducer';
import { FaviconService } from '../favicon.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bye',
  templateUrl: './bye.component.html',
  styleUrls: ['./bye.component.scss']
})
export class ByeComponent implements OnInit {

  constructor(private _store: Store<UserState>,
    titleService: Title,
    faviconService: FaviconService) {
      titleService.setTitle('Bye bye...');
      faviconService.update();
  }

  ngOnInit() {
    //this._store.dispatch(new userActions.SignOutSuccess());
  }
}
