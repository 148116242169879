import { Action } from '@ngrx/store';
import { DeliveryMethod } from '../postage';

export enum PostageActionTypes {
    loadDeliveryMethods = '[Postage] Load delivery methods',
    loadDeliveryMethodsSuccess = '[Postage] Load delivery methods success',
    loadDeliveryMethodsFail = '[Postage] Load delivery methods fail',
    setIsLoading = '[Postage] Set is loading to true'
}

export class LoadDeliveryMethods implements Action {
    readonly type = PostageActionTypes.loadDeliveryMethods;
}

export class LoadDeliveryMethodsSuccess implements Action {
    readonly type = PostageActionTypes.loadDeliveryMethodsSuccess;
    constructor(public payload: DeliveryMethod[]) { }
}

export class LoadDeliveryMethodsFail implements Action {
    readonly type = PostageActionTypes.loadDeliveryMethodsFail;
    constructor(public payload: string) { }
}

export class SetIsloading implements Action {
    readonly type = PostageActionTypes.setIsLoading;
}

export type PostageActions = LoadDeliveryMethods
    | LoadDeliveryMethodsSuccess
    | LoadDeliveryMethodsFail
    | SetIsloading;
