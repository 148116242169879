import { Action } from '@ngrx/store';
import { Product, ProductSearch } from '../product';

export enum ProductActionTypes {
    searchProducts = '[Product] Search products',
    setIsLoadingProducts = '[Product] Set is loading products',
    searchProductsSuccess = '[Product] Search products success',
    searchProductsFail = '[Product] Search products fail'
}

export class SearchProducts implements Action {
    readonly type = ProductActionTypes.searchProducts;
    constructor(public payload: ProductSearch) { }
}

export class SearchProductsSuccess implements Action {
    readonly type = ProductActionTypes.searchProductsSuccess;
    constructor(public payload: Product[]) { }
}

export class SearchProductsFail implements Action {
    readonly type = ProductActionTypes.searchProductsFail;
    constructor(public payload: string) { }
}

export class SetIsLoadingProducts implements Action {
    readonly type = ProductActionTypes.setIsLoadingProducts;
}

export type ProductActions = SearchProducts
    | SearchProductsSuccess
    | SearchProductsFail
    | SetIsLoadingProducts;
