import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  private _faviconElement;

  constructor() {
    this._faviconElement = document.querySelectorAll('link[ rel ~= \'icon\' i]')[0];
  }

  update(dirty: boolean = false) {
    if (dirty) {
      this._faviconElement.setAttribute('href', 'favicon-dirty.png');
    } else {
      this._faviconElement.setAttribute('href', 'favicon.png');
    }
  }
}
